<template>
  <div class="item-box-main">
    <div
      class="d-flex flex-wrap justify-content-between align-items-baseline pb-2 pt-1 pt-md-0"
    >
      <div class="col">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            { name: $t('sidebar.products'), route: 'management_products' },
            { name: $t('sidebar.item_box'), route: '' },
          ]"
        />
      </div>
      <router-link
        custom
        v-slot="{ href, navigate }"
        :to="{ name: 'new_item_box' }"
        class="col-3 text-center mx-3"
      >
        <a
          :href="href"
          @click="navigate"
          class="btn btn-outline-primary text-decoration-none"
        >
          {{ $t("common.add_new") }}
        </a>
      </router-link>
    </div>

    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span style="font-size: 1.2rem">
              {{ current_item_box.name }} ?</span
            >
          </div>
          <div class="modal-footer">
            <button @click="delete_item_box" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <button
      id="del-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#delmodal"
    ></button>
    <!-- End of ConfirmDelete Modal -->

    <div class="table-wrapper mx-3">
      <table class="primary-table" v-if="item_boxes.length > 0">
        <thead>
          <tr>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("common.item_boxes") }}</th>
            <th style="width: 1rem">{{ $t("common.control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in item_boxes" :key="item.id">
            <td>{{ item.name }}</td>

            <td>
              <span
                v-for="(item_box, index) in item.itembox_set"
                :key="item_box.id"
              >
                {{ item_box.name }}
                <span
                  v-if="index < item.itembox_set.length - 1"
                  class="text-danger"
                  style="font-size: 1.5rem"
                  >,
                </span>
              </span>
            </td>

            <td class="d-flex">
              <!--              <a-->
              <!--                :href="$get_route_url('view_item_box', { id: item.id })"-->
              <!--                class="btn btn-primary mx-1"-->
              <!--              >-->
              <!--                <img :src="$get_icon('view')" alt="" />-->
              <!--              </a>-->

              <a
                :href="$get_route_url('update_item_box', { id: item.id })"
                class="btn btn-success mx-1"
              >
                <img :src="$get_icon('edit')" alt="" />
              </a>
              <button
                @click="show_delete_dialog(item)"
                class="btn btn-danger mx-1"
              >
                <img :src="$get_icon('delete')" alt="" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

import { close_current_modal } from "@/helpers";

export default {
  name: "ItemBoxMain",
  components: { section_route },
  data() {
    return {
      item_boxes: [],
      // current triggered item_box name to edit | delete
      current_item_box: {},
    };
  },
  methods: {
    close_current_modal,

    get_item_boxes() {
      this.$http.get("/inventory/item_box").then((response) => {
        this.item_boxes = response.data;
      });
    },
    show_delete_dialog(item) {
      this.current_item_box = item;
      document.getElementById("del-modal-btn").click();
    },
    delete_item_box() {
      this.$http
        .delete(`/inventory/item_box`, {
          data: { product_id: this.current_item_box.id },
        })
        .then(() => {
          this.item_boxes = this.item_boxes.filter(
            (item) => item.id !== this.current_item_box.id
          );
        })
        .catch((e) => {
          this.get_item_boxes();
          this.$store.dispatch("utils/push_alert", {
            message: `${e.response.data}`,
            type: "danger",
          });
          console.log(`Error while deleting an Item Box ! : ${e}`);
        });
      this.close_current_modal();
    },
  },
  mounted() {
    this.get_item_boxes();
  },
};
</script>

<style lang="scss" scoped>
.item-box-main {
  width: 100%;
  height: 98%;
  background: $main-white;
  margin: 1rem 0;
  border-radius: 8px;

  .table-wrapper {
    min-height: 90%;
    max-height: 90%;
    overflow: auto;

    thead {
      th {
        z-index: 10;
      }
    }
    input[type="checkbox"] {
      transform: scale(1.5);
      z-index: 1;
    }
  }
}
</style>
