import { fixed_float } from "@/helpers";
import store from "@/store";
import { OrderItem } from "@/js/sell_screen/order/order_item";

export function is_valid_ean(str) {
  const eanLengths = [5, 8, 13, 14, 18];
  if (!eanLengths.includes(str.length)) {
    return false;
  }
  const sum = str
    .split("")
    .reverse()
    .map((num, i) => (i % 2 === 0 ? +num : +num * 3))
    .reduce((a, b) => a + b, 0);
  return sum % 10 === 0;
}

// 9910001052803
// 2-5-5-1 --> 99 10001 05280 3

export function get_pattern_length(pattern) {
  /** Returns the sum of all pattern number
   * i.e @param pattern  = 2-5-5-1  will @return 13
   * */
  let parts = pattern.split("-");
  let length = 0;
  for (let i = 0; i < parts.length; i++) {
    length += parseInt(parts[i]);
  }
  return length;
}

function convert_scale_price(price_from_barcode) {
  const decimal_places =
    store.getters["general_settings/get_settings"].scale.price_decimal_places;

  const power_of_10 = Math.pow(10, decimal_places);

  return price_from_barcode / power_of_10;
}

function product_scale_qty(order_item, price_from_barcode) {
  // return product with changing price
  const ordered_quantity = convert_scale_price(price_from_barcode);
  return new OrderItem(order_item.product, [], ordered_quantity);
}

function product_scale_price(order_item, price_from_barcode) {
  // return product with changing  quantity
  let modified_product = product_scale_qty(order_item, price_from_barcode);

  modified_product.ordered_quantity = fixed_float(
    convert_scale_price(price_from_barcode) / order_item.price_with_tax
  );

  return modified_product;
}

export function handle_price_or_qty(order_item, price_from_barcode) {
  const is_price = store.getters["general_settings/get_settings"].scale.price;
  if (is_price) {
    return product_scale_price(order_item, price_from_barcode);
  }
  return product_scale_qty(order_item, price_from_barcode);
}

function extract_ean_13(pattern, barcode, product) {
  const [
    prefix_length,
    product_code_length,
    price_or_qty_length,
    check_digit_length,
  ] = pattern.split("-").map((x) => parseInt(x));
  let prefix, product_code, price_or_qty, check_digit;
  let current_index = 0;

  prefix = barcode.slice(current_index, (current_index += prefix_length));
  product_code = barcode.slice(
    current_index,
    (current_index += product_code_length)
  );
  price_or_qty = barcode.slice(
    current_index,
    (current_index += price_or_qty_length)
  );
  check_digit = barcode.slice(
    current_index,
    (current_index += check_digit_length)
  );
  const modified_item = product
    ? handle_price_or_qty(product, price_or_qty)
    : null;

  return {
    prefix,
    product_code,
    price_or_qty,
    check_digit,
    scale_product: modified_item,
  };
}

function extract_ean_18(pattern, barcode, order_item) {
  /* ean 18 contains price and quantity*/
  const [
    prefix_length,
    product_code_length,
    price_length,
    qty_length,
    check_digit_length,
  ] = pattern.split("-").map((x) => parseInt(x));

  let prefix, product_code, price, qty, check_digit;

  let current_index = 0;

  prefix = barcode.slice(current_index, (current_index += prefix_length));

  product_code = barcode.slice(
    current_index,
    (current_index += product_code_length)
  );

  price = barcode.slice(current_index, (current_index += price_length));

  qty = barcode.slice(current_index, (current_index += qty_length));

  check_digit = barcode.slice(
    current_index,
    (current_index += check_digit_length)
  );

  // console.warn(
  //   `prefix: ${prefix}, product_code: ${product_code}, price: ${price}, qty: ${qty}, check_digit: ${check_digit}`
  // );

  let modified_item = null;

  if (order_item) {
    const price_decimal_places =
      store.getters["general_settings/get_settings"].scale.price_decimal_places;

    const weight_decimal_places =
      store.getters["general_settings/get_settings"].scale
        .weight_decimal_places;

    const price_power_of_10 = Math.pow(10, price_decimal_places);
    const weight_power_of_10 = Math.pow(10, weight_decimal_places);

    const barcode_total = price / price_power_of_10;
    const barcode_quantity = qty / weight_power_of_10;
    const unit_price = barcode_total / barcode_quantity;

    modified_item = new OrderItem(
      order_item.product,
      [],
      barcode_quantity,
      0,
      0,
      unit_price
    );

    // console.log(
    //   "qty, price: ",
    //   qty / weight_power_of_10,
    //   price / price_power_of_10,
    //   unit_price
    // );
  }

  return {
    prefix,
    product_code,
    price,
    qty,
    check_digit,
    scale_product: modified_item,
  };
}

export function extract_ean_info(pattern, barcode, product) {
  switch (get_pattern_length(pattern)) {
    case 13:
      return extract_ean_13(pattern, barcode, product);
    case 18:
      return extract_ean_18(pattern, barcode, product);
  }
}
