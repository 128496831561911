export default {
  profile: "الصفحة الشخصية",
  sell: "شاشة البيع",
  history: "سجل المبيعات",
  management: "الادارة",
  dashboard: "لوحة القيادة",
  data_report: "التقارير",
  products: "المنتجات",
  all_products: "جميع المنتجات",
  addons: "الإضافات",
  item_box: "صندوق العناصر",
  price_tags: "بطاقات الاسعار",
  inventory: "المخزون",
  users_and_security: "المستخدمين والحماية",
  clients_and_suppliers: "العملاء والموردين",
  purchases: "المشتريات",
  promos: "العروض",
  company: "الشركة",
  branches: "الفروع",
  zatca: "هيئة الزكاة والضريبة",
  settings: "الإعدادات",
  logout: "تسجيل الخروج",
  logout_confirm: "هل انت متاكد من تسجيل الخروج ؟",
};
