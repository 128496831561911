import { render, staticRenderFns } from "./purchase_modal.vue?vue&type=template&id=2268a68a&scoped=true"
import script from "./purchase_modal.vue?vue&type=script&lang=js"
export * from "./purchase_modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2268a68a",
  null
  
)

export default component.exports