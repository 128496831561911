import CompanyView from "@/views/management/CompanyView";

export default [
  {
    path: "/management/company/",
    name: "company",
    component: CompanyView,
    meta: {
      title: () => {
        return `OnePos | Company Information`;
      },
    },
  },
];
