<template>
  <div class="settings-payment">
    <div class="sec-name">{{ $t("settings.w_scale") }}</div>
    <div class="settings mt-4">
      <div class="line">
        <div class="left">
          <p class="header">{{ $t("common.enable") }}</p>
          <p class="desc">{{ $t("settings.w_scale_desc") }}</p>
        </div>

        <div class="right">
          <label class="switch">
            <input v-model="request_form.enabled" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="mt-3" v-show="request_form.enabled">
        <div class="line">
          <div class="left">
            <p class="header">{{ $t("common.price") }}</p>
            <p class="desc">{{ $t("settings.price_desc") }}</p>
          </div>

          <div class="right">
            <label class="switch">
              <input v-model="request_form.price" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="line mt-3">
          <div class="left">
            <p class="header">{{ $t("settings.price_decimal_places") }}</p>
            <p class="desc">{{ $t("settings.price_decimal_places_desc") }}</p>
          </div>

          <div class="right">
            <input
              v-model="request_form.price_decimal_places"
              type="number"
              class="pos-input"
              min="1"
            />
          </div>
        </div>

        <div class="line mt-3">
          <div class="left">
            <p class="header">{{ $t("settings.weight_decimal_places") }}</p>
            <p class="desc">{{ $t("settings.weight_decimal_places_desc") }}</p>
          </div>

          <div class="right">
            <input
              v-model="request_form.weight_decimal_places"
              type="number"
              class="pos-input"
              min="1"
              :disabled="disable_weight_input"
            />
          </div>
        </div>

        <div v-for="ean in settings.ean" :key="ean.id" class="mt-4">
          <div class="line">
            <div class="left">
              <label class="header">
                <input
                  type="radio"
                  name="ean"
                  @change="ean_selected(ean.id)"
                  :checked="ean.id === settings.selected.ean_id"
                />
                {{ ean.name }}
              </label>

              <!--              <p class="desc">Number of digits for product</p>-->
            </div>

            <div class="right">
              <select id="pattern" class="form-select">
                <option
                  v-for="type in ean.types"
                  :key="type.id"
                  :value="type.id"
                  :selected="type.id === settings.selected.pattern_id"
                >
                  {{ type.pattern }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons mt-5">
      <button @click="save" type="button" class="pos-primary-btn py-3 px-5">
        {{ $t("common.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { get_pattern_length } from "@/js/sell_screen/scales";

export default {
  name: "ScalesSettings",
  data() {
    return {
      disable_weight_input: false,
      request_form: {
        enabled: false,
        price: false, // price instead of quantity
        price_decimal_places: 1,
        weight_decimal_places: 1,
      },
      settings: {
        enabled: true,
        price: false,
        price_decimal_places: 2,
        weight_decimal_places: 1,
        ean: [
          // {
          //   id: 1,
          //   types: [
          //     {
          //       id: 1,
          //       pattern: "1-5-6-1",
          //       timestamp: "2023-11-11T14:48:53.365661Z",
          //       ean: 1,
          //     },
          //     {
          //       id: 2,
          //       pattern: "2-5-5-1",
          //       timestamp: "2024-01-08T18:44:15.434224Z",
          //       ean: 1,
          //     },
          //   ],
          //   name: "ean 13",
          // }, ......
        ],
        selected: {
          ean_id: -1,
          ean_name: "", //"ean 18"
          pattern_id: -1,
          pattern: "", // "1-5-6-5-1"
        },
      },
    };
  },
  methods: {
    get_selected_pattern_id() {
      const selectedRadio = document.querySelector('input[name="ean"]:checked');
      return selectedRadio.parentNode.parentNode.nextElementSibling.querySelector(
        "#pattern option:checked"
      ).value;
    },
    get_scale_settings() {
      this.$http
        .get(`/scale/get_scales`)
        .then((response) => {
          this.settings = response.data.results;
          this.request_form = Object.assign(
            {},
            {
              enabled: this.settings.enabled,
              price: this.settings.price,
              price_decimal_places: this.settings.price_decimal_places,
              weight_decimal_places: this.settings.weight_decimal_places,
            }
          );

          this.ean_selected(this.settings.selected.ean_id);
        })
        .catch((e) => {
          console.log(`Error while fetching scale settings ! : ${e}`);
        });
    },
    save() {
      this.$http({
        method: "PUT",
        url: "/scale/update_scale_settings",
        data: {
          enabled: this.request_form.enabled,
          price: this.request_form.price,
          price_decimal_places: this.request_form.price_decimal_places,
          weight_decimal_places: this.request_form.weight_decimal_places,

          pattern_id: this.get_selected_pattern_id(),
        },
      })
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: "Settings Saved Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          const errors_data = error.response.data;
          for (let key in errors_data) {
            if (errors_data.hasOwnProperty.call(errors_data, key)) {
              this.$store.dispatch("utils/push_alert", {
                message: `${key}: ${errors_data[key][0]}`,
                type: "danger",
              });
            }
          }
        });
    },
    ean_selected(ean_id) {
      // getting the first type pattern of selected ean because any length of the selected ean type will represent the ean length
      let pattern = this.settings.ean.find((ean) => ean.id === ean_id).types[0]
        .pattern;

      // check if selected ean >= 18 to enable or disable weight option
      this.disable_weight_input = get_pattern_length(pattern) < 18;
    },
  },
  created() {
    this.get_scale_settings();
  },
};
</script>

<style lang="scss" scoped>
.settings-payment {
  width: 100%;
  height: 100%;

  .settings {
    height: 86%;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  .line {
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 20px;
      color: $secondary-500;
      padding: 0;
      margin: 0;
    }
    .desc {
      color: #808080;
    }
    .right input {
      padding: 0.5rem;
    }
  }
}
</style>
