export default {
  // navbar buttons
  create_new_csid: "Create New CSID",
  renew_csid: "Renew CSID",

  // new_csid_layout
  already_have_csid_msg: "You Have Already A CSID", // valid_csid
  create_new_csid_button: "Create New CSID",

  // renew_csid_layout
  create_csid_msg: "You Have To Create CSID At first",
  renew_csid_button: "Renew CSID",
};
