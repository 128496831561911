var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"count-display"},[_c('div',{staticClass:"modal fade",attrs:{"id":"editmodal","tabindex":"-1","aria-labelledby":"editModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header",staticStyle:{"border":"none"}},[(_vm.selected_item.product)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" "),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.selected_item.product))]),_vm._v(" "+_vm._s(_vm.$t("common.quantity"))+" ")]):_vm._e(),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[_c('input',{ref:"qty",staticClass:"form-control",staticStyle:{"padding":"0.6rem"},attrs:{"type":"text"}})]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-outline-warning px-5",on:{"click":_vm.edit_quantity}},[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")])])])])]),_c('button',{staticClass:"d-none",attrs:{"id":"edit-modal-btn","data-bs-toggle":"modal","data-bs-target":"#editmodal"},on:{"click":_vm.focus_qty}}),_c('section_route',{staticClass:"pb-2",attrs:{"routes":[
      { name: _vm.$t('inventory.inventory_count'), route: 'inventory_count' },
      {
        name: `${_vm.$t('inventory.inventory_count_number')}  ${
          this.$route.params.report_id
        }`,
        route: '',
      },
    ]}}),_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"primary-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("common.product_name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inventory.counted_qty")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inventory.expected_qty")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inventory.increase_qty")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inventory.shortage_qty")))]),_c('th',[_vm._v(_vm._s(_vm.$t("common.unit_price")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inventory.shortage_price")))]),(_vm.is_pending)?_c('th',[_vm._v(_vm._s(_vm.$t("common.control")))]):_vm._e()])]),_c('tbody',_vm._l((_vm.inventory_count_items),function({
            id,
            product,
            counted,
            quantity,
            increase_quantity,
            loss_quantity,
            product_unit_price,
            loss_price,
          }){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(product))]),_c('td',[_vm._v(_vm._s(counted))]),_c('td',[_vm._v(_vm._s(quantity))]),_c('td',[_vm._v(_vm._s(increase_quantity))]),_c('td',[_vm._v(_vm._s(loss_quantity))]),_c('td',[_vm._v(_vm._s(product_unit_price))]),_c('td',[_vm._v(_vm._s(loss_price))]),(_vm.is_pending)?_c('td',[_c('button',{staticClass:"btn btn-success mx-1",on:{"click":function($event){return _vm.edit_dialog(id)}}},[_c('img',{attrs:{"src":_vm.$get_icon('edit'),"alt":"edit icon","title":_vm.$t('common.edit')}})])]):_vm._e()])}),0)])]),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("common.total"))+" :")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-text"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("inventory.expected_qty"))+" : ")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.total_qty))])]),_c('div',{staticClass:"info-text"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("inventory.counted_qty"))+" : ")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.total_counted))])]),_c('div',{staticClass:"info-text"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("inventory.shortage_qty"))+" : ")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.total_shortage_qty))])]),_c('div',{staticClass:"info-text"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("inventory.shortage_price"))+" : ")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.shortage_price))])])]),(_vm.is_pending)?_c('div',{staticClass:"buttons justify-content-end gap-3"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.update_status('disapprove')}}},[_vm._v(" "+_vm._s(_vm.$t("inventory.decline"))+" ")]),_c('button',{staticClass:"pos-primary-btn",on:{"click":function($event){return _vm.update_status('approve')}}},[_vm._v(" "+_vm._s(_vm.$t("inventory.approve"))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }