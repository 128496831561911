export default {
  days_of_week: "ايام الاسبوع",
  start_date: "بداية",
  end_date: "ينتهي",

  new_promo: "اضافة عرض جديد",
  edit_promo: "تعديل علي عرض",

  promo_name: "اسم العرض",
  quantity_condition: "شرط الكمية",

  apply_to_all: "تطبق علي الكل",
  apply_to_next: "تطبق علي التالي",
};
