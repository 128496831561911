<template>
  <div class="settings">
    <div class="wrapper row pb-5 pb-md-2">
      <div class="layout-sidebar col-md-3">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            { name: $t('sidebar.settings'), route: '' },
          ]"
          class="pb-2"
        />
        <div class="layout-menu-buttons d-flex flex-wrap gap-3">
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'management_payment' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("settings.orders") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'management_scales' }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              {{ $t("settings.w_scale") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'customer_display' }"
            class="d-none"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              Customer Display
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'management_taxes' }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              {{ $t("common.taxes") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'payment_types' }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              {{ $t("settings.payment_types") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'management_database' }"
            class="d-none"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              Database
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'management_printers' }"
            class="d-none"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              Printers Settings
            </a>
          </router-link>
        </div>
      </div>
      <div class="content-wrapper col-md-9">
        <div class="router-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "SettingsMain",
  components: { section_route },
};
</script>

<style scoped></style>
