import { fixed_float } from "@/helpers";
import { convert_itembox } from "@/js/sell_screen/itembox";
import { ProductPrice } from "@/js/counts";
import store from "@/store";

class OrderItem {
  #price_with_tax = 0;
  #addons;
  #addons_taxes = 0;
  #addons_subtotal = 0;
  #addons_total = 0;

  constructor(
    product,
    addons,
    ordered_quantity,
    fixed_discount = 0,
    percentage_discount = 0,
    changed_price = 0
  ) {
    this.product = product;
    this.copyProperties(product);
    this.subtotal = fixed_float(product.subtotal);
    this.taxes = product.tax || [];
    this.tax_included = product.tax_included;
    this.tax_total = fixed_float(product.tax_total);
    this.price_with_tax = fixed_float(product.price_with_tax);
    this.ordered_quantity = ordered_quantity || 1;
    this.changed_price = changed_price;

    // #ced9ff aka  $secondary-100
    this.color = product.color || "#ced9ff";

    // item box
    this._itembox_set = product.itembox_set || [];
    this.is_item_box = product.is_item_box || false;

    // promotions
    this.promo = product.promo;

    // addons
    this.addons = addons || [];
    if (this.addons.length > 0) {
      this.calculate_addons();
    }

    // discount
    this.discount_after =
      store.getters["general_settings/get_settings"].discount_type ===
      "after_tax";

    this.fixed_discount = fixed_discount;
    this.percentage_discount = percentage_discount;

    this.total_discount = 0;

    if (this.is_item_box) {
      // change item_box item based on product tax include or exclude
      this.changed_price = this.price_with_tax;
    }

    if (this.changed_price) {
      this.change_price();
    }
  }

  copyProperties(product) {
    const properties = [
      "id",
      "name",
      "cost",
      "price",
      "last_cost",
      "reorder_point",
      "low_stock_bool",
      "active",
      "quantity",
      "code",
      "barcode",
      "image",
    ];

    for (const property of properties) {
      if (Object.prototype.hasOwnProperty.call(product, property)) {
        this[property] = product[property];
      }
    }
  }

  calculate_addons() {
    for (let addon of this.addons) {
      this.addons_subtotal += addon.chosen_type.subtotal;
      this.addons_taxes += addon.chosen_type.taxes;
      this.addons_total += addon.chosen_type.total;
    }
  }
  set price_with_tax(val) {
    this.#price_with_tax = val;
  }

  get price_with_tax() {
    return fixed_float(this.#price_with_tax, 2);
  }

  get total_subtotal() {
    return fixed_float(this.subtotal * this.ordered_quantity);
  }

  get total_taxes() {
    return fixed_float(this.tax_total * this.ordered_quantity);
  }

  get total_price() {
    return fixed_float(this.price_with_tax * this.ordered_quantity);
  }

  set addons(addons) {
    this.#addons = addons;
  }

  get addons() {
    return this.#addons;
  }

  set addons_subtotal(val) {
    this.#addons_subtotal = val;
  }

  get addons_subtotal() {
    return fixed_float(this.#addons_subtotal);
  }

  set addons_taxes(val) {
    this.#addons_taxes = val;
  }

  get addons_taxes() {
    return fixed_float(this.#addons_taxes);
  }

  set addons_total(val) {
    this.#addons_total = val;
  }

  get addons_total() {
    return fixed_float(this.#addons_total);
  }

  get itembox_set() {
    return this._itembox_set.map((itembox) =>
      convert_itembox(itembox, this.product)
    );
  }

  has_itemboxes() {
    return this.itembox_set.length > 0;
  }

  reset_ordered_quantity() {
    this.ordered_quantity = 1;
  }

  reduce_product_quantity() {
    /**
     * reduce current product
     * quantity for reorder_point
     * to prevent refresh in order to get the new product qty
     * */
    this.quantity -= this.ordered_quantity;
  }

  reset_quantities() {
    this.reduce_product_quantity();
    this.reset_ordered_quantity();
  }

  change_price() {
    const product = new ProductPrice(
      this.taxes,
      this.changed_price,
      this.tax_included
    );

    this.tax_total = product.tax_total;
    this.subtotal = product.subtotal;
    this.price_with_tax = product.price_with_tax;
  }

  // DISCOUNT METHODS
  handle_order_discount(order_fixed_discount, order_total) {
    this.get_total_discount();

    const item_discount_ratio_from_order = this.total_price / order_total;

    const item_discount_from_order_discount =
      order_fixed_discount * item_discount_ratio_from_order;

    // item discount + order_discount
    const total_discount =
      this.total_discount + item_discount_from_order_discount;

    const item_total_after_discount = this.total_price - total_discount;

    if (item_total_after_discount <= 0) {
      this.reset_discount();
      // Invalid item Discount or Invalid order Discount
      store.dispatch("utils/push_alert", {
        message: `Invalid  Item Discount`,
        type: "danger",
      });
      return this;
    }

    const new_order_item_price = new ProductPrice(
      this.taxes,
      item_total_after_discount,
      true
    );

    this.subtotal = new_order_item_price.subtotal / this.ordered_quantity;
    this.tax_total = new_order_item_price.tax_total / this.ordered_quantity;
    this.price_with_tax =
      new_order_item_price.price_with_tax / this.ordered_quantity;

    return this;
  }

  calculate_total_discount(base_amount) {
    return this.fixed_discount > 0
      ? this.fixed_discount
      : (this.percentage_discount * base_amount) / 100;
  }

  get_total_discount() {
    if (this.discount_after) {
      this.total_discount = this.calculate_total_discount(this.total_price);
    } else {
      this.total_discount = this.calculate_total_discount(this.total_subtotal);
    }
  }

  reset_discount() {
    this.fixed_discount = 0;
    this.percentage_discount = 0;
  }
}

export { OrderItem };
