import store from "@/store";
import { Order } from "@/js/sell_screen/order/order";
import { OrderItem } from "@/js/sell_screen/order/order_item";

class OrderDiscount extends Order {
  constructor(
    client,
    datetime,
    items = [],

    cart_fixed_discount,
    cart_discount_percentage
  ) {
    super(
      client,
      datetime,
      items,
      cart_fixed_discount,
      cart_discount_percentage
    );

    this.items = [];

    // discount
    this.discount_after =
      store.getters["general_settings/get_settings"].discount_type ===
      "after_tax";

    this.is_fixed_discount = this.cart_fixed_discount > 0;
    this.handle_discount();

    for (let item of items) {
      const _order_item = new OrderItem(
        item.product,
        item.addons,
        item.ordered_quantity,
        item.fixed_discount,
        item.percentage_discount,
        item.changed_price
      );
      if (this.total <= this.total_discount) {
        store.dispatch("utils/push_alert", {
          message: `Invalid Order Discount`,
          type: "danger",
        });
        this.reset_discount();
      } else {
        _order_item.handle_order_discount(this.total_discount, this.total);
      }

      this.items.push(_order_item);
    }

    this.count_order();
  }

  calculate_total_discount(base_amount) {
    return this.is_fixed_discount
      ? this.order_discount
      : (this.order_discount * base_amount) / 100;
  }

  handle_discount() {
    if (this.discount_after) {
      this.total_discount = this.calculate_total_discount(this.total);
    } else {
      this.total_discount = this.calculate_total_discount(this.subtotal);
    }
  }

  reset_discount() {
    this.cart_fixed_discount = 0;
    this.cart_discount_percentage = 0;
    this.total_discount = 0;
  }
}

export { OrderDiscount };
