export default {
  // join
  grow_your_business: "Grow Your Business Fast",
  easy_to_start: "Easy To start with",
  all_you_need: "All You Need and More",

  // login
  login: "Login",
  error: "Error",
  credential_error: "Incorrect Username or Password",
  activation_error: "You Have To Activate Your Email",
  username: "Username",
  password: "Password",

  signup_helper: " You Don't Have an account ?",
  signup: "SignUp",
  signin: "SignIn",

  password_reset_helper: "Forget Your Password?",
  password_reset: "Reset Password",

  // register
  register: "Register",
  country: "Country",
  company_name: "Company Name",
  signin_helper: "Already Have an account ?",
  email_helper: "Enter Your email address and double check it",
  password_helper: "Must be 8-20 characters long.",
  re_password: "Retype Password",
};
