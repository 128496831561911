<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.payments'), route: 'payment_types_report' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="invoices.length === 0"
        class="btn btn-warning col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-if="invoices.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th v-for="key in Object.keys(invoices[0])" :key="key">
                <span v-if="key === 'date'">{{ $t("common.date") }}</span>
                <span v-else-if="key === 'total'">{{
                  $t("common.total")
                }}</span>
                <span v-else>{{ key }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) of invoices" :key="index">
              <template v-if="invoice['meta_data']">
                <td v-for="col in invoice['meta_data']['row_count']" :key="col">
                  <span v-if="col === invoice['meta_data']['row_count']">
                    {{ invoice["meta_data"]["total"] }}
                  </span>
                </td>
              </template>
              <td v-else v-for="key in Object.keys(invoice)" :key="key">
                {{ invoice[key] || "" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PaymentTypes",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/payment_types/`,
      invoices: [],
    };
  },
  methods: {
    get_invoices() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.invoices = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "product_groups.pdf");
    },
  },
  mounted() {
    this.get_invoices();
  },
};
</script>

<style scoped>
.table-wrapper {
  height: 60rem;
  max-height: 60rem;
  overflow: scroll;
}
</style>
