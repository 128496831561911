import { render, staticRenderFns } from "./CreateUpdatePromo.vue?vue&type=template&id=17520c88&scoped=true"
import script from "./CreateUpdatePromo.vue?vue&type=script&lang=js"
export * from "./CreateUpdatePromo.vue?vue&type=script&lang=js"
import style0 from "./CreateUpdatePromo.vue?vue&type=style&index=0&id=17520c88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17520c88",
  null
  
)

export default component.exports