import PaymentTypesReport from "@/views/management/reports/payment_reports/PaymentTypes";
import PaymentsByUsers from "@/views/management/reports/payment_reports/PaymentsByUsers";
import PaymentsByCustomers from "@/views/management/reports/payment_reports/PaymentsByCustomers";

export default [
  {
    path: "/management/reports/payments/",
    component: PaymentTypesReport,
    name: "payment_types_report",
    meta: {
      title: () => {
        return "OnePos | Payment Types";
      },
    },
  },
  {
    path: "/management/reports/payments_by_users/",
    component: PaymentsByUsers,
    name: "payments_by_users",
    meta: {
      title: () => {
        return "OnePos | Payments By Users";
      },
    },
  },
  {
    path: "/management/reports/payments_by_customers/",
    component: PaymentsByCustomers,
    name: "payments_by_customers",
    meta: {
      title: () => {
        return "OnePos | Payments By Customers";
      },
    },
  },
];
