<template>
  <input
    v-model="current_date"
    @change="filter_function(current_date)"
    class="form-control"
    id="date-picker"
    type="date"
    min="YYYY-MM-DD"
    max="YYYY-MM-DD"
  />
</template>

<script>
export default {
  name: "date_filter",
  props: {
    filter_function: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      current_date: "",
    };
  },
  methods: {
    get_date() {
      // Create a new Date object
      const currentDate = new Date();

      // Get the year, month, and day from the Date object
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Month is zero-indexed, so add 1
      const day = currentDate.getDate();

      // Create a formatted date string in YYYY-MM-DD format
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

      console.log(formattedDate); // Output: YYYY-MM-DD format of the current date
      return formattedDate;
    },
  },
  mounted() {
    this.current_date = this.get_date();
  },
};
</script>

<style lang="scss" scoped>
#date-picker {
  background: transparent;
  border: none;
  outline: none;
}

::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url("@/assets/calendar.png");
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

::-webkit-calendar-picker-indicator {
  transition: transform 0.3s, box-shadow 0.3s;

  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url("@/assets/calendar.png");
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0.5rem;
  border: none;
  outline: none;
}
::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  border-radius: 4px;
}
</style>
