<template>
  <div style="height: 0">
    <div class="modal fade" id="logout-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <h3 class="text-center text-danger">
              {{ $t("sidebar.logout_confirm") }}
            </h3>
          </div>
          <div class="modal-footer">
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
            <button @click="logout" class="btn btn-danger px-4">
              {{ $t("sidebar.logout") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      data-bs-toggle="modal"
      data-bs-target="#logout-modal"
      ref="logout_modal_btn"
    ></button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "logout_modal",
  methods: {
    ...mapActions("auth", ["logout"]),
    close_current_modal() {
      //  close_current modal
      document.documentElement
        .querySelector(".modal.fade.show .btn-close")
        .click();
    },
  },
};
</script>
