import purchase from "./purchase/purcahse_ar.js";

export default {
  ...purchase,
  reports: "التقارير",
  report: "تقرير",

  // inventory
  product_groups: "مجموعات المنتجات",

  // customers
  customer_orders: "فواتير العملاء",

  // taxes
  tax_rates: "معدلات الضرائب",

  // invoices
  invoices: "الفواتير",
  invoice_list: "قائمة الفواتير",

  // payments
  payments: "المدفوعات",
  payment_types: "انواع الدفع",
  payment_by_users: "انواع الدفع بواسطة الموظفين",
  payment_types_by_customers: "انواع الدفع بواسطة العملاء",

  // refunds
  refunds: "الاسترداد",
  refunds_list: "فواتير الاسترداد",

  // sales
  daily_sales: "المبيعات اليومية",

  // purchases
  purchase_products: "منتجات المشتريات",
  purchase_suppliers: "موردين المشتريات",
  purchase_discount: "خصم فواتير المشتريات",
  purchase_invoices: "فواتير المشتريات",

  // loss & damage
  loss_and_damage: "الخسارة و الضرر",

  // common
  show_report: "اظهار التقرير",
  save_as_pdf: "حفظ كملف PDF",
};
