<template>
  <Doughnut :options="chartOptions" :data="chartData" />
</template>
<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "top_product_groups_doughnut",
  props: ["groups_data"],
  components: {
    Doughnut,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 1,

            borderColor: [
              "rgba(0, 128, 255, 1)", // Blue
              "rgba(255, 102, 0, 1)", // Orange
              "rgba(0, 204, 102, 1)", // Green
              "rgba(204, 0, 102, 1)", // Magenta
              "rgba(255, 204, 0, 1)", // Yellow
            ],
            backgroundColor: [
              "rgba(0, 128, 255, 0.2)",
              "rgba(255, 102, 0, 0.2)",
              "rgba(0, 204, 102, 0.2)",
              "rgba(204, 0, 102, 0.2)",
              "rgba(255, 204, 0, 0.2)",
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    load_groups_chart() {
      if (this.groups_data.length > 0) {
        for (let i = 0; i < this.groups_data.length; i++) {
          this.chartData.labels.push(this.groups_data[i].group_name);
          this.chartData.datasets[0].data.push(this.groups_data[i].sales);
        }
      } else {
        this.chartData.datasets[0].data.push(1);
      }
    },
  },
  created() {
    this.load_groups_chart();
  },
};
</script>
