import { render, staticRenderFns } from "./search_client_or_supplier.vue?vue&type=template&id=802ae170&scoped=true"
import script from "./search_client_or_supplier.vue?vue&type=script&lang=js"
export * from "./search_client_or_supplier.vue?vue&type=script&lang=js"
import style0 from "./search_client_or_supplier.vue?vue&type=style&index=0&id=802ae170&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "802ae170",
  null
  
)

export default component.exports