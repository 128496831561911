<template>
  <Bar id="orders-per-hour-chart" :options="chartOptions" :data="chartData" />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

import { convertTo12HourFormat } from "@/helpers";
export default {
  name: "orders_chart",
  components: { Bar },
  props: ["orders_data"],
  data() {
    return {
      chart_key: 0,

      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t("dashboard.orders_per_hour"),
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(255, 205, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
            ],

            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false, // to take parent div height
      },
    };
  },
  methods: {
    load_chart_data() {
      // [
      //   {
      //     "hour": 13,
      //     "order_count": 1
      //   },
      //   {
      //     "hour": 17,
      //     "order_count": 2
      //   },
      //   {
      //     "hour": 16,
      //     "order_count": 2
      //   },
      //   {
      //     "hour": 19,
      //     "order_count": 3
      //   }
      // ]
      for (let i = 0; i < this.orders_data.length; i++) {
        this.chartData.labels.push(
          convertTo12HourFormat(this.orders_data[i].hour.toString())
        );
        this.chartData.datasets[0].data.push(this.orders_data[i].order_count);
      }
    },
  },
  created() {
    this.load_chart_data();
  },
};
</script>
