<template>
  <div class="p-2">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
      ]"
    />
    <div style="border: 1px solid #f2c2a8; margin-top: 1rem"></div>

    <div class="wrapper d-flex flex-wrap gap-5">
      <!-- INVENTORY -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("sidebar.inventory") }}</h5>
        <div class="d-flex flex-wrap gap-3">
          <router-link
            :to="{ name: 'products_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("common.products") }}
          </router-link>

          <router-link
            :to="{ name: 'product_groups_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.product_groups") }}
          </router-link>
        </div>
      </div>
      <!-- INVENTORY -->

      <!-- CUSTOMERS -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("common.customers") }}</h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'customer_orders' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.customer_orders") }}
          </router-link>
        </div>
      </div>
      <!-- CUSTOMERS -->

      <!-- USERS -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("common.taxes") }}</h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'taxes_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.tax_rates") }}
          </router-link>
        </div>
      </div>
      <!-- USERS -->

      <!-- INVOICES -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("reports.invoices") }}</h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'invoice_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.invoice_list") }}
          </router-link>
        </div>
      </div>
      <!-- INVOICES -->

      <!-- PAYMENTS -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("reports.payments") }}</h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'payment_types_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.payment_types") }}
          </router-link>
          <router-link
            :to="{ name: 'payments_by_users' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.payment_by_users") }}
          </router-link>
          <router-link
            :to="{ name: 'payments_by_customers' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.payment_types_by_customers") }}
          </router-link>
        </div>
      </div>
      <!-- PAYMENTS -->

      <!-- REFUNDS -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">
          {{ $t("reports.refunds") }}
        </h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'refunds_report' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.refunds_list") }}
          </router-link>
        </div>
      </div>
      <!-- REFUNDS -->

      <!-- SALES -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">{{ $t("dashboard.sales") }}</h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'daily_sales' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.daily_sales") }}
          </router-link>
        </div>
      </div>
      <!-- SALES -->

      <!-- PURCHASES -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label">
          {{ $t("sidebar.purchases") }}
        </h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'purchase_by_product' }"
            class="btn btn-outline-primary"
          >
            {{ $t("common.products") }}
          </router-link>
          <router-link
            :to="{ name: 'purchase_by_suppliers' }"
            class="btn btn-outline-primary"
          >
            {{ $t("common.suppliers") }}
          </router-link>
          <router-link
            :to="{ name: 'purchase_discount' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.purchase_discount") }}
          </router-link>
          <router-link
            :to="{ name: 'purchase_invoices' }"
            class="btn btn-outline-primary"
          >
            {{ $t("reports.purchase_invoices") }}
          </router-link>
        </div>
      </div>
      <!-- PURCHASES -->

      <!-- LOSS AND DAMAGE -->
      <div class="box col-md-3 col-12">
        <h5 class="box-label text-danger">
          {{ $t("reports.loss_and_damage") }}
        </h5>
        <div class="box-buttons-container">
          <router-link
            :to="{ name: 'damaged_products' }"
            class="btn btn-outline-primary"
          >
            {{ $t("common.products") }}
          </router-link>
        </div>
      </div>
      <!--  LOSS AND DAMAGE -->
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "ReportsView",
  components: {
    section_route,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 4rem auto;

  .box {
    position: relative;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    background: #fff;

    .box-label {
      position: absolute;
      height: 2.3rem;
      top: -2.1rem;
      background: #fafafa;
      color: #4e5381;

      left: 0;
      width: 100%;
      text-align: center;
      padding: 0 0.2rem;
      border-radius: 3px;
      border: 1px solid #e8e1e1;
    }
    .box-buttons-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
</style>
