<template>
  <div class="new-item-box pb-5 pb-md-0">
    <section_route
      :routes="[
        { name: $t('sidebar.item_box'), route: 'item_boxes' },
        { name: $t('products.new_item_box'), route: '' },
      ]"
    />

    <!-- New Item Box Modal -->
    <div
      class="modal fade"
      id="add-modal"
      tabindex="-1"
      aria-labelledby="add-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <span> {{ $t("common.add_new") }} </span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <p class="col-md-4">{{ $t("common.name") }} :</p>
              <input
                v-model="new_item_box.name"
                class="col-md-6 pos-input"
                type="text"
              />

              <p class="col-md-4">{{ $t("common.count") }} :</p>
              <input
                v-model="new_item_box.count"
                class="col-md-6 pos-input"
                type="number"
              />

              <p class="col-md-4">{{ $t("common.unit_price") }} :</p>
              <input
                v-model="new_item_box.unit_of_price"
                class="col-md-6 pos-input"
                type="number"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="add_new_item" class="pos-primary-btn px-5 py-2">
              {{ $t("common.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- New Item Box Modal -->

    <div class="mid mt-5">
      <div class="row align-items-baseline">
        <h3 class="col-12 col-sm-auto">{{ $t("common.product_name") }} :</h3>
        <div class="col-9 col-sm-3">
          <input
            v-model="selected_product"
            @input="search"
            class="search-input pos-input w-100"
            list="products-list"
          />

          <datalist id="products-list">
            <option
              v-for="product in products"
              :key="product.id"
              :value="product.name"
              :data-id="product.id"
            ></option>
          </datalist>
        </div>
        <!-- add new item box button -->
        <div
          class="col"
          data-bs-toggle="modal"
          data-bs-target="#add-modal"
          title="Add a new Item Box to this product"
        >
          <button class="pos-primary-btn" style="width: 42px; height: 42px">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 12H16"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 16V8"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <!-- add new item box button -->
      </div>
      <div class="table-wrapper">
        <table class="primary-table mt-5" v-if="item_boxes.length > 0">
          <thead>
            <tr>
              <th>{{ $t("common.name") }}</th>
              <th>{{ $t("common.count") }}</th>
              <th>{{ $t("common.unit_price") }}</th>
              <th style="width: 1rem">{{ $t("common.control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in item_boxes" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.count }}</td>
              <td>{{ item.unit_of_price }}</td>
              <td>
                <button
                  @click="remove_item(item.id)"
                  class="btn btn-outline-danger"
                >
                  {{ $t("common.remove") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row justify-content-end mx-1">
      <button @click="save" class="col-md-2 btn btn-outline-primary px-5">
        {{ $t("common.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { close_current_modal } from "@/helpers";

export default {
  name: "CreateItemBox",
  components: { section_route },
  data() {
    return {
      selected_product: "",
      selected_product_obj: {},

      products: [],
      // to bind new item box modal variables
      new_item_box: {
        name: "",
        count: null,
        unit_of_price: null,
      },
      item_boxes: [],
    };
  },
  methods: {
    search() {
      if (this.selected_product.length > 0) {
        this.$http
          .get(`/inventory/get_products/?search=${this.selected_product}`)
          .then((response) => {
            this.products = response.data.results;
          })
          .catch((e) => {
            console.log(`Error while searching products by name ! : ${e}`);
          });
      }

      this.select_product();
    },
    select_product() {
      try {
        const selectedOption = document.querySelector(
          `#products-list option[value="${this.selected_product}"]`
        );
        const productId = selectedOption.dataset.id;
        this.selected_product_obj = this.products.find(
          (product) => product.id === parseInt(productId)
        );
        selectedOption.blur(); // close the datalist
      } catch (error) {
        if (error instanceof TypeError) {
          console.error("Caught TypeError:", error.message);
        } else {
          console.error("Caught error:", error.message);
        }
      }
    },
    remove_item(product_id) {
      const index = this.item_boxes.findIndex((p) => p.id === product_id);
      this.item_boxes.splice(index, 1);
    },
    add_new_item() {
      this.item_boxes.push(this.new_item_box);
      this.new_item_box = {
        name: "",
        count: null,
        unit_of_price: null,
      };
      close_current_modal();
    },
    save() {
      const form = {
        product: this.selected_product_obj.id,
        item_boxes: this.item_boxes,
      };
      this.$http
        .post(`/inventory/item_box`, form)
        .then(() => {
          this.$router.push({ name: "item_boxes" });
        })
        .catch((e) => {
          console.log(`Error while creating new item boxes ! : ${e}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-item-box {
  width: 100%;
  height: calc(100% - 1.2rem);
  position: relative;

  background: $main-white;
  margin: 0.55rem 0;
  border-radius: 8px;

  .mid {
    min-height: 85%;
    .table-wrapper {
      min-height: 93%;
      max-height: 93%;
      overflow: scroll;
    }
  }

  .modal {
    .modal-body > .row * {
      margin-top: 1rem;
    }
  }
}
</style>
