<template>
  <div class="stock full-height pb-5 pb-md-0">
    <p class="sec-name d-none d-md-block">
      {{ $t("inventory.quantities_in_the_stock") }}
    </p>
    <div class="filters">
      <label>
        <input @click="filter" value="n_qty" type="radio" name="filters" />

        {{ $t("inventory.negative_quantity") }}
      </label>
      <label
        ><input @click="filter" value="z_qty" type="radio" name="filters" />
        {{ $t("inventory.zero_quantity") }}
      </label>

      <label
        ><input @click="filter" value="g_qty" type="radio" name="filters" />

        {{ $t("inventory.non_zero_quantity") }}
      </label>
    </div>

    <div class="search">
      <label>
        <input
          @input="search"
          class="pos-input p-2"
          type="text"
          :placeholder="$t('common.search_by_product_name_or_barcode')"
        />
      </label>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="$t('inventory.negative_quantity')"
        class="box"
      >
        {{ qty.n_qty }}
      </div>
      <!-- /.box -->
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="$t('inventory.items_with_zero_quantity')"
        class="box"
      >
        {{ qty.z_qty }}
      </div>
      <!-- /.box -->
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        :title="$t('inventory.items_with_quantity_greater_than_zero')"
        class="box"
      >
        {{ qty.g_qty }}
      </div>
      <!-- /.box -->
    </div>
    <!-- /.search -->
    <div class="table-wrapper mt-2 w-100">
      <table class="primary-table">
        <thead>
          <tr>
            <th>{{ $t("common.code") }}</th>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th>{{ $t("common.unit_price") }}</th>
            <th>{{ $t("common.cost") }}</th>
            <th>{{ $t("common.value") }}</th>
            <th>{{ $t("common.total_after_tax") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.price_with_tax }}</td>
            <td>{{ product.last_cost }}</td>
            <td>{{ (product.last_cost * product.quantity).toFixed(2) }}</td>
            <td>
              {{ (product.price_with_tax * product.quantity).toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pag mt-3">
      <button
        @click="get_products"
        :disabled="!pagination.previous"
        class="pos-button p-2"
      >
        {{ $t("common.previous") }}
      </button>
      <span class="p-2"
        >Table {{ pagination.current_page }} of
        {{ pagination.total_pages }}</span
      >
      <button
        @click="get_products"
        :disabled="!pagination.next"
        class="pos-button"
      >
        {{ $t("common.next") }}
      </button>
    </div>
    <!-- /.pag -->
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import { mapActions } from "vuex";
export default {
  name: "StockQuantities",
  data() {
    return {
      qty: { n_qty: 0, z_qty: 0, g_qty: 0 },
      products: [],
      pagination: {},
      page_num: 1,
      // filters
      selected: "",
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden", "append_forbidden_error"]),
    get_products() {
      this.$http
        .get(
          this.pagination.next ||
            this.pagination.previous ||
            `/inventory/get_products_quantities/`
        )
        .then((response) => {
          this.products = response.data.results;
          this.qty = response.data.qty;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
            this.append_forbidden_error(
              "Make Sure You Have Permission To View Products"
            );
          }
          console.log(`Error while fetching products ! : ${e}`);
        });
    },
    filter(e) {
      const fn = e.target.value;
      console.log(fn, this.selected);

      if (this.selected === fn) {
        e.path[0].checked = false;
        return this.get_products();
      }
      this.selected = fn;
      this.$http
        .get(`/inventory/get_products_quantities/?${fn}=true`)
        .then((response) => {
          this.products = response.data.results;
          this.qty = response.data.qty;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(`Error while filtering products ! : ${e}`);
        });
    },
    search(e) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => {
        this.$http
          .get(`/inventory/get_products_quantities/?name=${e.target.value}`)
          .then((response) => {
            this.products = response.data.results;
            this.qty = response.data.qty;
            this.pagination = response.data.pagination;
          })
          .catch((e) => {
            console.log(`Error while filtering products ! : ${e}`);
          });
      }, 800);
    },
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  created() {
    this.get_products();
  },
};
</script>

<style lang="scss" scoped>
.stock {
  width: 100%;
  height: 100%;
}
.filters,
.search,
.indicators {
  display: flex;
  flex-wrap: wrap;
}

.filters {
  margin: 1rem 0;
  gap: 2rem;
  label {
    flex: 1;
    font-size: 1.2rem;
    input[type="radio"] {
      transform: scale(1.5);
      margin: 0.22rem;
    }
  }
}

.search {
  gap: 0.45rem;
  & > label {
    flex: 3;
    input {
      width: 100%;
    }
  }
  .box {
    flex-basis: 80px;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;

    display: flex;
    justify-content: center; /* center flex items vertically */
    align-items: center; /* center flex items horizontally */

    &:nth-child(2) {
      background: $error-600;
    }
    &:nth-child(3) {
      background: $primary-400;
    }
    &:nth-child(4) {
      background: $success-700;
    }
  }
}
.table-wrapper {
  //min-height: 75%;
  //max-height: 75%;
  overflow: auto;
}
.pag {
  margin: 1% 0;
  position: relative;
  display: flex;
  & > * {
    flex: 1;
  }
  span {
    text-align: center;
  }
}
</style>
