<template>
  <div class="addons full-height">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('sidebar.products'), route: 'management_products' },
        { name: $t('sidebar.addons'), route: '' },
      ]"
    />
    <div class="addons-content d-flex flex-wrap gap-3">
      <div class="addons col-12 col-sm-4">
        <div class="addons-wrapper d-flex flex-wrap gap-3">
          <template v-for="{ id, name } in addons">
            <button
              v-if="name"
              :key="id"
              @click="set_addon(id)"
              :class="{ 'active-item': current_addon_obj.id === id }"
              class="col"
            >
              {{ name }}
            </button>
            <button
              v-else
              :key="id"
              @click="set_addon(id)"
              :class="{ 'active-item': current_addon_obj.id === id }"
              style="color: gray"
            >
              {{ $t("common.new") }}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 12H16"
                  stroke="gray"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 16V8"
                  stroke="gray"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="gray"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </template>
        </div>
      </div>
      <div class="addon-type col-2">
        <div class="addon-type-buttons d-flex flex-column p-1 gap-3">
          <button
            v-for="type in current_addon_obj.types"
            :key="type.id"
            @click="set_addon_type(type)"
            :class="{
              'active-item': checked_addons_types_ids.includes(type.id),
            }"
          >
            {{ type.name }}
          </button>
        </div>
      </div>
      <!--current selected details-->
      <div class="addons-details full-height col p-3">
        <div class="inputs">
          <div v-if="current_layout === 'addon'" class="">
            <input
              v-model="current_addon_obj.name"
              @input="create_new_addon_room"
              type="text"
              class="addon-input w-100"
              placeholder="Addon Name"
            />
          </div>
          <div v-else-if="current_layout === 'type'">
            <input
              v-model="current_addon_type.name"
              @input="create_new_addon_type_room"
              type="text"
              class="addon-input w-100"
              placeholder="Type Name"
            />

            <input
              v-model="current_addon_type.price"
              type="text"
              class="mt-4 addon-input w-100"
              placeholder="Type Price"
            />
          </div>
        </div>
        <div class="buttons d-flex flex-wrap gap-3 pt-5 pt-md-0">
          <button @click="save" class="pos-primary-btn col-4">
            {{ $t("common.save") }}
          </button>
          <button @click="delete_addons" class="btn btn-danger col-4">
            {{ $t("common.delete") }}
          </button>
        </div>
      </div>
      <!--End of current selected details-->
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "addonsMain",
  components: { section_route },
  data() {
    return {
      addons: [],
      checked_addons_ids: [],
      checked_addons_types_ids: [],

      current_addon_obj: {},
      current_addon_type: {},

      current_layout: "",
    };
  },
  methods: {
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
    new_addon_obj() {
      return {
        id: new Date().toISOString(),
        name: "",
        types: [{ id: new Date().toISOString(), name: "", price: "" }],
      };
    },
    new_addon_type_obj() {
      return {
        id: new Date().toISOString(),
        addon: this.current_addon_obj.id,
        name: "",
        price: "",
      };
    },
    create_new_addon_room() {
      // if there is a place for new addon
      const last_element = this.addons[this.addons.length - 1];

      // check if current selected object is empty and the last
      // button is empty in order to create a place for new addon
      if (
        this.current_addon_obj.name.length > 0 &&
        last_element.name.length > 0
      ) {
        this.addons.push(this.new_addon_obj());
      }

      // delete last new addon button if current name is empty
      if (this.current_addon_obj.name.length === 0) {
        this.addons.pop();
      }
    },
    create_new_addon_type_room() {
      // find selected addon in the addons array
      let current_addon_index = this.addons.findIndex(
        (addon) => addon.id === this.current_addon_obj.id
      );

      // if user deselect all addons
      if (current_addon_index < 0) {
        return;
      }

      const current_addon = this.addons[current_addon_index];
      const current_addon_types = current_addon.types;
      const last_addon_type =
        current_addon_types[current_addon_types.length - 1];

      // if there is no addon_types room
      if (!last_addon_type) {
        this.addons[current_addon_index].types.push(this.new_addon_type_obj());
      }
      if (last_addon_type.name.length > 0) {
        this.addons[current_addon_index].types.push(this.new_addon_type_obj());
      }
    },
    get_addons() {
      this.$http
        .get(`/inventory/addons?all=true`)
        .then((response) => {
          this.addons = response.data;
          this.addons.push(this.new_addon_obj());
        })
        .catch((e) => {
          console.log(`Error while fetching addons ! : ${e}`);
        });
    },
    set_addon(id) {
      // set selected_addon || deselect current addon
      if (id === this.current_addon_obj.id) {
        this.current_addon_obj = {};
        this.checked_addons_types_ids = [];
        this.current_layout = "";
      } else {
        this.current_addon_obj = this.addons.find((e) => e.id === id);
        this.checked_addons_types_ids = [];
        this.current_layout = "addon";
      }
      // add an empty addon_type room if there is not
      this.create_new_addon_type_room();
    },
    set_addon_type(type) {
      if (this.checked_addons_types_ids.includes(type.id)) {
        this.checked_addons_types_ids = this.checked_addons_types_ids.filter(
          (id) => id !== type.id
        );
      } else {
        this.checked_addons_types_ids = [];
        this.checked_addons_types_ids.push(type.id);
        this.current_addon_type = type;
      }
      this.current_layout = "type";
    },
    set_checked_addons(id) {
      if (this.checked_addons_ids.includes(id)) {
        this.checked_addons_ids.splice(this.checked_addons_ids.indexOf(id), 1);
      } else {
        this.checked_addons_ids.push(id);
      }
    },
    save() {
      // optimizing addons before sending to the server

      let addons = this.addons.filter((addon) => addon.name.length > 0); // skip empty addon rooms

      for (let i = 0; i < addons.length; i++) {
        // delete id property if it is a timestamp
        if (isNaN(addons[i].id)) {
          delete addons[i].id;
        }

        // skip empty addon_types rooms
        addons[i].types = addons[i].types.filter(
          (type) => type.name.length > 0
        );

        for (let type of addons[i].types) {
          // delete id property if it is a timestamp
          if (isNaN(type.id)) {
            delete type["id"];
          }
          // deleting addon id if it's a timestamp
          if (isNaN(type.addon)) {
            delete type["addon"];
          }
        }
      }

      this.$http
        .put(`/inventory/addons`, addons)
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: `Addons Saved Successfully`,
            type: "success",
          });
          this.get_addons(); // todo: find a better approach
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: `Error While Updating addons ${e}`,
            type: "danger",
          });
          console.log(`Error while Updating addons ! : ${e}`);
        });

      this.clear();
    },
    delete_addons() {
      // check if selected type is addon or addon_type
      if (this.current_layout === "addon") {
        const obj_id = this.current_addon_obj.id;
        this.$http
          .delete("/inventory/addons", {
            data: { pk: this.current_addon_obj.id, type: "addon" },
          })
          .then(() => {
            this.$store.dispatch("utils/push_alert", {
              message: `Addon Deleted`,
              type: "warning",
            });

            this.addons = this.addons.filter((a) => a.id !== obj_id);
          })
          .catch((err) => {
            console.log("Error while trying to delete an addon", err);
          });
      } else if (this.current_layout === "type") {
        this.$http
          .delete("/inventory/addons", {
            data: { pk: this.current_addon_type.id, type: "type" },
          })
          .catch((err) => {
            console.log("Error while trying to delete an addon type", err);
          });
      }
      this.clear();
    },
    clear() {
      this.current_layout = "";
      this.current_addon_obj = {};
      this.selected_addons = [];
    },
  },
  mounted() {
    this.get_addons();
  },
};
</script>

<style lang="scss" scoped>
.addons {
  background: $main-white;
  border-radius: 8px;

  .addons-content {
    min-height: 95%;
    max-height: 95%;
    height: 95%;
    padding: 1rem;
    .addon-input {
      border: 1px solid $secondary-200;
      padding: 1rem;
      border-radius: 8px;
    }

    .addons-wrapper {
      padding: 1rem;
      position: relative;

      button {
        background: $main-white;
        border: 2px solid $secondary-300;
        border-radius: 8px;
        height: 56px !important;
        font-size: 15px;
        padding: 1rem;
      }

      .active-item {
        background: $primary-100;
      }
    }

    .addon-type {
      background: $secondary-background-color;
      border-radius: 10px;
      //padding: 2px 0;
      overflow: hidden;

      .addon-type-buttons {
        button {
          // to center the text vertically
          display: flex;
          justify-content: center;
          padding-top: 1.2rem;
          font-weight: bold;
          background: $main-white;
          border: 2px solid $secondary-300;
          border-radius: 8px;
          height: 56px;
        }

        button.active-item {
          background: $primary-100;
        }
      }
    }

    .addons-details {
      height: 100%;
      background: $secondary-background-color;
      border-radius: 5px;
      .inputs {
        height: 94%;
        min-height: 94%;
      }
      .buttons {
        height: 6%;
        button {
          padding: 1% 5%;
        }
      }
    }
  }
}
</style>
