<template>
  <div class="customer-display">
    <div class="sec-name">Customer Display</div>
    <div class="mt-4">
      <div class="line">
        <div class="left">
          <p class="header">Active</p>
          <p class="desc">Enable or disable the customer screen</p>
        </div>
        <!-- /.left -->
        <div class="right">toggle</div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
      <div class="line">
        <div class="left">
          <p class="header">Com Port</p>
          <p class="desc">Port number that connected to the screen</p>
        </div>
        <!-- /.left -->
        <div class="right">
          <input type="number" class="pos-input" placeholder="Type a Text" />
          <!-- /.pos-input -->
        </div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
      <div class="line">
        <div class="left">
          <p class="header">Header</p>
          <p class="desc">screen header text</p>
        </div>
        <!-- /.left -->
        <div class="right">
          <input type="number" class="pos-input" placeholder="Type a Text" />
          <!-- /.pos-input -->
        </div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
      <div class="line">
        <div class="left">
          <p class="header">Footer</p>
          <p class="desc">screen footer text</p>
        </div>
        <!-- /.left -->
        <div class="right">
          <input type="number" class="pos-input" placeholder="Type a Text" />
          <!-- /.pos-input -->
        </div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
    </div>
    <!-- /.mt-5 -->
  </div>
</template>

<script>
export default {
  name: "CustomerDisplay",
};
</script>

<style lang="scss" scoped>
.customer-display {
  width: 100%;
  height: 100%;
  .line {
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 20px;
      color: $secondary-500;
      padding: 0;
      margin: 0;
    }
    .desc {
      color: #808080;
    }
    .right input {
      padding: 0.5rem;
    }
  }
}
</style>
