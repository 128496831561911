<template>
  <div class="purchases full-height pb-5">
    <div class="d-flex flex-column flex-md-row gap-3 full-height">
      <div class="layout-sidebar col-12 col-md-3">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            {
              name: $t('sidebar.purchases'),
              route: '',
            },
          ]"
          class="pb-2"
        />
        <div class="layout-menu-buttons d-flex flex-wrap gap-3">
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'all_purchases' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("sidebar.purchases") }}
            </a>
          </router-link>

          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'purchases_refund' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("purchase.purchase_refund") }}
            </a>
          </router-link>
        </div>
      </div>
      <div class="content-wrapper col">
        <div class="router-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "PurchasesMain",
  components: { section_route },
};
</script>

<style scoped></style>
