<template>
  <div class="users-crud full-height pb-5 pb-0">
    <!--    New User Modal-->
    <div
      class="modal fade"
      id="add-user-modal"
      tabindex="-2"
      aria-hidden="true"
    >
      <button
        type="button"
        class="d-none btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header row">
            <slot name="header">
              <h5 class="modal-title text-start col-md-8 text-danger">
                {{ $t("users_and_security.add_new_user") }}
              </h5>
            </slot>
          </div>
          <div class="modal-body">
            <form @submit.prevent="new_user" id="nuf" class="row">
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.first_name"
                  type="text"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">
                  {{ $t("common.first_name") }}
                </label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.last_name"
                  type="text"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">
                  {{ $t("common.last_name") }}
                </label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.email"
                  type="email"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">
                  {{ $t("common.email") }}
                </label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.phone_number"
                  type="tel"
                  class="did-floating-input"
                  placeholder=""
                />
                <label class="did-floating-label">Phone Number</label>
              </div>
              <div class="col-md-12 did-floating-label-content">
                <input
                  v-model="form.password"
                  type="password"
                  class="did-floating-input"
                  placeholder=""
                  autocomplete="off"
                  required
                />
                <label class="did-floating-label required">
                  {{ $t("common.password") }}
                </label>
              </div>
              <div class="col-md-12">
                <select
                  ref="permission_group_dropdown"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="-1" selected>
                    {{ $t("users_and_security.select_perms_group") }}
                  </option>
                  <template>
                    <option v-for="g in groups" :key="g.id" :value="g.id">
                      {{ g.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-md-12 mt-3">
                <div v-show="error_msg" class="alert alert-danger" role="alert">
                  {{ error_msg }}
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer d-flex">
            <button
              @click="close_current_modal"
              type="button"
              class="btn btn-secondary"
            >
              {{ $t("common.cancel") }}
            </button>
            <input
              type="submit"
              form="nuf"
              class="btn btn-primary px-5"
              :value="$t('common.save')"
            />
          </div>
        </div>
      </div>
    </div>
    <!--    END OF new user modal -->

    <!--    UPDATE User Modal-->
    <div
      class="modal fade"
      id="update-user-modal"
      tabindex="-2"
      aria-hidden="true"
    >
      <button
        type="button"
        class="d-none btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header row">
            <slot name="header">
              <h5 class="modal-title text-start col-md-8 text-danger">
                {{ $t("common.edit") }}
                {{ selected_item.name }}
              </h5>
            </slot>
          </div>
          <div class="modal-body">
            <form @submit.prevent="update_user" id="uuf" class="row">
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.first_name"
                  type="text"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">First Name</label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.last_name"
                  type="text"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">Last Name</label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.email"
                  type="email"
                  class="did-floating-input"
                  placeholder=""
                  required
                />
                <label class="did-floating-label required">
                  {{ $t("common.email") }}
                </label>
              </div>
              <div class="col-md-6 did-floating-label-content">
                <input
                  v-model="form.phone_number"
                  type="tel"
                  class="did-floating-input"
                  placeholder=""
                />
                <label class="did-floating-label">
                  {{ $t("common.phone") }}
                </label>
              </div>

              <div class="col-md-12">
                <select
                  ref="update_permission_group_dropdown"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="-1" :selected="groups.length < 2">
                    {{ $t("users_and_security.select_perms_group") }}
                  </option>
                  <template>
                    <option
                      v-for="g in groups"
                      :key="g.id"
                      :value="g.id"
                      :selected="g.id === get_user_current_group.id"
                    >
                      {{ g.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-md-12 mt-3">
                <div v-show="error_msg" class="alert alert-danger" role="alert">
                  {{ error_msg }}
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer d-flex">
            <button
              @click="close_current_modal"
              type="button"
              class="btn btn-secondary"
            >
              {{ $t("common.cancel") }}
            </button>
            <input
              type="submit"
              form="uuf"
              class="btn btn-primary px-5"
              :value="$t('common.edit')"
            />
          </div>
        </div>
      </div>
    </div>
    <!--    END OF  UPDATE modal -->

    <!--    DELETE CONFIRMATION MODAL -->
    <div class="modal fade" id="del-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              {{ selected_item.first_name }}
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_users" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!--    END OF DELETE CONFIRMATION  MODAL-->

    <!--    UPDATE PASSWORD MODAL -->
    <div
      class="modal fade"
      id="p-update-modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <form
            @submit.prevent="update_password"
            id="p-update-form"
            class="modal-body"
          >
            <div class="col-auto">
              <div class="col-auto">
                <span id="firstname-inline" class="form-text required">
                  {{ $t("users_and_security.new_password") }}
                </span>
              </div>
              <input
                v-model="new_password"
                type="password"
                class="form-control"
                aria-describedby="firstname-inline"
                autocomplete="off"
                required
              />
            </div>
          </form>
          <div class="modal-footer">
            <input
              type="submit"
              form="p-update-form"
              class="btn btn-primary px-5"
              :value="$t('common.edit')"
            />
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!--    UPDATE PASSWORD  MODAL-->

    <div class="d-flex justify-content-between align-items-baseline mb-4">
      <p class="sec-name mb-3">
        {{ $t("users_and_security.users_and_perms") }}
      </p>
      <button
        @click="reset_form"
        data-bs-toggle="modal"
        data-bs-target="#add-user-modal"
        class="pos-primary-btn px-5 py-3"
      >
        {{ $t("common.add") }}
      </button>
    </div>

    <table class="primary-table">
      <thead>
        <tr>
          <th>{{ $t("common.first_name") }}</th>
          <th>{{ $t("common.last_name") }}</th>
          <th>{{ $t("common.email") }}</th>
          <th>{{ $t("common.phone") }}</th>
          <th>
            {{ $t("users_and_security.group_perms") }}
          </th>
          <th>{{ $t("common.last_login") }}</th>
          <th>{{ $t("common.creation_date") }}</th>
          <th>{{ $t("common.active") }}</th>
          <th>{{ $t("common.control") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone_number }}</td>
          <td>
            <span v-for="group in user.groups" :key="group.id"
              >{{ group.name }}
            </span>
          </td>
          <td>
            {{
              user.last_login
                ? new Date(user.last_login).toLocaleDateString()
                : ""
            }}
          </td>
          <td>{{ new Date(user.date_joined).toLocaleDateString() }}</td>
          <td>
            <input type="checkbox" :checked="user.is_active" disabled />
          </td>
          <td
            class="d-flex flex-wrap gap-2 justify-content-around control-btns"
          >
            <button
              @click="
                () => {
                  set_selected_user_by_id(user.id);
                }
              "
              class="btn btn-default"
              data-bs-toggle="modal"
              data-bs-target="#p-update-modal"
              :title="$t('users_and_security.change_password')"
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 8V6.5C4.5 4.0175 5.25 2 9 2C12.75 2 13.5 4.0175 13.5 6.5V8"
                  stroke="#1A202C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.75 17H5.25C2.25 17 1.5 16.25 1.5 13.25V11.75C1.5 8.75 2.25 8 5.25 8H12.75C15.75 8 16.5 8.75 16.5 11.75V13.25C16.5 16.25 15.75 17 12.75 17Z"
                  stroke="#1A202C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9973 12.5H12.0041"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.99661 12.5H9.00335"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.99588 12.5H6.00262"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <img
              @click="
                () => {
                  form = get_selected_user_by_id(user.id);
                }
              "
              data-bs-toggle="modal"
              data-bs-target="#update-user-modal"
              :src="$get_icon('edit')"
              class="btn btn-success"
              :title="$t('common.edit')"
              alt=""
            />
            <img
              @click="
                () => {
                  set_selected_user_by_id(user.id);
                }
              "
              data-bs-toggle="modal"
              data-bs-target="#del-modal"
              :src="$get_icon('delete')"
              class="btn btn-danger"
              :title="$t('common.delete')"
              alt=""
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";

export default {
  name: "UsersCRUD",
  computed: {
    get_user_current_group: function () {
      // user has only 2 groups the selected one will always be the second one
      // this first group will be default group
      // -1 to prevent undefined in template render
      return this.form.groups[1] || -1;
    },
  },
  data() {
    return {
      users: [],
      groups: [],

      selected_item: {},

      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        groups: [],
      },
      new_password: "",
      error_msg: "",
    };
  },
  methods: {
    close_current_modal: close_current_modal,
    get_users() {
      this.$http
        .get(`/accounts/employee`)
        .then((response) => {
          this.users = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_permission_groups() {
      this.$http
        .get(`/accounts/get_permission_groups`)
        .then((response) => {
          this.groups = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while fetching permission groups : ${e}`);
        });
    },
    set_selected_user_by_id(id) {
      this.selected_item = this.users.find((i) => i.id === id);
    },
    get_selected_user_by_id(id) {
      return this.users.find((i) => i.id === id);
    },
    new_user() {
      if (document.getElementById("nuf").checkValidity()) {
        const selected_group = this.$refs.permission_group_dropdown.value;
        if (selected_group !== "-1") {
          this.form["custom_group"] = selected_group;
        }
        this.$isLoading(true);
        this.$http
          .post(`/accounts/employee`, this.form)
          .then((response) => {
            this.close_current_modal();
            this.users.push(response.data);
          })
          .catch((e) => {
            this.error_msg = e.response.data;
            console.log(
              `Error while creating new employee ! : ${e} ${e.response}`
            );
          })
          .finally(() => {
            this.$isLoading(false);
          });
      }
    },
    update_password() {
      this.$http
        .put(`/accounts/change_password/${this.selected_item.id}`, {
          password: this.new_password,
        })
        .then(() => {
          this.close_current_modal();
          this.$store.dispatch("utils/push_alert", {
            message: "User Password Updated Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          this.error_msg = e.response.data;
          console.log(
            `Error while updating employee password ! : ${e} ${e.response}`
          );
        });
    },
    update_user() {
      if (document.getElementById("uuf").checkValidity()) {
        const s_data = {
          id: this.form.id,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          phone_number: this.form.phone_number,
        };
        const selected_group =
          this.$refs.update_permission_group_dropdown.value;

        s_data["custom_group"] =
          selected_group === "-1" ? null : selected_group;

        this.$http
          .put(`/accounts/employee`, s_data)
          .then((response) => {
            this.close_current_modal();
            this.$store.dispatch("utils/push_alert", {
              message: "User Information Updated Successfully",
              type: "success",
            });
            const user_index = this.users.findIndex(
              (user) => user.id === response.data.id
            );
            this.users[user_index] = response.data;
          })
          .catch((e) => {
            this.error_msg = e.response.data;
            console.log(
              `Error while creating new employee ! : ${e} ${e.response}`
            );
          })
          .finally(() => {
            this.reset_form();
          });
      }
    },
    delete_users() {
      this.close_current_modal();
      this.$http
        .delete(`/accounts/employee`, {
          data: { user_id: this.selected_item.id },
        })
        .then(() => {
          this.users = this.users.filter(
            (user) => user.id !== this.selected_item.id
          );
          this.$store.dispatch("utils/push_alert", {
            message: "User Deleted Successfully",
            type: "warning",
          });
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: e.response.data,
            type: "danger",
          });
          console.log(`Error while Deleting Employees ! : ${e}`);
        });
    },
    reset_form() {
      this.form = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        groups: [],
      };
    },
  },
  mounted() {
    this.get_users();
    this.get_permission_groups();
  },
};
</script>

<style lang="scss" scoped>
.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: $main-white;
  color: #323840;
  border: 1px solid $secondary-background-color;
  border-radius: 4px;
  box-sizing: border-box;

  outline: none;
  ~ .did-floating-label {
    top: -8px;
    font-size: 13px;
  }
}

.users-crud {
  .pos-button {
    padding: 1rem 2rem;
    margin: 0 0.5rem;
  }
}
</style>
