import InvoiceList from "@/views/management/reports/invoice_reports/InvoiceList";

export default [
  {
    path: "/management/reports/invoices/",
    component: InvoiceList,
    name: "invoice_report",
    meta: {
      title: () => {
        return "OnePos | Invoices";
      },
    },
  },
];
