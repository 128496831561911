export default {
  // orders
  orders: "الطلبات",
  prevent_negative: "عدم السماح بكمية سالبة",
  prevent_negative_desc: "ايقاف البيع عندما تكون كمية المنتج تساوي صفر",
  reset_invoice: "تصفير رقم الفاتورة",
  rest_invoice_desc:
    "رقم الفاتورة يبدأ من صفر عندما يقوم اي مستخدم بإنهاء اليومية",

  allow_changing_price: "السماح بتغيير السعر",
  allow_changing_price_desc: "يمكن للمستخدم تغير سعر البيع للمنتج اثناء البيع",

  discount_type: "نوع الخصم",
  discount_type_desc:
    "الطريقة اللتي تريد تطبيق الخصم بها علي الطلبات  اذا كان قبل او بعد الضريبة",
  before_tax: "قبل الضريبة",
  after_tax: "بعد الضريبة",

  // weighting Scale
  w_scale: "اعدادت الميزان والباركود",
  w_scale_desc: "تفعيل الميزان عند البحث علي المنتجات في شاشة البيع",
  price_desc: "السعر بدلاً من الكمية",
  price_decimal_places: "عدد المنازل العشرية للسعر",
  price_decimal_places_desc: "عدد المنازل العشرية لسعر المنتج",
  weight_decimal_places: "عدد المنازل العشرية للوزن",
  weight_decimal_places_desc: "عدد المنازل العشرية للوزن في ميزان 18 فما فوق",

  // taxes
  add_new_tax: "إضافة ضريبة جديدة",
  value_in_percentage: "القيمة بالنسبة المئوية",

  // payment types
  payment_types: "انواع الدفع",
  add_new_payment: "اضافة نوع دفع جديد",
  mark_as_paid: "تحديد كمدفوعة",
  customer_required: "تتطلب عميل",
};
