import icons from "@/assets/icons";

export function get_icon(iconName) {
  return icons[iconName];
}

export function get_route_url(routeName, params) {
  const resolvedRoute = this.$router.resolve({
    name: routeName,
    params: params,
  });
  return resolvedRoute.href;
}
