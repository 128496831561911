import { render, staticRenderFns } from "./UsersPermissions.vue?vue&type=template&id=1799a91b&scoped=true"
import script from "./UsersPermissions.vue?vue&type=script&lang=js"
export * from "./UsersPermissions.vue?vue&type=script&lang=js"
import style0 from "./UsersPermissions.vue?vue&type=style&index=0&id=1799a91b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1799a91b",
  null
  
)

export default component.exports