import InventoryTransfer from "@/views/management/inventory/transfer/InventoryTransfer";
import NewTransfer from "@/views/management/inventory/transfer/NewTransfer";
import DisplayInventoryTransfer from "@/views/management/inventory/transfer/DisplayInventoryTransfer";

export default [
  {
    path: "inventory_transfer/",
    component: InventoryTransfer,
    name: "inventory_transfer",
    meta: {
      title: () => {
        return "OnePos | Inventory Transfer";
      },
    },
  },
  {
    path: "inventory_transfer/new",
    component: NewTransfer,
    name: "new_transfer",
    meta: {
      title: () => {
        return "OnePos | New transfer";
      },
    },
  },
  {
    path: "inventory_transfer/transfer_details/:report_id",
    component: DisplayInventoryTransfer,
    name: "transfer_details",
    meta: {
      title: (route) => {
        return `OnePos | Transfer ${route.params.report_id} `;
      },
    },
  },
];
