<template>
  <div class="keypad">
    <div class="row">
      <div class="numbers col-md-9">
        <button class="keypad-btn" @click="insert(1)">1</button>
        <button class="keypad-btn" @click="insert(2)">2</button>
        <button class="keypad-btn" @click="insert(3)">3</button>
        <button class="keypad-btn" @click="insert(4)">4</button>
        <button class="keypad-btn" @click="insert(5)">5</button>
        <button class="keypad-btn" @click="insert(6)">6</button>
        <button class="keypad-btn" @click="insert(7)">7</button>
        <button class="keypad-btn" @click="insert(8)">8</button>
        <button class="keypad-btn" @click="insert(9)">9</button>
        <button class="keypad-btn" @click="insert('00')">00</button>
        <button class="keypad-btn" @click="insert('0')">0</button>
        <button class="keypad-btn" @click="insert('.')">.</button>
      </div>
      <div class="controllers col-md-3">
        <div class="row">
          <button @click="reset" class="ctrl-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="m9.17 14.83 5.66-5.66m0 5.66L9.17 9.17M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7Z"
              />
            </svg>
          </button>
          <button
            @click="delete_char"
            class="ctrl-btn"
            style="font-weight: bold; font-size: 18px"
          >
            ESC
          </button>
          <button @click="add_quantity" class="ctrl-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              fill="none"
              viewBox="0 0 20 17"
            >
              <path
                fill="#000"
                d="M19 .5a1 1 0 0 1 .993.883L20 1.5V8a3.5 3.5 0 0 1-3.308 3.495l-.192.005H3.415l3.292 3.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083-5-5a1.006 1.006 0 0 1-.097-.112l-.071-.11-.054-.114-.035-.105-.025-.117-.007-.06L0 10.5l.003-.075.017-.126.03-.111.044-.111.052-.098.064-.092.083-.094 5-5a1 1 0 0 1 1.497 1.32l-.083.094L3.415 9.5H16.5a1.5 1.5 0 0 0 1.493-1.355L18 8V1.5a1 1 0 0 1 1-1Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";

export default {
  name: "main_keypad",
  props: ["enter"],
  methods: {
    insert(char) {
      const node = this.$store.getters["keyboard/active_input"];
      node.value += char;
      node.focus();
    },
    delete_char() {
      const node = this.$store.getters["keyboard/active_input"];
      node.value = node.value.slice(0, -1);
      node.focus();
    },
    reset() {
      const node = this.$store.getters["keyboard/active_input"];
      node.value = "";
      this.$store.dispatch("keyboard/reset_quantity");
      node.focus();
    },
    add_quantity() {
      const qty = this.$store.getters["keyboard/active_input"].value;
      if (this.enter) {
        this.enter(qty);
      } else {
        if (this.$parent.selected_item.index >= 0) {
          this.$parent.append_to_selected_item(qty);
        }
      }
      this.reset();
      close_current_modal();
    },
  },
};
</script>

<style lang="scss" scoped>
.row > * {
  margin-left: 0;
  padding-left: 0;
}

.keypad,
.keypad-btn,
.ctrl-btn {
  background: $main-white;
}

.keypad {
  .keypad-btn {
    padding: 20px;
    width: 31.33333%;
    height: 56px;
    border: 1px solid $keypad-border;
    border-radius: 7px;
    margin: 3px;
  }

  .controllers {
    .ctrl-btn {
      border: 2px solid $primary-500;
      border-radius: 5px;
      margin-top: 0.3rem;
    }

    .ctrl-btn:nth-child(1),
    .ctrl-btn:nth-child(2) {
      height: 4rem;
    }

    .ctrl-btn:nth-child(3) {
      height: 8rem;

      svg path {
        fill: #000 !important;
      }
    }
  }
}
</style>
