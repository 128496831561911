import new_product from "./new_product/new_product_en";

export default {
  ...new_product,
  manage_groups: "Manage Groups",
  add_new_group: "Add New Group",
  add_new_product: "Add New Product",
  group_name: "Group Name",
  edit_group: "Edit the Group",
  confirm_delete_product: "Are You Sure you want to delete Product",
  // taxes
  add_new_tax: "Add New Tax",
  value_in_percentage: "Value in Percentage",
  new_tax_placeholder: "i.e 15 mean 15%",
};
