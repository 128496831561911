<template>
  <nav
    v-if="is_logged_in"
    class="navbar navbar-light bg-light m-0 p-0 d-block d-md-none"
  >
    <div id="hamburger-floating" class="">
      <input type="checkbox" @change="show_mobile_sidebar" />
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div
      v-if="get_navbar_content.type === 'html'"
      v-html="get_navbar_content.content"
    ></div>
    <component
      v-else-if="get_navbar_content.type === 'component'"
      :is="get_navbar_content.content"
    />
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  computed: {
    ...mapGetters("auth", ["is_logged_in"]),
    ...mapGetters("navbar", ["get_navbar_content"]),
  },
  methods: {
    show_mobile_sidebar() {
      const app = this.$parent;
      app.show_sidebar = true;
      app.sidebar_config.collapsed = false;
      app.$refs.static_hamburger_input.checked = true;
    },
  },
};
</script>

<style scoped></style>
