<template>
  <div class="card-container h-100">
    <div class="d-flex flex-column justify-content-center card-wrapper">
      <div v-if="group_info.image" class="card-image col-12">
        <img class="img-fluid" :src="group_info.image" alt="product image" />
      </div>
      <div
        :class="group_info.image ? 'fw-bold mt-1' : 'no-image'"
        class="col-12"
      >
        <p class="product-name">{{ group_info.name }}</p>
        <p class="product-price text-danger">{{ $t("sell_screen.group") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "group_card",
  props: ["group_info"],
};
</script>
<style lang="scss" scoped>
.img-fluid {
  width: 100%;
  min-height: 4.3rem;
  max-height: 4.3rem;
}
.card-container {
  background: $primary-200;
  border-radius: 5px;
}
.no-image {
  margin: 25% 0;
}
.product-name,
.product-price {
  font-weight: bold;
}

.product-name {
  font-size: 1.2rem;
}
</style>
