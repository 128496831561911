<template>
  <div class="printer-settings">
    <div class="sec-name">Printers Settings</div>
    <div class="mt-4"></div>
    <!-- /.mt-3 -->
  </div>
  <!-- /.database-settings -->
</template>

<script>
export default {
  name: "PrinterSettings",
};
</script>

<style lang="scss" scoped>
.database-settings {
  width: 100%;
  height: 100%;
}
</style>
