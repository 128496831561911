import UsersCRUD from "@/views/management/users_and_secuirty/UsersCRUD";

export default [
  {
    path: "manage/",
    component: UsersCRUD,
    name: "users_crud",
    meta: {
      title: () => {
        return "OnePos | Users&Security";
      },
    },
  },
];
