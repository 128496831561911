export default {
  profile: "Profile",
  sell: "Sell",
  history: "History",
  management: "Management",
  dashboard: "Dashboard",
  data_report: "Data Reports",
  products: "Products",
  all_products: "All Products",
  addons: "Addons",
  item_box: "ItemBox",
  price_tags: "Price Tags",
  inventory: "Inventory",
  users_and_security: "Users And Security",
  clients_and_suppliers: "Clients And Suppliers",
  purchases: "Purchases",
  promos: "Promos",
  company: "Company",
  branches: "Branches",
  zatca: "Zatca",
  settings: "Settings",
  logout: "Logout",
  logout_confirm: "Are You Sure you want to Logout ?",
};
