const state = {
  groups: [],
  selection: {
    selected_group: {},
    // parent group pk
    new_group_parent: null,
  },
};

const actions = {
  set_groups({ commit }, groups) {
    commit("set_groups", groups);
  },
  set_selection({ commit }, selection) {
    commit("set_selection", selection);
  },
  set_selected_group({ commit }, selected_group) {
    commit("set_selected_group", selected_group);
  },
};
const mutations = {
  set_groups: (state, groups) => {
    state.groups = groups;
  },
  set_selection: (state, selection) => {
    state.selection = selection;
  },
  set_selected_group: (state, selected_group) => {
    state.selection = {
      ...state.selection,
      selected_group: selected_group,
    };
  },
};
const getters = {
  get_groups: (state) => {
    return state.groups;
  },
  get_selection: (state) => {
    return state.selection;
  },
  get_selected_group: (state) => {
    return state.selection.selected_group;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
