<template>
  <div
    class="card-container h-100"
    :style="{ background: product_info.image ? `#ced9ff` : product_info.color }"
  >
    <div class="d-flex flex-column justify-content-center card-wrapper">
      <div v-if="product_info.image" class="card-image col-12">
        <img class="img-fluid" :src="product_info.image" alt="product image" />
      </div>
      <div
        :class="product_info.image ? ' fw-bold mt-1' : ' no-image'"
        class="col-12"
      >
        <p class="product-name">{{ product_info.name }}</p>
        <p class="product-price">{{ product_info.price_with_tax }}$</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product_card",
  props: ["product_info"],
};
</script>
<style lang="scss" scoped>
.img-fluid {
  width: 100%;
  min-height: 4.3rem;
  max-height: 4.3rem;
}

.card-container {
  //background: $primary-200;
  border-radius: 5px;
}

.product-name,
.product-price {
  font-size: 1.2rem;
  font-weight: bold;
}
.product-price {
  color: #3366ff;
  font-size: 0.98rem;
  line-height: 1rem;
}
.no-image {
  margin: 25% 0;
}
</style>
