import PromosMain from "@/views/management/promos/PromosMain";

import ViewPromo from "@/views/management/promos/ViewPromo";
import CreateUpdatePromo from "@/views/management/promos/CreateUpdatePromo";

export default [
  {
    path: "/management/promotions",
    name: "promos",
    component: PromosMain,
    meta: {
      title: () => {
        return `OnePos | Promotions`;
      },
    },
  },
  {
    path: "/management/promotions/promo/:id",
    name: "view_promo",
    component: ViewPromo,
    meta: {
      title: (route) => {
        return `OnePos | Promo ${route.params.id}`;
      },
    },
  },
  {
    path: "/management/promotions/new_promo",
    name: "new_promo",
    component: CreateUpdatePromo,
    meta: {
      title: () => {
        return `OnePos | New Promo`;
      },
    },
  },
  {
    path: "/management/promotions/update_promo/:id",
    name: "update_promo",
    component: CreateUpdatePromo,
    meta: {
      title: () => {
        return `OnePos | Update Promo`;
      },
    },
  },
];
