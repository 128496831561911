<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.payment_by_users'), route: 'payments_by_users' },
      ]"
    />
    <div class="row py-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="report.payment_types.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-if="report.payment_types.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>{{ $t("common.name") }}</th>
              <th
                v-for="(payment_name, index) of report.payment_types"
                :key="index"
              >
                <span v-if="payment_name === 'total'">{{
                  $t("common.total")
                }}</span>
                <span v-else>{{ payment_name }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) of report.users" :key="index">
              <td>{{ record.name }}</td>
              <td v-for="(payment, p_index) of record.payments" :key="p_index">
                {{ payment }}
              </td>

              <template v-if="record.meta_data">
                <td v-for="col in record.meta_data.row_count" :key="col">
                  <span
                    v-if="col === record.meta_data.row_count"
                    class="fw-bold"
                  >
                    {{ record.meta_data.total }}
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PaymentTypesByUsers",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/payments_by_users/`,
      report: {
        payment_types: [],
        users: [
          // { name: "ahmed", payments: { cash: 10, credit: 20, visa: 30, total: 60 }
        ],
      },
    };
  },
  methods: {
    get_invoices() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.report.users = response.data;
          let payments = [];
          for (let payment_name of Object.keys(this.report.users[0].payments)) {
            payments.push(payment_name);
          }
          this.report.payment_types = payments;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "product_groups.pdf");
    },
  },
  mounted() {
    this.get_invoices();
  },
};
</script>

<style scoped></style>
