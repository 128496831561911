<template>
  <!-- overflow-hidden to prevent the main scroll bar -->
  <div class="products full-height p-1 overflow-hidden pb-5">
    <!--    DELETE PRODUCT CONFIRMATION MODAL -->
    <div class="modal fade" id="del-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("products.confirm_delete_product") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              <span style="font-size: 1.2rem">
                {{ current_product.name }}
              </span>
            </span>
          </div>

          <div class="modal-footer">
            <button @click="delete_product" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button
              @click="close_current_modal()"
              class="btn btn-secondary px-5"
            >
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!--    END OF DELETE CONFIRMATION  MODAL-->

    <div class="header-wrapper">
      <div class="row row-wrap align-items-baseline justify-content-between">
        <div class="col">
          <section_route
            :routes="[
              { name: $t('sidebar.management'), route: 'management' },
              { name: $t('sidebar.products'), route: 'management_products' },
            ]"
          />
        </div>
        <div class="col-5 text-start">
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'groups_management' }"
            class="manage-groups"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="text-decoration-none"
            >
              {{ $t("products.manage_groups") }}
            </a>
          </router-link>
        </div>
      </div>

      <div class="header">
        <!--        GROUP PATH -->
        <div class="group-path">
          <span @click="get_group_products(false)" class="pre">All > </span>
          <template v-for="group in group_path">
            <span
              :key="group.id"
              @click="get_group_products(group.id)"
              class="pre"
            >
              {{ group.name }} &#62;
            </span>
          </template>
          <span>{{ current_group.name }}</span>
        </div>
        <!--        END OF GROUP PATH-->

        <!--        GROUPS-->
        <div class="groups row gap-2 justify-content-start">
          <div
            v-for="group in groups"
            :key="group.id"
            class="group col-auto d-flex flex-column text-center align-items-center"
            @click="get_group_products(group.id)"
          >
            <div class="icon">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M43.8724 9.31258C44.1067 9.70479 43.6848 10.1486 43.2428 10.033C43.1534 10.0096 43.064 9.98782 42.9746 9.9675C41.9171 9.6525 40.7471 9.495 39.5546 9.495H31.8169C31.6596 9.495 31.5116 9.42106 31.4171 9.29537L28.6421 5.6025C28.5829 5.51852 28.5225 5.43562 28.4611 5.35357C28.2035 5.00973 28.437 4.5 28.8665 4.5H35.3696C38.9809 4.5 42.1498 6.42841 43.8724 9.31258Z"
                  fill="#6690FF"
                />
                <path
                  d="M45.315 14.715C44.3475 14.0175 43.245 13.5 42.0525 13.2075C41.2425 12.9825 40.41 12.87 39.555 12.87H31.185C29.88 12.87 29.79 12.7575 29.0925 11.835L25.9425 7.65C24.48 5.6925 23.3325 4.5 19.665 4.5H14.445C8.955 4.5 4.5 8.955 4.5 14.445V39.555C4.5 45.045 8.955 49.5 14.445 49.5H39.555C45.045 49.5 49.5 45.045 49.5 39.555V22.815C49.5 19.4625 47.8575 16.515 45.315 14.715ZM32.3775 36.765H21.6C20.7225 36.765 20.0475 36.0675 20.0475 35.19C20.0475 34.335 20.7225 33.615 21.6 33.615H32.3775C33.255 33.615 33.9525 34.335 33.9525 35.19C33.9525 36.0675 33.255 36.765 32.3775 36.765Z"
                  fill="#6690FF"
                />
              </svg>
            </div>
            <!-- /.icon -->
            <p class="group-name">{{ group.name }}</p>
            <!-- /.group-name -->
          </div>
        </div>
        <!--        END OF GROUPS-->
      </div>
    </div>
    <div class="middle">
      <div
        class="row row-wrap gap-3 justify-content-between align-items-baseline"
      >
        <div class="row col-md-8">
          <!-- todo: change this component to components/sell_screen/search_product -->
          <search_products class="col-md-11" />
        </div>
        <button
          @click="$router.push({ name: 'new_product' })"
          class="col-md-2 btn btn-outline-primary"
        >
          {{ $t("products.add_new_product") }}
        </button>
      </div>
      <div
        @scroll="onScroll"
        class="table-wrapper responsive-table mt-3 pb-5 pb-md-0"
      >
        <table class="primary-table table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>
                {{ $t("common.product_name") }}
              </th>
              <th>
                {{ $t("common.group") }}
              </th>
              <th>
                {{ $t("common.code") }}
              </th>
              <th>
                {{ $t("common.barcode") }}
              </th>

              <th>
                {{ $t("common.sale_price_before_tax") }}
              </th>
              <th>
                {{ $t("common.tax_total") }}
              </th>
              <th>
                {{ $t("common.sale_price") }}
              </th>
              <th>
                {{ $t("common.tax_inc") }}
              </th>
              <th>
                {{ $t("common.taxes") }}
              </th>
              <th>
                {{ $t("common.cost") }}
              </th>

              <th>
                {{ $t("common.supplier") }}
              </th>
              <th>
                {{ $t("common.active") }}
              </th>
              <th>
                {{ $t("common.control") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="product in products"
              @click="current_product = product"
              :key="product.id"
              :class="{
                'active-row': current_product.id === product.id,
              }"
            >
              <td data-label="ID">{{ product.id }}</td>
              <td :data-label="$t('common.product_name')">
                {{ product.name }}
              </td>
              <td :data-label="$t('common.group')">
                <span v-if="product.group">{{ product.group.name }}</span>
              </td>
              <td :data-label="$t('common.code')">
                {{ product.code }}
              </td>
              <td :data-label="$t('common.barcode')">
                {{ product.barcode }}
              </td>

              <td :data-label="$t('common.sale_price_before_tax')">
                {{ product.subtotal }}
              </td>

              <td :data-label="$t('common.tax_total')">
                {{ product.tax_total }}
              </td>
              <td :data-label="$t('common.sale_price')">
                {{ product.price_with_tax }}
              </td>

              <td :data-label="$t('common.tax_inc')">
                <input
                  type="checkbox"
                  v-model="product.tax_included"
                  disabled
                />
              </td>
              <td :data-label="$t('common.taxes')">
                <span v-for="tax in product.tax" :key="tax.id">
                  {{ tax.name }}
                </span>
              </td>

              <td :data-label="$t('common.cost')">
                {{ product.last_cost }}
              </td>

              <td :data-label="$t('common.supplier')">
                {{ product.supplier }}
              </td>
              <td :data-label="$t('common.active')">
                <input type="checkbox" v-model="product.active" disabled />
              </td>
              <td :data-label="$t('common.control')">
                <div
                  class="row row-wrap gap-2 justify-content-center control-btns"
                >
                  <img
                    @click="
                      $router.push({
                        name: 'edit_product',
                        params: {
                          product_id: product.id,
                        },
                      })
                    "
                    :src="$get_icon('edit')"
                    class="btn btn-success col"
                    alt=""
                  />
                  <img
                    data-bs-toggle="modal"
                    data-bs-target="#del-modal"
                    :src="$get_icon('delete')"
                    class="btn btn-danger col"
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import search_products from "@/components/settings/products/search_products";
import { close_current_modal } from "@/helpers";
import { mapActions } from "vuex";

export default {
  name: "ProductMain",
  computed: {},
  components: {
    section_route,
    search_products,
  },
  data() {
    return {
      groups: [],
      products: [],

      current_product: {},

      // groups
      group_pk: 0,
      group_path: [],
      current_group: {},
      children: [],

      // pagination
      pagination: {},
      page: 1,
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden"]),
    close_current_modal,
    get_group_products(group_pk) {
      this.$isLoading(true);
      this.current_group = {};
      const path = group_pk
        ? `/inventory/ProductGroups/?group_pk=${group_pk}`
        : `/inventory/ProductGroups/?page=${this.page}`;

      this.$http
        .get(path)
        .then((response) => {
          this.pagination = response.data.pagination;
          const data = response.data.results;

          if (data.clicked_group) {
            // if user clicked on group
            // and there is multiple groups to click
            this.current_group = data.clicked_group;
            this.groups = data.clicked_group.children;
            this.products = []; // to prevent conflict between group products
          } else {
            // main page shows all groups
            this.groups = data.groups;
          }

          if (data.clicked_group && data.clicked_group.path) {
            this.group_path = data.clicked_group.path.sort(
              (a, b) => a.id - b.id
            );
          } else {
            this.group_path = [];
          }

          this.products.push(...data.products);
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
          }
          console.log(`Error while fetching groups ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    delete_product() {
      this.$http
        .delete(`/inventory/product/${this.current_product.id}`)
        .then(() => {
          this.products = this.products.filter(
            (product) => product.id !== this.current_product.id
          );
          this.$store.dispatch("utils/push_alert", {
            message: `Product Deleted Successfully`,
            type: "success",
          });
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: this.current_product.name + " " + e.response.data,
            type: "danger",
          });
          console.log(`Error while Deleting products ! : ${e}`);
        });
      this.close_current_modal();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      // console.log(scrollTop, clientHeight, scrollHeight);

      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("scroll bottom");
        if (this.pagination.next) {
          this.page++;
          this.get_group_products();
        }
      }
    },
  },
  mounted() {
    this.get_group_products();
  },
};
</script>

<style lang="scss" scoped>
.products {
  .header-wrapper {
    min-height: 25%;
    max-height: 25%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: $main-white;
    border-radius: 8px;
    .header {
      padding: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      .buttons {
        text-align: end;

        button {
          padding: 0.8rem 2rem;
          margin: 0 0.5rem;
        }
      }

      .group-path {
        display: flex;

        span {
          padding: 1rem;
          font-size: 1rem;
        }

        .pre {
          color: $fade-color;
        }
      }

      .groups {
        .group {
          .icon {
            max-width: 50px;
            max-height: 54px;
          }
        }
      }
    }
  }

  .middle {
    margin: 0.5rem 0;
    padding: 0.5rem 0.5rem; // todo: sometimes causes scroll problem
    border-radius: 8px;

    min-height: 78%;
    max-height: 78%;
    height: 78%;

    background: $main-white;
    .table-wrapper {
      min-height: 88%;
      max-height: 88%;
      height: 88%;
      overflow: auto;
    }
  }

  .pos-input {
    padding: 1rem;
  }
  //
  .pos-button,
  .pos-delete-btn {
    padding: 0.5rem 1rem;
  }
  //
  a.pos-button {
    text-decoration: none;
    text-align: center;
  }

  .did-floating-input,
  .did-floating-select {
    font-size: 12px;
    display: block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    background: $main-white;
    color: #323840;
    border: 1px solid $secondary-background-color;
    border-radius: 4px;
    box-sizing: border-box;

    outline: none;

    ~ .did-floating-label {
      top: -8px;
      font-size: 13px;
    }
  }

  .form-select {
    width: auto;
  }

  .area {
    position: relative;
    border: 1px solid $secondary-background-color;
    border-radius: 8px;
    padding: 1rem;

    .name {
      position: absolute;
      top: -10px;
      left: 10px;
      background: $pure-white;
      padding: 0 0.5rem;
    }
  }
}

.group {
  transition: all 0.4s ease-in-out;
  user-select: none;
}

.active-group {
  background: $primary-100;
  border-radius: 8px;
  padding: 0.1rem 0.5rem;

  .group-name {
    color: $error-500;
  }
}
.control-btns {
  img {
    max-width: 48px;
    max-height: 48px;
  }
}
</style>
