import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import navbar from "./modules/navbar/navbar.js";
import keyboard from "./modules/keyboard.js";

import sell_screen from "./modules/sell_screen/sell_screen.js";
import sell_screen_invoices from "./modules/sell_screen/sell_screen_invoices.js";
import saved_orders from "./modules/saved_orders.js";

import utils from "./modules/utils.js";

import purchase_crud from "./modules/settings/purchase/purchase_crud.js";
import general_settings from "./modules/settings/general_settings.js";
import sales_history from "./modules/sales_history.js";

import groups from "./modules/settings/products/groups.js";
import temporary from "./modules/temporary/temporary.js";

import axios from "axios";

// making axios global to  use in any components
Vue.prototype.$http = axios;

// setup token for any user request
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Token " + token;
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  getters: {},
  modules: {
    auth,
    navbar,
    keyboard,
    sell_screen,
    sell_screen_invoices,
    saved_orders,
    sales_history,
    utils,
    purchase_crud,
    general_settings,
    groups,
    temporary,
  },
});
