var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section_route',{attrs:{"routes":[
      { name: _vm.$t('sidebar.management'), route: 'management' },
      { name: _vm.$t('reports.reports'), route: 'reports' },
      {
        name: _vm.$t('reports.payment_types_by_customers'),
        route: 'payments_by_customers',
      },
    ]}}),_c('div',{staticClass:"row py-3 p-3 justify-content-end"},[_c('button',{staticClass:"btn btn-warning mx-lg-5 col-sm-3 col-12",attrs:{"disabled":_vm.report.payment_types.length === 0},on:{"click":_vm.get_pdf_file}},[_vm._v(" "+_vm._s(_vm.$t("reports.save_as_pdf"))+" ")]),(_vm.report.payment_types.length > 0)?_c('div',{staticClass:"table-wrapper p-3"},[_c('table',{staticClass:"table table-striped text-center"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("common.name")))]),_vm._l((_vm.report.payment_types),function(payment_name,index){return _c('th',{key:index},[(payment_name === 'total')?_c('span',[_vm._v(_vm._s(_vm.$t("common.total")))]):_c('span',[_vm._v(_vm._s(payment_name))])])})],2)]),_c('tbody',_vm._l((_vm.report.users),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.name))]),_vm._l((record.payments),function(payment,p_index){return _c('td',{key:p_index},[_vm._v(" "+_vm._s(payment)+" ")])}),(record.meta_data)?_vm._l((record.meta_data.row_count),function(col){return _c('td',{key:col},[(col === record.meta_data.row_count)?_c('span',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(record.meta_data.total)+" ")]):_vm._e()])}):_vm._e()],2)}),0)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }