export default [
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/history/HistoryView"),
    meta: {
      title: () => {
        return "OnePos | History";
      },
    },
  },
];
