export default [
  {
    path: "/zatca",
    name: "zatca_main",
    component: () => import("@/views/zatca/ZatcaMain"),
    meta: {
      title: () => {
        return "OnePos | Zatca";
      },
    },
  },
];
