import { fixed_float, sum_array } from "@/helpers";

function get_taxes_included(taxes_array) {
  // todo: check this function usages in project and remove it if it not being used correctly
  /**
   *  @param  : taxes_array = [{'percentage_value': '15'}, ...]  must be an array that contains object
   *  @return : [ 1.15, 2.15, ] array of taxes after calculating the percentage example
   *            tax = {'percentage_value': '15'} will be 1.15
   * */
  return taxes_array.map((tax) => tax.percentage_value / 100 + 1);
}

function get_taxes_included_sum(taxes_array) {
  /**
   *  @param  : taxes_array = [{'percentage_value': '15'}, ...]  must be an array that contains object
   *  @return : 1.35 for [15, 20] taxes
   * */
  let taxes_sum = 0;
  for (let tax of taxes_array) {
    taxes_sum += tax.percentage_value / 100;
  }
  return 1 + taxes_sum;
}

function get_taxes_excluded(taxes_array) {
  /**
   *  @param  : taxes_array = [{'percentage_value': '15'}, ...]  must be an array that contains object
   *  @return : [ 0.15, 0.15, ] array of taxes after calculating the percentage example
   *            tax = {'percentage_value': '15'} will be 0.15
   * */
  return taxes_array.map((tax) => tax.percentage_value / 100);
}

// get product taxes, subtotal # todo: try to deprecate this function
function get_tax_total_and_subtotal(price, product_taxes_array) {
  const taxes = get_taxes_included(product_taxes_array);
  let subtotal = taxes.reduce(
    (i_subtotal, previous_subtotal) => i_subtotal + price / previous_subtotal,
    0
  );

  let tax_total = price - subtotal;

  return {
    tax_total: fixed_float(tax_total),
    subtotal: fixed_float(subtotal),
  };
}

class ProductPrice {
  #converted_taxes;
  #product_price;

  #subtotal;
  #tax_total;
  #price_with_tax;

  constructor(
    taxes_to_be_converted_array = [],
    product_price = 0,
    tax_included = false
  ) {
    this.taxes_to_be_converted_array = taxes_to_be_converted_array;
    this.#product_price = product_price;

    if (this.#product_price <= 0) {
      console.log("wrong value product_price ");
      return;
    }

    if (tax_included) {
      this.#converted_taxes = get_taxes_included(taxes_to_be_converted_array);
      this.product_tax_included();
    } else {
      this.#converted_taxes = get_taxes_excluded(taxes_to_be_converted_array);
      this.product_tax_excluded();
    }
  }

  get subtotal() {
    return fixed_float(this.#subtotal);
  }

  get tax_total() {
    return fixed_float(this.#tax_total);
  }

  get price_with_tax() {
    return fixed_float(this.#price_with_tax);
  }

  product_tax_included() {
    /* if taxes is included that's means the product price contains the taxes we need to get the SUBTOTAL based on that*/

    this.#subtotal =
      this.#product_price /
      get_taxes_included_sum(this.taxes_to_be_converted_array);
    this.#tax_total = this.#product_price - this.#subtotal;
    this.#price_with_tax = this.#subtotal + this.#tax_total;
  }
  product_tax_excluded() {
    /* if taxes is excluded that's means the product price DOESN'T contain the taxes we need to get the PRICE_WITH_TAX based on that*/
    this.#subtotal = this.#product_price;
    this.#tax_total = this.#subtotal * sum_array(this.#converted_taxes);
    this.#price_with_tax = this.#subtotal + this.#tax_total;
  }
}

export { ProductPrice, get_tax_total_and_subtotal };
