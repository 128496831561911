<template>
  <LineChartGenerator
    :data="chart_data"
    :options="chart_options"
  ></LineChartGenerator>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "orders_chart",
  props: ["graph_data"],
  components: {
    LineChartGenerator,
  },
  data() {
    return {
      chart_data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: this.$t("dashboard.sales"),
            data: this.graph_data,
            borderWidth: 2,

            borderColor: "#2554FF",
            backgroundColor: "#2554FF",
            tension: 0.5,
          },
        ],
      },
      chart_options: {
        responsive: true,
        maintainAspectRatio: false, // to take parent div height
      },
    };
  },
};
</script>
