<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.purchase_discount'), route: 'purchase_discount' },
      ]"
    />
    <div v-if="meta_data.meta_data.number_of_orders" class="d-flex ms-3 mt-4">
      <h5>{{ $t("reports.number_of_orders_discounted") }} :</h5>
      <h5 class="ms-2">{{ meta_data.meta_data.number_of_orders }}</h5>
    </div>

    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="purchases.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="purchases.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>{{ $t("common.invoice_no") }}</th>
              <th>{{ $t("common.date") }}</th>
              <th>{{ $t("common.user") }}</th>
              <th>
                {{ $t("reports.total_before_discount") }}
              </th>
              <th>{{ $t("reports.total_after_discount") }}</th>
              <th>{{ $t("reports.discount_granted") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(purchase, index) of purchases" :key="index">
              <td>{{ purchase.id }}</td>
              <td>{{ purchase.date }}</td>
              <td>{{ purchase.user }}</td>
              <td>{{ purchase.total_before_discount }}</td>
              <td>{{ purchase.total_after_discount }}</td>
              <td>{{ purchase.discount_granted }}</td>
            </tr>
            <tr v-if="meta_data.meta_data">
              <td v-for="index in 5" :key="index"></td>
              <td>{{ meta_data.meta_data.total_discount_granted }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PurchaseDiscount",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/purchase_discount/`,
      purchases: [],
      meta_data: {},
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.meta_data = response.data.pop();
          this.purchases = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching purchase_by_product : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "purchase_by_product.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 60rem;
  max-height: 60rem;
  overflow: scroll;
}
</style>
