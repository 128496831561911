export default {
  seller_name: "الاسم",
  seller_trn: "الرقم الضريبي",
  invoice_type: "نوع الفواتير",
  branch_name: "اسم الفرع",
  device_name: "اسم الجهاز",
  district_name: "اسم المنطقة",
  street_name: "اسم الشارع",
  tax_payer_email: "البريد الإلكتروني",
  city_name: "المدينة",
  address: "العنوان الوطني",
  additional_street_address: "عنوان الشارع الإضافي",
  building_number: "رقم المبنى",
  postal_code: "الرمز البريدي",
  business_category: "نوع النشاط",
  identity_number: "رقم الهوية",
  identity_type: "نوع الهوية",
  country_code: "رمز الدولة",
};
