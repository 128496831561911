<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="preview-zone hidden">
            <div class="box box-solid">
              <div class="box-header with-border">
                <div><b>Preview</b></div>
                <div class="d-flex gap-3" dir="ltr">
                  <div class="col-10">{{ image_name }}</div>
                  <button
                    v-show="image"
                    @click="reset_image"
                    type="button"
                    class="btn btn-danger btn-xs remove-preview"
                  >
                    <img :src="$get_icon('delete')" alt="" />
                  </button>
                </div>
              </div>
              <div class="box-body"></div>
            </div>
          </div>

          <div
            @click="$refs.image_input.click()"
            ref="drop_container"
            @dragover.prevent="drag_over"
            @dragleave.prevent="drag_leave"
            @drop.prevent
            @drop="drop"
            class="dropzone-wrapper"
          >
            <div v-if="image" class="img-wrapper">
              <img :src="image" alt="" />
            </div>
            <div v-else class="d-flex flex-wrap">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img
                  :src="require('@/assets/upload.png')"
                  alt=""
                  width="56"
                  height="56"
                />
              </div>
              <div class="dropzone-desc">
                <p>Choose an image file or drag it here.</p>
              </div>
            </div>

            <input
              @change="browse"
              ref="image_input"
              type="file"
              accept="image/png, image/jpeg"
              class="dropzone d-none"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image_picker",
  props: {
    image_prop: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      image: null,
      image_name: "",
    };
  },
  watch: {
    image_prop(newValue) {
      this.image = newValue;
    },
  },
  methods: {
    drag_over() {
      this.$refs.drop_container.style = "background: #ecf0f5;";
    },
    drag_leave() {
      this.$refs.drop_container.style = "background: unset;";
    },
    drop(e) {
      e.preventDefault();
      // if image was dragged
      let files = e.dataTransfer.files;
      this.image_name = files[0].name;
      this.hold_image(files[0]);
    },
    browse(e) {
      // if browse button selected an image
      try {
        let file = e.target.files[0];
        this.image_name = file.name;
        this.hold_image(file);
      } catch {
        // if user opened browser dialog
        // and he didn't select anything !
        // console.log(`error in drop function ${e}`);
        return;
      }
    },
    hold_image(image_file) {
      // alert prompt if dropped file is not an image
      if (!image_file.type.match("image.*")) {
        alert("Please Select an image");
        this.reset_image();
        return;
      }
      // let img = new Image();
      let reader = new FileReader();
      // to be used in reader.onload to access main scope
      let self = this;
      reader.onload = function (e) {
        self.image = e.target.result;
      };
      // set image binary to results
      reader.readAsDataURL(image_file);
    },
    reset_image() {
      this.image = null;
      this.image_name = "";
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0 50px 10%;
}

.box {
  position: relative;
  background: #ffffff;
  width: 100%;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 10px;
}

.img-wrapper {
  max-width: 100%;
  max-height: 100%;
}

.img-wrapper img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.dropzone-wrapper {
  border: 2px dashed #91b0b3;
  color: #92b0b3;
  position: relative;
  height: 150px;
  cursor: pointer;
}

.dropzone-desc {
  /*position: absolute;*/
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  top: 50px;
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #ecf0f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}
</style>
