export default {
  // sidebar
  details: "تفاصيل المنتج",
  price_and_taxes: "السعر والضريبة",
  stock_control: "خصائص المخزون",
  image_and_color: "الصورة واللون",
  item_box: "صندوق العناصر",

  // product details
  add_product_details: "اضافة تفاصيل للمنتج",
  random: "عشوائي",

  // Product Price and Taxes
  add_price_and_taxes: "اضافة السعر والضريبة",
  allow_changing_the_price: "يسمح بتغيير السعر",
  add_tax_to_product: "-- اختر ضريبة للمنتج --",

  // product stock control
  stock_inventory_control: "التحكم في خصائص المنتج في المخزون",
  reorder_point: "نقطة اعادة الطلب",

  // ItemBox
  new_item_box: "اضافة صندوق جديد",
};
