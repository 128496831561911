<template>
  <div :class="get_card_class()">
    <slot name="card-body">
      <div class="row justify-content-between p-0 m-0">
        <div class="col-md-2">
          <img width="64" height="64" :src="$get_icon(icon_name)" alt="" />
        </div>
        <slot name="card-content ">
          <div class="col-md-9">
            <div class="d-flex justify-content-between w-100">
              <h4 class="text-white mb-1 w-75">{{ card_title }}</h4>
              <!-- Date Filter -->
              <date_filter :filter_function="filter_fn" />
              <!-- Date Filter -->
            </div>
            <div class="d-flex flex-wrap mt-3">
              <h5 class="col-md-12">
                {{ statics_naming.total_text }}:
                <span>${{ report_data.total }}</span>
              </h5>
              <p class="col-md-12">
                {{ statics_naming.count_text }}:
                <span>{{ report_data.count }}</span>
              </p>
            </div>
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import date_filter from "@/components/dashboard/date_filter";

export default {
  name: "dashboard_card",
  props: {
    statics_naming: {
      type: Object,
      default: function () {
        return {
          total_text: this.$t("dashboard.total_sales"),
          count_text: this.$t("dashboard.orders_count"),
        };
      },
    },
    card_classes: {
      type: String,
      default: null,
    },
    card_type_class: {
      type: String,
      default: "blue-card",
    },
    icon_name: {
      type: String,
      default: "default-icon-name",
    },
    card_title: {
      type: String,
      default: "Default Card Title",
    },
    report_data: {
      type: Object,
      default: function () {
        return { total: 0, count: 0 };
      },
    },
    filter_fn: {
      type: Function,
      default: function () {},
    },
  },
  components: { date_filter },
  data() {
    return {
      icons: {},
    };
  },
  methods: {
    get_card_class() {
      const default_class = " card ";

      let custom = "";

      if (this.card_classes) {
        custom += ` ${this.card_classes}`;
      }
      if (this.card_type_class) {
        custom += ` ${this.card_type_class}`;
      }

      if (custom.length > 0) {
        custom += default_class;
        return custom;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/*        Card Colors   */
.blue-card {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.green-card {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.yellow-card {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.pink-card {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}
/*        Card Colors   */
</style>
