import SellScreen from "@/views/sell_screen/SellScreen.vue";
import PaymentView from "@/views/sell_screen/PaymentView.vue";
import SavedOrders from "@/views/sell_screen/SavedOrders";

export default [
  {
    path: "/sell_screen/",
    // todo: change to split_payments, save order, sidebar
    name: "sell_screen",
    component: SellScreen,
    meta: {
      title: () => {
        return "OnePos | Sell Screen";
      },
    },
  },
  {
    path: "/sell_screen/invoices",
    name: "invoices",
    component: () => import("@/views/sell_screen/InvoicesView.vue"),
    meta: {
      title: () => {
        return "OnePos | Invoices";
      },
    },
  },
  {
    path: "/sell_screen/saved_orders",
    name: "saved_orders",
    component: SavedOrders,
    meta: {
      title: () => {
        return "OnePos | Saved orders";
      },
    },
  },
  {
    path: "/sell_screen/payment",
    name: "payment",
    component: PaymentView,
    meta: {
      title: () => {
        return "OnePos | Split Payments";
      },
    },
  },
];
