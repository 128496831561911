export default {
  // sidebar
  details: "Details",
  price_and_taxes: "Price and Taxes",
  stock_control: "Stock Control",
  image_and_color: "Image and Color",
  item_box: "ItemBox",

  // product details
  add_product_details: "Add Product Details",
  random: "Random",

  // Product Price and Taxes
  add_price_and_taxes: "Add Price and Taxes",
  allow_changing_the_price: "Allow Changing The Price",
  add_tax_to_product: "-- Add Tax to Product --",

  // product stock control
  stock_inventory_control: "Stock / Inventory Control",
  reorder_point: "Reorder Point",

  // ItemBox
  new_item_box: "New ItemBox",
};
