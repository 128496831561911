import permission_groups from "./permission_groups/main_en";

export default {
  ...permission_groups,
  perms: "Permissions",
  users_and_perms: "Users and Permissions",
  add_new_user: "Add a New User",
  add_new_group: "Add a New Group",
  group: "Group",
  group_perms: "Permission Groups",
  group_name: "Group Name",
  chosen_perms: "Chosen Permissions",
  change_password: "Change Password",
  new_password: "New Password",
  select_perms_group: "--- Select A Permission Group ----",
  group_delete_confirm: "Are You Sure to Delete this Group",

  // permission box title
  customer: "Customer",
  users: "Users",
  groups: "Groups",
  permissions: "Permissions",

  order_settings: "Order Settings",
  order: "Orders",
  addons: "Addons",
  itembox: "ItemBox",
  scale_settings: "Scale Settings",

  inventory_count: "Inventory Count",
  inventory_damage: "Inventory Damage",

  branch: "Branches",
  company: "Company",
  product: "Products",
  refund: "Refunds",
  stock_transfer: "Stock Transfer",
  tax: "Taxes",
  payment_amount: "Payment Amounts",
  payment_type: "Payment Types",
  promo: "Promos",
  purchase: "Purchases",
};
