import { fixed_float } from "@/helpers";
import { OrderItem } from "@/js/sell_screen/order/order_item";

export function convert_itembox(itembox, product) {
  /*
   * Converting itembox to be order_item
   */
  // const itembox_blueprint = {
  //   id: 28,
  //   name: "كرتون جبنه",
  //   count: "20.00",
  //   unit_of_price: "3.00",
  //   barcode: "",
  //   image: null,
  //   timestamp: "2023-09-03T20:43:12.738695Z",
  //   product: 2,
  //   stock: 8,
  // };
  // const order_item_blueprint = {
  //   name: "a5",
  //   tax_total: "1.30",
  //   cost: "8.70",
  //   price: "10.00",
  //   last_cost: "7.57",
  //   subtotal: "8.70",
  //   price_with_tax: "10.00",
  //   ordered_quantity: 1,
  // };

  // looping through product taxes array and get tax percentage_value
  const taxes =
    product.tax.reduce(
      (total, tax) => fixed_float(total) + fixed_float(tax.percentage_value),
      0
    ) / 100;

  const total = fixed_float(itembox.count) * fixed_float(itembox.unit_of_price);
  const subtotal = total / (1 + taxes);
  const tax_total = total - subtotal;

  return new OrderItem({
    ...product,
    id: itembox.id,
    name: itembox.name,
    tax_total: fixed_float(tax_total, 2),
    subtotal: fixed_float(subtotal, 2),
    price_with_tax: fixed_float(total, 2),
    is_item_box: true,
  });
}
