import axios from "axios";
import router from "../../router";
import i18n_lang from "../../i18n";

const state = {
  user_id: localStorage.getItem("user_id") || null,
  username: localStorage.getItem("username") || null,
  token: localStorage.getItem("token") || null,
  role: localStorage.getItem("role") || null,
  lang: localStorage.getItem("lang") || null,
  company_name: localStorage.getItem("company_name") || null,
  company_vat_num: localStorage.getItem("company_vat_num") || "",
  company_id: localStorage.getItem("company_id") || null,
  branch_id: localStorage.getItem("branch_id") || null,
  avatar: localStorage.getItem("avatar") || null,
  email: localStorage.getItem("email") || null,
};

const actions = {
  login({ commit }, user_payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/accounts/login", {
          username: user_payload.username,
          password: user_payload.password,
        })
        .then((response) => {
          // commit a user mutation
          commit("login", response.data);
          resolve(response);
        })
        .catch((err) => {
          console.log(`Error in login promise: ${err}`); // pass err arg for debugging

          // remove user info if user failed to login
          commit("clear_storage");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit("logout");
        // redirect to homepage
        // push to go to homepage url
        // go to redirect to homepage if there is NavigationDuplication Error
        // this method of routing should be produced only in logout
        router.push({ path: "/" });
        router.go("/");
        resolve();
      } catch (err) {
        reject();
      }
    });
  },
  set_language({ commit }, lang) {
    commit("set_language", lang);
  },
};

const mutations = {
  clear_storage: () => {
    localStorage.clear();
  },
  login: (state, payload) => {
    // save user info (username, token, is_logged_in) to state & local storage
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        // i.e state.user_id = payload.user_id;
        state[key] = payload[key];
        // i.e localStorage.setItem("user_id", this.user_id);
        localStorage.setItem(key, payload[key]);
      }
    }

    // setting app language based on user language
    i18n_lang.locale = state.lang;
    // set Authorization header for django
    axios.defaults.headers.common["Authorization"] = "Token " + state.token;
  },
  logout: (state) => {
    state.user_id = null;
    state.username = null;
    state.email = null;
    state.token = null;
    state.lang = null;
    state.role = null;
    state.company_id = null;
    state.branch_id = null;
    state.avatar = null;

    mutations.clear_storage();
    delete axios.defaults.headers.common["Authorization"];
  },
  set_language: (state, lang) => {
    state.lang = lang;
    localStorage.setItem("lang", lang);
  },
};

const getters = {
  is_logged_in: (state) => {
    return state.token && state.token.length > 0;
  },
  user_info: (state) => {
    return state;
  },
  get_page_direction: (state) => {
    switch (state.lang) {
      case "ar":
        return "rtl";
      case "en":
        return "ltr";
      default:
        return "ltr";
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
