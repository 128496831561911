<template>
  <div class="client-supplier pb-5">
    <modal :modal_id="'add-new-modal'" :close_btn_id="'add_close_modal'">
      <template #title-content>
        {{ $t("customers_and_suppliers.add_customer_or_supplier") }}
      </template>
      <template #body>
        <image_picker ref="new_client_photo" />
        <form class="row mt-2" style="gap: 2rem">
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.name") }}</p>
            <div class="col-md-9 g-0">
              <input
                ref="add_new_name"
                v-model="form.name"
                type="text"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.phone") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.phone_number"
                type="text"
                class="pos-input w-100"
                pattern="[0-9]+"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.email") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.email"
                type="email"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.address") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.address"
                type="text"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.vat_reg_num") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.vat_number"
                type="text"
                class="pos-input w-100"
                pattern="^(\d{13})?$"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.supplier") }}</p>
            <div class="col-md-9 g-0">
              <label class="switch">
                <input v-model="form.is_supplier" type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          @click="reset"
          ref="edit-close-modal-btn"
          type="button"
          class="btn btn-secondary px-5"
          data-bs-dismiss="modal"
        >
          {{ $t("common.cancel") }}
        </button>
        <button
          @click="new_user_request"
          type="button"
          class="pos-primary-btn py-2 px-5"
        >
          {{ $t("common.save") }}
        </button>
      </template>
    </modal>
    <modal :modal_id="'edit-modal'" :close_btn_id="'edit_close_modal'">
      <template #title-content>
        {{ $t("common.edit") }} {{ $t("common.user") }}

        {{ current_user.name }}</template
      >
      <template #body>
        <image_picker ref="update_client_photo" :image_prop="form.avatar" />
        <form id="update-form" class="row mt-2" style="gap: 2rem">
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.name") }}</p>
            <div class="col-md-9 g-0">
              <input v-model="form.name" type="text" class="pos-input w-100" />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.phone") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.phone_number"
                type="text"
                class="pos-input w-100"
                pattern="[0-9]+"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.email") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.email"
                type="email"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.address") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.address"
                type="text"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.vat_reg_num") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.vat_number"
                type="text"
                class="pos-input w-100"
                pattern="^(\d{13})?$"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.supplier") }}</p>
            <div class="col-md-9 g-0">
              <label class="switch">
                <input v-model="form.is_supplier" type="checkbox" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary px-5"
          data-bs-dismiss="modal"
        >
          {{ $t("common.cancel") }}
        </button>
        <button
          @click="edit_user"
          type="button"
          class="pos-primary-btn py-2 px-5"
        >
          {{ $t("common.save") }}
        </button>
      </template>
    </modal>
    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span style="font-size: 1.2rem"> {{ current_user.name }} </span> ?
          </div>
          <div class="modal-footer">
            <button @click="delete_user" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!-- End of ConfirmDelete Modal -->
    <div class="d-flex flex-wrap justify-content-between mb-3">
      <section_route
        :routes="[
          { name: $t('sidebar.management'), route: 'management' },
          { name: $t('common.customer_or_supplier'), route: '' },
        ]"
        class="pb-2"
      />

      <button
        @click="reset"
        class="pos-primary-btn col-3 p-1"
        data-bs-toggle="modal"
        data-bs-target="#add-new-modal"
      >
        {{ $t("customers_and_suppliers.add_customer_or_supplier") }}
      </button>
    </div>

    <div class="table-wrapper">
      <table class="primary-table responsive-table">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("common.name") }}</th>
            <th>{{ $t("common.phone") }}</th>
            <th>{{ $t("common.email") }}</th>
            <th>{{ $t("common.address") }}</th>
            <th>{{ $t("common.creation_date") }}</th>
            <th>{{ $t("common.last_modified") }}</th>
            <th>{{ $t("common.vat_reg_num") }}</th>
            <th>{{ $t("common.supplier") }}</th>
            <th>{{ $t("common.control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{
              id,
              avatar,
              name,
              phone_number,
              email,
              address,
              date_created,
              date_updated,
              vat_number,
              is_supplier,
            } in users"
            :key="id"
          >
            <td><img :src="avatar" alt="" width="128" height="128" /></td>
            <td :data-label="$t('common.name')">{{ name }}</td>
            <td :data-label="$t('common.phone')">{{ phone_number }}</td>
            <td :data-label="$t('common.email')">{{ email }}</td>
            <td :data-label="$t('common.address')">{{ address }}</td>
            <td :data-label="$t('common.creation_date')">
              {{ new Date(date_created).toLocaleString() }}
            </td>
            <td :data-label="$t('common.last_modified')">
              {{ new Date(date_updated).toLocaleString() }}
            </td>
            <td :data-label="$t('common.vat_reg_num')">{{ vat_number }}</td>
            <td :data-label="$t('common.supplier')">
              <input type="checkbox" :checked="is_supplier" disabled />
            </td>
            <td :data-label="$t('common.control')">
              <div class="d-flex justify-content-center">
                <button
                  @click="show_update_dialog(id)"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-modal"
                  class="btn btn-success mx-1"
                >
                  <img :src="$get_icon('edit')" alt="" />
                </button>
                <button
                  @click="show_delete_dialog(id)"
                  data-bs-toggle="modal"
                  data-bs-target="#delmodal"
                  class="btn btn-danger mx-1"
                >
                  <img :src="$get_icon('delete')" alt="" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import modal from "@/components/bootstrap_modal";
import { check_url, close_current_modal } from "@/helpers";
import image_picker from "@/components/image_picker";

export default {
  name: "ClientAndSupplier",
  components: {
    section_route,
    modal,
    image_picker,
  },
  data() {
    return {
      current_user: {},
      users: [],
      form: {},
    };
  },
  methods: {
    close_current_modal,
    // set and get user
    get_user_by_id(user_id) {
      return this.users.find((user) => user.id === user_id);
    },
    set_current_user(user_id) {
      this.current_user = this.get_user_by_id(user_id);
    },

    // validate new_user, update_user forms inputs
    validate_inputs() {
      const elements = Array.from(document.querySelectorAll(".pos-input"));
      return elements.every((e) => e.checkValidity());
    },

    // get, update, delete user/s requests
    get_users_request() {
      this.$http.get("/accounts/client_and_suppliers").then((response) => {
        this.users = response.data.results;
      });
    },
    new_user_request() {
      const body_form_data = new FormData();
      const image = this.$refs.new_client_photo.image;
      if (image) {
        this.form.avatar = image;
      }

      for (const [key, value] of Object.entries(this.form)) {
        body_form_data.append(key, `${value}`);
      }

      this.$http({
        method: "POST",
        url: `/accounts/client_and_suppliers`,
        headers: { "Content-Type": "multipart/form-data" },
        data: body_form_data,
      })
        .then((response) => {
          document.getElementById("add_close_modal").click();
          const customer_or_supplier = response.data.is_supplier
            ? "supplier"
            : "customer";

          this.$store.dispatch("utils/push_alert", {
            message: `New ${customer_or_supplier} Created Successfully`,
            type: "success",
          });
          this.users.push(response.data);
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: e.response.data.detail,
            type: "danger",
          });
        });
    },
    edit_user_request() {
      const body_form_data = new FormData();
      const image = this.$refs.update_client_photo.image;

      if (!check_url(image)) {
        body_form_data.append("avatar", image);
      }

      for (const [key, value] of Object.entries(this.form)) {
        if (value !== null && key !== "avatar") {
          body_form_data.append(key, `${value}`);
        }
      }
      this.$http({
        method: "PUT",
        url: `/accounts/client_and_suppliers`,
        data: body_form_data,
      })
        .then((response) => {
          const index = this.users.findIndex(
            (user) => user.id === this.current_user.id
          );
          this.users.splice(index, 1, response.data);
          this.$store.dispatch("utils/push_alert", {
            message: "User Information Updated Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.dispatch("utils/push_alert", {
            message: "Error while Updating User Information  " + error.response,
            type: "danger",
          });
          console.log(" error while Updating client", error.response);
        });
      this.reset();
    },
    delete_user_request() {
      this.$http
        .delete(`/accounts/client_and_suppliers`, {
          data: { pks: [this.current_user.id] },
        })
        .then(() => {
          this.users = this.users.filter(
            (user) => user.id !== this.current_user.id
          );
          this.$store.dispatch("utils/push_alert", {
            message: "User Deleted Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: e.response.data,
            type: "danger",
          });
          this.get_users();
        });
    },

    // update, delete user
    edit_user() {
      if (this.validate_inputs()) {
        this.edit_user_request();
        close_current_modal();
      }
    },
    delete_user() {
      close_current_modal();
      this.delete_user_request();
    },

    // on edit, delete click
    show_update_dialog(user_id) {
      this.set_current_user(user_id);
      this.form = Object.assign({ ...this.current_user });
    },
    show_delete_dialog(user_id) {
      this.set_current_user(user_id);
    },
    reset() {
      const elements = Array.from(document.querySelectorAll(".pos-input"));
      elements.every((e) => {
        e.style.border = "1px solid #c3d4e9";
      });
      this.form = {};
    },
  },
  mounted() {
    this.get_users_request();
  },
};
</script>

<style lang="scss" scoped>
.client-supplier {
  background: $main-white;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
}
</style>
