export default {
  // customer
  add_customer: "Add New Customers",
  change_customer: "Edit Customer Information",
  delete_customer: "Delete Customers",
  view_customer: "View Customers",

  // permission groups
  add_customgroup: "Add Permission Groups",
  change_customgroup: "Edit Permission Groups",
  delete_customgroup: "Delete Permission Groups",
  view_customgroup: "View Permission Groups",

  // permissions
  add_permission: "Add Permissions",
  change_permission: "Edit Permissions ",
  delete_permission: "Remove Permissions ",
  view_permission: "View Permissions",

  // user
  add_user: "Add New Users",
  change_user: "Edit Users Information",
  delete_user: "Delete Users",
  view_user: "View Users",

  // branch
  add_branch: "Add New Branches",
  change_branch: "Edit Branches",
  delete_branch: "Delete Branches",
  view_branch: "View Branches",

  // Company
  change_company: "Edit Company Information",
  view_company: "View Company Information",

  // settings -> Order settings
  change_ordersettings: "Edit Order Settings",
  view_ordersettings: "View Order Settings",

  // settings -> Scale settings
  change_scalesettings: "Edit Scale Settings",
  view_scalesettings: "View Scale Settings",

  // Product
  add_product: "Add New Products",
  change_product: "Edit Products",
  delete_product: "Delete Products",
  view_product: "View Products",

  // Product Groups
  add_productgroups: "Add New Product Groups",
  change_productgroups: "Edit Product Groups",
  delete_productgroups: "Delete Product Groups",
  view_productgroups: "View Product Groups",

  // Product taxes
  add_producttax: "Add New Product Tax",
  change_producttax: "Edit Product Tax",
  delete_producttax: "Delete Product Tax",
  view_producttax: "View Product Tax",

  // order
  add_order: "Create Orders",
  change_order: "Edit Orders",
  delete_order: "Delete Orders",
  view_order: "View Orders",

  // ItemBox
  add_itembox: "Create ItemBox",
  change_itembox: "Edit ItemBoxes",
  delete_itembox: "Delete ItemBoxes",
  view_itembox: "View ItemBoxes",

  // addons
  add_addon: "Add New Addons",
  change_addon: "Edit Addons",
  delete_addon: "Delete Addons",
  view_addon: "View Addons",

  // addons -> addon items
  add_addonitem: "[add_addonitem]",
  change_addonitem: "[change_addonitem]",
  delete_addonitem: "[delete_addonitem]",
  view_addonitem: "[view_addonitem]",

  // addons -> addon types
  add_addontype: "Add New Addon Types",
  change_addontype: "Edit Addon Types",
  delete_addontype: "Delete Addon Types",
  view_addontype: "View Addon Types",

  // inventory count
  add_inventorycount: "Creating Inventory Count",
  change_inventorycount: "Edit Inventory Count",
  delete_inventorycount: "Delete Inventory Count",
  view_inventorycount: "View Inventory Count",

  // inventory Damage
  add_inventorydamage: "Creating Inventory Damage",
  change_inventorydamage: "Edit Inventory Damage",
  delete_inventorydamage: "Delete Inventory Damage",
  view_inventorydamage: "View Inventory Damage",

  // refund
  add_refund: "Add Refunds",
  change_refund: "Edit Refund Invoices",
  delete_refund: "Delete Refunds",
  view_refund: "View Refunds",

  // stock Transfer
  add_stocktransfer: "Add New Stock Transfer",
  change_stocktransfer: "Edit Stock Transfer",
  delete_stocktransfer: "Delete Transfer",
  view_stocktransfer: "View Stock Transfers",

  // Taxes
  add_tax: "Add New Taxes",
  change_tax: "Edit Taxes",
  delete_tax: "Delete Taxes",
  view_tax: "View Taxes",

  // payment amount
  add_paymentamount: "Add multiple Payments when selling ",
  change_paymentamount: "Edit split payment ",
  delete_paymentamount: "Delete split payment",
  view_paymentamount: "View Split payment",

  // payment types
  add_paymenttype: "Adding New Payment Types",
  change_paymenttype: "Edit Payment Types",
  delete_paymenttype: "Delete Payment Types",
  view_paymenttype: "View Payment Types",

  // promo
  add_promo: "Add New Promo",
  change_promo: "Edit Promo",
  delete_promo: "Delete Promo",
  view_promo: "View Promo",

  // purchase
  add_purchasae: "Add New Purchase",
  change_purchasae: "Edit Purchase",
  delete_purchasae: "Delete Purchase",
  view_purchasae: "View Purchase",

  // purchase item
  add_purchaseitem: "Add New Purchase Items",
  change_purchaseitem: "Edit Purchase Items",
  delete_purchaseitem: "Delete Purchase Items",
  view_purchaseitem: "View Purchase Items",

  // user work shifts
  add_userworkshift: "Add Work Shifts",
  change_userworkshift: "Edit Work Shifts",
  delete_userworkshift: "Delete Work Shifts",
  view_userworkshift: "View Work Shifts",
};
