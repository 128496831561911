<template>
  <div class="count-display">
    <!-- Edit  Modal -->
    <div
      class="modal fade"
      id="editmodal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <span v-if="selected_item.product">
              {{ $t("common.edit") }}
              <span class="text-success">{{ selected_item.product }}</span>
              {{ $t("common.quantity") }}
            </span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <input
              ref="qty"
              type="text"
              class="form-control"
              style="padding: 0.6rem"
            />
          </div>
          <div class="modal-footer">
            <button @click="edit_quantity" class="btn btn-outline-warning px-5">
              {{ $t("common.edit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="focus_qty"
      id="edit-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#editmodal"
    ></button>
    <!-- End of Edit Modal -->

    <section_route
      :routes="[
        { name: $t('inventory.inventory_count'), route: 'inventory_count' },
        {
          name: `${$t('inventory.inventory_count_number')}  ${
            this.$route.params.report_id
          }`,
          route: '',
        },
      ]"
      class="pb-2"
    />

    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("inventory.counted_qty") }}</th>
            <th>{{ $t("inventory.expected_qty") }}</th>
            <th>{{ $t("inventory.increase_qty") }}</th>
            <th>{{ $t("inventory.shortage_qty") }}</th>
            <th>{{ $t("common.unit_price") }}</th>
            <th>{{ $t("inventory.shortage_price") }}</th>
            <th v-if="is_pending">{{ $t("common.control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{
              id,
              product,
              counted,
              quantity,
              increase_quantity,
              loss_quantity,
              product_unit_price,
              loss_price,
            } in inventory_count_items"
            :key="id"
          >
            <td>{{ product }}</td>
            <td>{{ counted }}</td>
            <td>{{ quantity }}</td>
            <td>{{ increase_quantity }}</td>
            <td>{{ loss_quantity }}</td>
            <td>{{ product_unit_price }}</td>
            <td>{{ loss_price }}</td>
            <td v-if="is_pending">
              <button @click="edit_dialog(id)" class="btn btn-success mx-1">
                <img
                  :src="$get_icon('edit')"
                  alt="edit icon"
                  :title="$t('common.edit')"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="mt-3">{{ $t("common.total") }} :</h3>

    <div class="info">
      <div class="info-text">
        <span class="desc">{{ $t("inventory.expected_qty") }} : </span>
        <span class="num">{{ total_qty }}</span>
        <!-- /.desc -->
      </div>
      <!-- /.info-text -->
      <div class="info-text">
        <span class="desc">{{ $t("inventory.counted_qty") }} : </span>
        <span class="num">{{ total_counted }}</span>
        <!-- /.desc -->
      </div>
      <!-- /.info-text -->
      <div class="info-text">
        <span class="desc">{{ $t("inventory.shortage_qty") }} : </span>
        <span class="num">{{ total_shortage_qty }}</span>
        <!-- /.desc -->
      </div>
      <!-- /.info-text -->
      <div class="info-text">
        <span class="desc">{{ $t("inventory.shortage_price") }} : </span>
        <span class="num">{{ shortage_price }}</span>
        <!-- /.desc -->
      </div>
      <!-- /.info-text -->
    </div>

    <div v-if="is_pending" class="buttons justify-content-end gap-3">
      <button @click="update_status('disapprove')" class="btn btn-danger">
        {{ $t("inventory.decline") }}
      </button>
      <button @click="update_status('approve')" class="pos-primary-btn">
        {{ $t("inventory.approve") }}
      </button>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { close_current_modal } from "@/helpers";

export default {
  name: "DisplayInventoryCount",
  components: {
    section_route,
  },
  data() {
    return {
      selected_item: {},
      inventory_count_items: [],
      total_qty: 0,
      total_counted: 0,
      total_shortage_qty: 0,
      shortage_price: 0,
      status: "",
    };
  },
  computed: {
    is_pending: {
      get() {
        if (this.status) {
          const text = `inventory.${this.status}`;
          return this.$t(text) === this.$t("inventory.pending");
        }
        return false;
      },
    },
  },
  methods: {
    get_details() {
      this.$http
        .get(
          `/inventory/get_inventory_count_details/${this.$route.params.report_id}`
        )
        .then((response) => {
          this.inventory_count_items = response.data.inventory_count_items;

          this.status = response.data.status;
          this.total_qty = response.data.quantity;
          this.total_counted = response.data.counted;
          this.total_shortage_qty = response.data.loss_quantity;
          this.shortage_price = response.data.loss_price;
        })
        .catch((e) => {
          console.log(`Error while fetching inventory count details ! : ${e}`);
        });
    },
    focus_qty() {
      setTimeout(function () {
        document.querySelectorAll(".show .modal-body input")[0].focus();
      }, 480);
    },
    update_status(status) {
      this.$http
        .post("/inventory/update_inventory_count_status", {
          pk: this.$route.params.report_id,
          status: status,
        })
        .then(() => {
          this.$router.push({ name: "inventory_count" });
        })
        .catch((e) => {
          console.log("Error while trying update ", e);
        });
    },
    edit_quantity() {
      // note changing value in original counts array depends
      // on this object reactivity
      if (this.$refs.qty.validity.valid) {
        this.selected_item.quantity = this.$refs.qty.value;
        close_current_modal();
      }
    },
    edit_dialog(selected_product_id) {
      this.selected_item = this.counts.find(
        (p) => p.id === selected_product_id
      );
      // insert the selected product qty inside the popup edit dialog
      this.$refs.qty.value = this.selected_item.quantity;
      document.getElementById("edit-modal-btn").click();
    },
  },
  mounted() {
    this.get_details();
  },
};
</script>

<style lang="scss" scoped>
.count-display {
  width: 100%;
  height: 100%;

  .table-wrapper {
    min-height: 75%;
    max-height: 75%;
    overflow: auto;
  }

  .info {
    margin-top: 1.2rem;
    display: flex;
    gap: 1rem;

    .desc {
      color: $secondary-400;
    }

    & > * {
      flex: 1;
    }
  }

  input[type="checkbox"] {
    transform: scale(1.6);
  }

  .buttons {
    margin-top: 2rem;
    display: flex;

    button {
      border-radius: 5px;
      padding: 0.5rem 1.5rem;
    }
  }
}
</style>
