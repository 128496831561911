const state = {
  invoices: [],
  pagination: {
    count: null,
    next: null,
    previous: null,
  },
  selected_invoice: {},
};

const actions = {
  set_invoices({ commit }, invoices) {
    commit("set_invoices", invoices);
  },
  set_pagination({ commit }, pagination_object) {
    commit("set_pagination", pagination_object);
  },
  set_selected_invoice({ commit }, invoice) {
    commit("set_selected_invoice", invoice);
  },
};
const mutations = {
  set_invoices: (state, invoices) => {
    state.invoices = invoices;
  },
  set_pagination: (state, pagination_object) => {
    state.pagination = pagination_object;
  },
  set_selected_invoice: (state, invoice) => {
    state.selected_invoice = invoice;
  },
};
const getters = {
  get_invoices: (state) => {
    return state.invoices;
  },
  get_pagination: (state) => {
    return state.pagination;
  },
  get_selected_invoice: (state) => {
    return state.selected_invoice;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
