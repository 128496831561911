<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.invoice_list'), route: 'invoice_report' },
      ]"
    />

    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="invoices.length === 0"
        class="btn btn-warning col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="invoices.length > 0" class="table-wrapper p-3">
        <table class="table table-striped responsive-table text-center">
          <thead class="sticky-table-header">
            <tr>
              <th>No</th>
              <th>Order ID</th>
              <th>{{ $t("common.customer") }}</th>
              <th>{{ $t("common.date") }}</th>
              <th>{{ $t("common.items_count") }}</th>
              <th>{{ $t("common.quantity") }}</th>
              <th>{{ $t("common.total_before_tax") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) of invoices" :key="index">
              <td data-label="NO">{{ index + 1 }}</td>
              <td data-label="ID">{{ invoice.id }}</td>
              <td :data-label="$t('common.customer')">
                {{ invoice.customer }}
              </td>
              <td :data-label="$t('common.date')">{{ invoice.date }}</td>
              <td :data-label="$t('common.items_count')">
                {{ invoice.items_count }}
              </td>
              <td :data-label="$t('common.quantity')">
                {{ invoice.quantity }}
              </td>
              <td :data-label="$t('common.total_before_tax')">
                {{ invoice.total_after_tax }}
              </td>
              <td :data-label="$t('common.total')">
                {{ invoice.total_before_tax }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "InvoiceList",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/invoices/`,
      invoices: [],
    };
  },
  methods: {
    get_invoices() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.invoices = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "product_groups.pdf");
    },
  },
  mounted() {
    this.get_invoices();
  },
};
</script>

<style scoped></style>
