import VueI18n from "vue-i18n";

import Vue from "vue";
Vue.use(VueI18n);

const messages = {
  ar: require("@/assets/i18n/ar").default,
  en: require("@/assets/i18n/en").default,
};

export default new VueI18n({
  locale: "en", // default locale
  messages,
});
