const state = {
  array: [],
  object: {},
};

const actions = {
  set_temp_array({ commit }, array) {
    commit("set_temp_array", array);
  },

  set_temp_object({ commit }, object) {
    commit("set_temp_object", object);
  },
};

const mutations = {
  set_temp_array: (state, array) => {
    state.array = array;
  },
  set_temp_object: (state, object) => {
    state.object = object;
  },
};
const getters = {
  get_temp_array: (state) => {
    return state.array;
  },
  get_temp_object: (state) => {
    return state.object;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
