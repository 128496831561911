<template>
  <div class="sec-name">
    <img class="arrow-icon" :src="$get_icon('ArrowRightIcon')" alt="" />
    <span v-for="(route, index) in routes" :key="index" class="route">
      <span
        class="route-name"
        :class="{ 'fade-route': has_next(index) }"
        @click="$router.push({ name: route.route })"
      >
        {{ route.name }}
      </span>
      <img
        v-if="has_next(index)"
        class="arrow-icon"
        :src="$get_icon('ArrowRightIcon')"
        alt=""
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "section_route",
  props: {
    routes: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    has_next(index) {
      // Check if there is a next item in the array
      return index < this.routes.length - 1;
    },
  },
};
</script>

<style scoped></style>
