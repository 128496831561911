export default {
  void_order: "Void Order",
  invoices: "Invoices",
  addons: "Addons",
  save_sale: "Save Sale",
  change_price: "Change Price",
  payments: "Payments",
  group: "Group",

  // clients
  add_new_client: "Add New Client",
  clients_search_desc: "Search by client name or phone number",

  // addons
  addon_details: "Addon Details",
  addon_type: "Addon Type",
  additional_price: "Additional price",

  // discount modal
  cart_discount: "Cart Discount",
  item_discount: "Item Discount",
  apply_discount_to: "Apply discount to",
  no_item_selected_msg: "Please select an item to add discount !",
  enter_discount_placeholder: "Enter Discount Value",

  // split payments
  payment_require_customer_error:
    "This Payment Type Require a client to be selected first",

  // sell screen invoices -> invoices filter
  invoice_type: "Invoice Type",
  all: "All",
  paid: "Paid",
  refund: "Refund",

  order_by: "Order By",
  last: "Last",
  first: "First",
};
