<template>
  <div class="users_security full-height">
    <div class="d-flex flex-column flex-md-row gap-3 full-height">
      <div class="layout-sidebar col-12 col-md-3">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            {
              name: $t('sidebar.users_and_security'),
              route: 'users_crud',
            },
          ]"
          class="pb-2"
        />
        <div class="layout-menu-buttons d-flex flex-wrap gap-3">
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'users_crud' }"
            class="products"
          >
            <button
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              {{ $t("common.users") }}
            </button>
          </router-link>

          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'users_permissions' }"
            class="col col-md-12"
          >
            <button
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
              class="col col-md-12"
            >
              {{ $t("users_and_security.perms") }}
            </button>
          </router-link>
        </div>
      </div>
      <div class="content-wrapper col">
        <div class="router-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "UsersCrud",
  components: { section_route },
};
</script>

<style lang="scss" scoped>
.router-wrapper {
  margin-top: 1rem;
  background: $main-white;
  padding: 1rem;
  border-radius: 5px;
}
</style>
