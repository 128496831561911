import UsersPermissions from "@/views/management/users_and_secuirty/UsersPermissions";
import Groups_routes from "@/router/routes/management/users_and_security/permissions/groups/groups_routes";

export default [
  {
    path: "permissions/",
    component: UsersPermissions,
    name: "users_permissions",
    meta: {
      title: () => {
        return "OnePos | Permissions";
      },
    },
  },
  ...Groups_routes,
];
