export default {
  seller_name: "Name",
  seller_trn: "Vat Number",
  invoice_type: "Invoice Type",
  branch_name: "Branch Name",
  device_name: "Device Name",
  district_name: "District Name",
  street_name: "Street Name",
  tax_payer_email: "Email",
  city_name: "City",
  address: "National address",
  additional_street_address: "Additional street Address",
  building_number: "Building Number",
  postal_code: "Postal Code",
  business_category: "Business Category",
  identity_number: "Identity Number",
  identity_type: "Identity Type",
  country_code: "Country Code",
};
