import { PromoItem } from "@/js/sell_screen/promo/promo";
import store from "@/store";

function conditional_apply_to_all(promo_item) {
  // check if promoitem has exceeded apply_to_all
  const invoice_order_items =
    store.getters["sell_screen/get_current_order"].items;

  const repeated_order_item = invoice_order_items.filter(
    (p_item) => p_item.id === promo_item.id
  ).length;

  if (repeated_order_item < promo_item.apply_to_all) {
    store.dispatch("sell_screen/add_order_item", promo_item);
  } else {
    const update_promo_item = new PromoItem(promo_item);
    update_promo_item.apply_promo_on_price();

    const payload = {
      order_item_index: invoice_order_items.length + 1,
      order_item: update_promo_item,
    };
    store.dispatch("sell_screen/update_order_item", payload);
  }
}

function conditional_apply_to_next(promo_item) {
  /*
   * i.e
   * apply_to_all = 3
   * apply_to_next = 2
   * every apply_to_all add apply_to_next promo_item with apply_promo_on_price
   * i.e every 3 items should add 2 items with promo one at the time " not 2 items at once "
   * */

  const invoice_order_items =
    store.getters["sell_screen/get_current_order"].items;

  // get the PromoItems in the invoice of the current promo_item
  const promo_items = invoice_order_items.filter(
    (p_item) => p_item.is_promo && p_item.id === promo_item.id
  );

  const not_promo_items = invoice_order_items.filter(
    (p_item) => !p_item.is_promo && p_item.id === promo_item.id
  );

  // if this item not added before in the invoice_list
  if (not_promo_items.length <= 0) {
    store.dispatch("sell_screen/add_order_item", promo_item);
    return;
  }

  /*
    * check if items with no promo of the same product has no remaining of the promo apply_to_all condition
    * and the number of how many times that item in the invoice list with no promo / promo_condition_apply_to_all * promo_condition_apply_to_next
      this will return and show us how many items should be added with promo
    */
  if (
    not_promo_items.length % promo_item.apply_to_all === 0 &&
    Math.floor(not_promo_items.length / promo_item.apply_to_all) *
      promo_item.apply_to_next >
      promo_items.length
  ) {
    promo_item.apply_promo_on_price();
    store.dispatch("sell_screen/add_order_item", promo_item);
  } else {
    store.dispatch("sell_screen/add_order_item", promo_item);
  }
}

function add_promo_item(order_item) {
  const promo_item = new PromoItem(order_item);
  if (promo_item.is_conditional) {
    if (promo_item.is_all) {
      conditional_apply_to_all(promo_item);
    } else {
      conditional_apply_to_next(promo_item);
    }
  } else {
    promo_item.not_conditional();
    store.dispatch("sell_screen/add_order_item", promo_item);
  }
}
export { add_promo_item };
