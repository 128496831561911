<template>
  <div class="modal fade" :id="modal_id" tabindex="-2" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header d-flex">
          <slot name="header">
            <h5 class="modal-title text-center col text-danger">
              <slot name="title-content"></slot>
            </h5>

            <div class="col-md-3 text-end"></div>

            <button
              type="button"
              class="d-none btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :id="close_btn_id"
            ></button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"> </slot>
        </div>
        <div class="modal-footer d-flex">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal_id", "close_btn_id"],
  name: "bootstrap_modal",
};
</script>

<style scoped></style>
