<template>
  <div class="promos">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        {
          name: $t('sidebar.promos'),
          route: '',
        },
      ]"
      class="pb-2"
    />

    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span style="font-size: 1.2rem">
              {{ current_promo.name }} {{ $t("common.promo") }} ?</span
            >
          </div>
          <div class="modal-footer">
            <button @click="delete_promo" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <button
      id="del-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#delmodal"
    ></button>
    <!-- End of ConfirmDelete Modal -->

    <div class="table-wrapper">
      <table class="primary-table" v-if="promos.length > 0">
        <thead>
          <tr>
            <th>{{ $t("common.name") }}</th>
            <th>{{ $t("common.active") }}</th>
            <th>{{ $t("promo.days_of_week") }}</th>
            <th>{{ $t("promo.start_date") }}</th>
            <th>{{ $t("promo.end_date") }}</th>
            <th style="width: 1rem">{{ $t("common.control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="promo in promos" :key="promo.id">
            <td>{{ promo.name }}</td>
            <td>
              <input type="checkbox" :checked="promo.is_active" disabled />
            </td>
            <td>{{ get_day_name(promo.days_of_week) }}</td>

            <td>{{ promo.start_date | localize_date }}</td>
            <td>{{ promo.end_date | localize_date }}</td>

            <td class="d-flex">
              <a
                :href="$get_route_url('view_promo', { id: promo.id })"
                class="btn btn-primary mx-1"
              >
                <img :src="$get_icon('view')" alt="" />
              </a>

              <a
                :href="$get_route_url('update_promo', { id: promo.id })"
                class="btn btn-success mx-1"
              >
                <img :src="$get_icon('edit')" alt="" />
              </a>
              <button
                @click="show_delete_dialog(promo)"
                class="btn btn-danger mx-1"
              >
                <img :src="$get_icon('delete')" alt="" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex flex-column justify-content-center mt-3 pb-3">
      <div class="d-flex justify-content-end">
        <button
          @click="$router.push({ name: 'new_promo' })"
          class="col-3 btn btn-outline-primary"
        >
          {{ $t("promo.new_promo") }}
        </button>
      </div>
    </div>
  </div>
  <!-- /.promo-settings -->
</template>

<script>
import section_route from "@/components/section_route";
import { close_current_modal } from "@/helpers";

export default {
  name: "PromosMain",
  components: { section_route },
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  | ${datetime.toLocaleTimeString()}
      `;
    },
  },
  data() {
    return {
      promos: [],
      // current triggered promo name to edit | delete
      current_promo: {},
    };
  },
  methods: {
    close_current_modal,

    get_promos() {
      this.$http.get("/promo/promo").then((response) => {
        this.promos = response.data;
      });
    },
    show_delete_dialog(promo) {
      this.current_promo = promo;
      document.getElementById("del-modal-btn").click();
    },
    delete_promo() {
      this.$http
        .delete(`/promo/promo`, {
          data: { promo_id: this.current_promo.id },
        })
        .then(() => {
          this.promos = this.promos.filter(
            (promo) => promo.id !== this.current_promo.id
          );
        })
        .catch((e) => {
          this.get_promos();
          this.$store.dispatch("utils/push_alert", {
            message: `${e.response.data}`,
            type: "danger",
          });
          console.log(`Error while deleting a promo ! : ${e}`);
        });
      this.close_current_modal();
    },
    get_day_name(day_numbers) {
      const day_name_map = {
        0: this.$t("common.day_sat"),
        1: this.$t("common.day_sun"),
        2: this.$t("common.day_mon"),
        3: this.$t("common.day_tue"),
        4: this.$t("common.day_wed"),
        5: this.$t("common.day_thu"),
        6: this.$t("common.day_fri"),
      };
      const all_days = "0123456";

      if (day_numbers === all_days) {
        return "EveryDay";
      }

      return day_numbers
        .split("")
        .map((num) => day_name_map[num])
        .join(", ");
    },
  },
  mounted() {
    this.get_promos();
  },
};
</script>

<style lang="scss" scoped>
.promos {
  background: $main-white;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
}
</style>
