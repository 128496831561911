<template>
  <div class="row row-wrap align-items-baseline gap-3">
    <slot v-if="load_clients" name="customers_and_suppliers">
      <div class="col-12 col-sm">
        <div class="row gap-2">
          <p class="col-12 col-sm">{{ $t("common.customer_or_supplier") }}</p>
          <search_client_or_supplier
            ref="client_search_component"
            :parent_component="this"
            :search_css_class="`p-1`"
          />
        </div>

        <ul v-if="clients_dropdown_toggle" class="card">
          <li
            v-for="client in searched_clients"
            :key="client.id"
            @click="select_client(client)"
            class="row justify-content-between"
          >
            <img
              :src="client.avatar"
              alt=""
              width="32"
              height="32"
              class="col-md-1"
            />
            <p class="col-md-8" href="#">{{ client.name }}</p>
          </li>
        </ul>
      </div>
    </slot>
    <slot v-if="load_users" name="user">
      <div class="col-12 col-sm">
        <div class="row gap-2">
          <p class="col-1">{{ $t("common.user") }}</p>
          <input
            ref="user_input"
            class="pos-input px-2 py-2"
            list="users"
            :placeholder="$t('common.search_by_user')"
          />
        </div>
        <datalist id="users">
          <option
            v-for="{ pk, username } in users"
            :key="pk"
            :value="username"
          ></option>
        </datalist>
      </div>
    </slot>
    <!-- DATE FILTERS -->
    <slot name="date_filter">
      <div class="col-12 col-md-5 row row-wrap gap-3">
        <div class="row gap-2">
          <p class="col-1">{{ $t("common.from") }}</p>
          <input
            ref="from_date"
            @input="date_filters"
            type="date"
            class="pos-input px-4 col"
          />
        </div>

        <div class="row gap-2">
          <p class="col-1">{{ $t("common.to") }}</p>
          <input
            ref="to_date"
            type="date"
            class="pos-input px-4 col"
            @input="date_filters"
          />
        </div>
      </div>
    </slot>
    <!-- DATE FILTERS -->

    <!-- PRODUCTS -->
    <slot name="product">
      <div class="col-sm-12">
        <search_products
          ref="search_component"
          :parent_component="this"
          :search_css_class="'col-md-12 py-2 form-control'"
          :input_type="'search'"
          :allow_icon="null"
        />
        <ul v-if="products_dropdown_toggle" class="card">
          <li
            v-for="product in searched_products"
            :key="product.id"
            @click="select_product(product)"
            class="row justify-content-between"
          >
            <img
              :src="product.image"
              alt=""
              width="32"
              height="32"
              class="col-md-3"
            />
            <p class="col-md-8" href="#">{{ product.name }}</p>
          </li>
        </ul>
      </div>
    </slot>
    <!-- PRODUCTS -->
  </div>
</template>

<script>
import search_products from "@/components/sell_screen/search_products";
import search_client_or_supplier from "@/components/search/search_client_or_supplier";

export default {
  name: "report_filters",
  props: {
    load_users: {
      type: Boolean,
      default: true,
    },
    load_clients: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    search_products,
    search_client_or_supplier,
  },
  data() {
    return {
      products: [],
      searched_products: [],
      cached_products: [],
      current_product_obj: {},

      users: [],

      // client or suppliers search component
      searched_clients: [],
      current_client_obj: {},

      // date filters
      from_date: "",
      to_date: "",

      clients_dropdown_toggle: true,
      products_dropdown_toggle: true,
    };
  },
  methods: {
    set_dropdown() {
      let self = this;
      this.$refs.search_component.$refs.search_input.addEventListener(
        "focus",
        function () {
          self.clients_dropdown_toggle = true;
        }
      );
      this.$refs.search_component.$refs.search_input.addEventListener(
        "focus",
        function () {
          self.products_dropdown_toggle = true;
        }
      );

      this.$refs.client_search_component.$refs.search_input.addEventListener(
        "blur",
        function () {
          // so user can select the dropdown item increase delay
          // if modal not showing or set dropdown_toggle based
          // if there is showing modal
          setTimeout(() => {
            self.clients_dropdown_toggle = false;
          }, 200);
        }
      );

      this.$refs.search_component.$refs.search_input.addEventListener(
        "blur",
        function () {
          setTimeout(() => {
            self.products_dropdown_toggle = false;
          }, 200);
        }
      );
    },
    select_client(client) {
      this.current_client_obj = client;
      this.$refs.client_search_component.$refs.search_input.value =
        this.current_client_obj.name;
      this.clients_dropdown_toggle = false;
    },
    select_product(product) {
      this.current_product_obj = product;
      this.$refs.search_component.$refs.search_input.value =
        this.current_product_obj.name;
      this.products_dropdown_toggle = false;
    },
    date_filters() {
      this.from_date = this.$refs.from_date.value
        ? new Date(this.$refs.from_date.value).toISOString()
        : "";

      this.to_date = this.$refs.to_date.value
        ? new Date(this.$refs.to_date.value).toISOString()
        : "";
    },
    get_users() {
      this.$http
        .get(`/accounts/get_branch_users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_filters() {
      return {
        product_name: this.current_product_obj.name,
        user: this.$refs.user_input.value,
        client: this.current_client_obj.name,
        from_date: this.from_date,
        to_date: this.to_date,
      };
    },
  },

  mounted() {
    this.set_dropdown();
    if (this.load_users) {
      this.get_users();
    }
  },
};
</script>

<style lang="scss" scoped>
.search-bar-li {
  list-style: none;
}

ul.card {
  // Todo: make this card overflows and show above the table
  position: absolute;
  border: none;

  width: 95%;
  margin: 1% 2.5%;
  box-shadow: -1px 8px 45px 0px rgba(0, 0, 0, 0.4);

  li {
    padding: 1rem;

    &:hover {
      background: #4285f4;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}
</style>
