<template>
  <Doughnut :options="chartOptions" :data="chartData" />
</template>
<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
export default {
  name: "top_payment_methods_doughnut",
  props: ["payment_data"],
  components: {
    Doughnut,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 1,

            borderColor: [
              "rgba(129, 77, 147, 1)",
              "rgba(47, 138, 176, 1)",
              "rgba(228, 135, 57, 1)",
              "rgba(96, 181, 141, 1)",
              "rgba(195, 108, 63, 1)",
            ],

            backgroundColor: [
              "rgba(129, 77, 147, 0.2)",
              "rgba(47, 138, 176, 0.2)",
              "rgba(228, 135, 57, 0.2)",
              "rgba(96, 181, 141, 0.2)",
              "rgba(195, 108, 63, 0.2)",
            ],

            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    load_payments_chart() {
      if (this.payment_data.length > 0) {
        for (let i = 0; i < this.payment_data.length; i++) {
          this.chartData.labels.push(this.payment_data[i].payment_name);
          this.chartData.datasets[0].data.push(this.payment_data[i].count);
        }
      } else {
        this.chartData.datasets[0].data.push(1);
      }
    },
  },
  created() {
    this.load_payments_chart();
  },
};
</script>
