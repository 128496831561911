import notifications from "@/assets/i18n/notifications/main_en";
import common from "@/assets/i18n/common/common_en";
import auth from "@/assets/i18n/auth/main_en";
import sidebar from "@/assets/i18n/sidebar/sidebar_en";
import profile from "@/assets/i18n/profile/profile_en";
import sell_screen from "@/assets/i18n/sell_screen/main_en";
import history from "@/assets/i18n/history/history_en";
import dashboard from "@/assets/i18n/dashboard/dashboard_en";
import reports from "@/assets/i18n/reports/main_en";
import products from "@/assets/i18n/products/main_en";
import inventory from "@/assets/i18n/inventory/main_en";
import customers_and_suppliers from "@/assets/i18n/customers_and_suppliers/main_en";
import users_and_security from "@/assets/i18n/users_and_security/main_en";
import purchase from "@/assets/i18n/purchase/main_en";
import promo from "@/assets/i18n/promo/main_en";
import company from "@/assets/i18n/company/main_en";
import branches from "@/assets/i18n/branches/main_en";
import zatca from "@/assets/i18n/zatca/main_en";
import settings from "@/assets/i18n/settings/main_en";

export default {
  notifications,
  common,
  auth,
  sidebar,
  profile,
  sell_screen,
  history,
  dashboard,
  reports,
  products,
  inventory,
  customers_and_suppliers,
  users_and_security,
  purchase,
  promo,
  company,
  branches,
  zatca,
  settings,
};
