import notifications from "@/assets/i18n/notifications/main_ar";
import common from "@/assets/i18n/common/common_ar";
import auth from "@/assets/i18n/auth/main_ar";
import sidebar from "@/assets/i18n/sidebar/sidebar_ar";
import profile from "@/assets/i18n/profile/profile_ar";
import sell_screen from "@/assets/i18n/sell_screen/main_ar";
import history from "@/assets/i18n/history/history_ar";
import dashboard from "@/assets/i18n/dashboard/dashboard_ar";
import reports from "@/assets/i18n/reports/main_ar";
import products from "@/assets/i18n/products/main_ar";
import inventory from "@/assets/i18n/inventory/main_ar";
import customers_and_suppliers from "@/assets/i18n/customers_and_suppliers/main_ar";
import users_and_security from "@/assets/i18n/users_and_security/main_ar";
import purchase from "@/assets/i18n/purchase/main_ar";
import promo from "@/assets/i18n/promo/main_ar";
import company from "@/assets/i18n/company/main_ar";
import branches from "@/assets/i18n/branches/main_ar";
import zatca from "@/assets/i18n/zatca/main_ar";
import settings from "@/assets/i18n/settings/main_ar";

export default {
  notifications,
  common,
  auth,
  sidebar,
  profile,
  sell_screen,
  history,
  dashboard,
  reports,
  products,
  inventory,
  customers_and_suppliers,
  users_and_security,
  purchase,
  promo,
  company,
  branches,
  zatca,
  settings,
};
