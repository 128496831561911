import DashboardView from "@/views/management/dashboard/DashboardView";

export default [
  {
    path: "/management/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: {
      title: () => {
        return "OnePos | Dashboard";
      },
    },
  },
];
