<template>
  <div class="permissions">
    <p class="sec-name">{{ $t("users_and_security.perms") }}</p>
    <div class="groups mt-3">
      <div class="row">
        <div
          v-for="g in groups"
          :key="g.id"
          class="group col-md-2 text-center"
          @click="redirect_to_group(g.id)"
        >
          <div class="icon">
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43.8724 9.31258C44.1067 9.70479 43.6848 10.1486 43.2428 10.033C43.1534 10.0096 43.064 9.98782 42.9746 9.9675C41.9171 9.6525 40.7471 9.495 39.5546 9.495H31.8169C31.6596 9.495 31.5116 9.42106 31.4171 9.29537L28.6421 5.6025C28.5829 5.51852 28.5225 5.43562 28.4611 5.35357C28.2035 5.00973 28.437 4.5 28.8665 4.5H35.3696C38.9809 4.5 42.1498 6.42841 43.8724 9.31258Z"
                fill="#6690FF"
              />
              <path
                d="M45.315 14.715C44.3475 14.0175 43.245 13.5 42.0525 13.2075C41.2425 12.9825 40.41 12.87 39.555 12.87H31.185C29.88 12.87 29.79 12.7575 29.0925 11.835L25.9425 7.65C24.48 5.6925 23.3325 4.5 19.665 4.5H14.445C8.955 4.5 4.5 8.955 4.5 14.445V39.555C4.5 45.045 8.955 49.5 14.445 49.5H39.555C45.045 49.5 49.5 45.045 49.5 39.555V22.815C49.5 19.4625 47.8575 16.515 45.315 14.715ZM32.3775 36.765H21.6C20.7225 36.765 20.0475 36.0675 20.0475 35.19C20.0475 34.335 20.7225 33.615 21.6 33.615H32.3775C33.255 33.615 33.9525 34.335 33.9525 35.19C33.9525 36.0675 33.255 36.765 32.3775 36.765Z"
                fill="#6690FF"
              />
            </svg>
          </div>
          <!-- /.icon -->
          <p class="group-name">{{ g.name }}</p>
          <!-- /.group-name -->
        </div>
      </div>
    </div>
    <div class="row mt-5 justify-content-end">
      <button
        @click="$router.push({ name: 'permissions_new_group' })"
        class="pos-primary-btn py-3 col-md-3"
      >
        {{ $t("common.add") }}
      </button>
    </div>
    <!-- /.row -->
  </div>
</template>

<script>
export default {
  name: "UsersPermissions",
  data() {
    return {
      groups: [],
      group_name: "",
    };
  },
  methods: {
    get_permission_groups() {
      this.$http
        .get(`/accounts/get_permission_groups`)
        .then((response) => {
          this.groups = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while fetching permission groups : ${e}`);
        });
    },
    redirect_to_group(group_id) {
      this.$router.push({
        name: "permissions_group_details",
        params: {
          group_id: group_id,
        },
      });
    },
  },
  created() {
    this.get_permission_groups();
  },
};
</script>

<style lang="scss" scoped>
.group {
  transition: all 0.4s ease-in-out;
  user-select: none;
}
.active-group {
  background: $primary-100;
  border-radius: 8px;
  padding: 0.1rem 0.5rem;
  .group-name {
    color: $error-500;
  }
}

.permissions {
  width: 100%;
  height: 100%;
  .groups {
    height: 80%;
  }
}
</style>
