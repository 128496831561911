var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addons full-height"},[_c('section_route',{attrs:{"routes":[
      { name: _vm.$t('sidebar.management'), route: 'management' },
      { name: _vm.$t('sidebar.products'), route: 'management_products' },
      { name: _vm.$t('sidebar.addons'), route: '' },
    ]}}),_c('div',{staticClass:"addons-content d-flex flex-wrap gap-3"},[_c('div',{staticClass:"addons col-12 col-sm-4"},[_c('div',{staticClass:"addons-wrapper d-flex flex-wrap gap-3"},[_vm._l((_vm.addons),function({ id, name }){return [(name)?_c('button',{key:id,staticClass:"col",class:{ 'active-item': _vm.current_addon_obj.id === id },on:{"click":function($event){return _vm.set_addon(id)}}},[_vm._v(" "+_vm._s(name)+" ")]):_c('button',{key:id,class:{ 'active-item': _vm.current_addon_obj.id === id },staticStyle:{"color":"gray"},on:{"click":function($event){return _vm.set_addon(id)}}},[_vm._v(" "+_vm._s(_vm.$t("common.new"))+" "),_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8 12H16","stroke":"gray","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 16V8","stroke":"gray","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z","stroke":"gray","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]})],2)]),_c('div',{staticClass:"addon-type col-2"},[_c('div',{staticClass:"addon-type-buttons d-flex flex-column p-1 gap-3"},_vm._l((_vm.current_addon_obj.types),function(type){return _c('button',{key:type.id,class:{
            'active-item': _vm.checked_addons_types_ids.includes(type.id),
          },on:{"click":function($event){return _vm.set_addon_type(type)}}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)]),_c('div',{staticClass:"addons-details full-height col p-3"},[_c('div',{staticClass:"inputs"},[(_vm.current_layout === 'addon')?_c('div',{},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current_addon_obj.name),expression:"current_addon_obj.name"}],staticClass:"addon-input w-100",attrs:{"type":"text","placeholder":"Addon Name"},domProps:{"value":(_vm.current_addon_obj.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.current_addon_obj, "name", $event.target.value)},_vm.create_new_addon_room]}})]):(_vm.current_layout === 'type')?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current_addon_type.name),expression:"current_addon_type.name"}],staticClass:"addon-input w-100",attrs:{"type":"text","placeholder":"Type Name"},domProps:{"value":(_vm.current_addon_type.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.current_addon_type, "name", $event.target.value)},_vm.create_new_addon_type_room]}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.current_addon_type.price),expression:"current_addon_type.price"}],staticClass:"mt-4 addon-input w-100",attrs:{"type":"text","placeholder":"Type Price"},domProps:{"value":(_vm.current_addon_type.price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.current_addon_type, "price", $event.target.value)}}})]):_vm._e()]),_c('div',{staticClass:"buttons d-flex flex-wrap gap-3 pt-5 pt-md-0"},[_c('button',{staticClass:"pos-primary-btn col-4",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" ")]),_c('button',{staticClass:"btn btn-danger col-4",on:{"click":_vm.delete_addons}},[_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }