import { render, staticRenderFns } from "./CompanyView.vue?vue&type=template&id=023e8282&scoped=true"
import script from "./CompanyView.vue?vue&type=script&lang=js"
export * from "./CompanyView.vue?vue&type=script&lang=js"
import style0 from "./CompanyView.vue?vue&type=style&index=0&id=023e8282&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023e8282",
  null
  
)

export default component.exports