<template>
  <div id="app" :dir="get_page_direction" class="full-screen-wrapper">
    <div ref="alertPlaceholder" id="alert-msg"></div>
    <NavBar />
    <logout_modal ref="logout_modal_comp" />
    <main_keyboard ref="keyboard" />

    <!-- sidebar and content -->
    <div class="d-flex full-height gap-3">
      <div v-if="is_logged_in" v-show="show_sidebar" class="sidebar-wrapper">
        <sidebar-menu
          v-if="sidebar_config"
          v-show="is_logged_in"
          :menu="sidebar_config.get_routes()"
          :collapsed="sidebar_config.collapsed"
          :theme="sidebar_config.theme"
          :rtl="sidebar_config.rtl"
          :show_one_child="sidebar_config.show_one_child"
          :showChild="sidebar_config.show_childs"
          @toggle-collapse="sidebar_config.on_toggle_collapse"
          @item-click="sidebar_config.on_item_click"
          class="sidebar"
          :hideToggle="sidebar_config.hide_toggle"
        >
          <template v-slot:header>
            <div id="hamburger-static" class="d-flex justify-content-center">
              <div class="position-relative">
                <input
                  ref="static_hamburger_input"
                  type="checkbox"
                  @change="toggle_sidebar"
                />
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </template>
          <span slot="dropdown-icon">
            <img :src="$get_icon('ArrowRightIcon')" alt="" />
          </span>
          <template v-slot:footer>
            <button @click="full_screen" class="bg-transparent border-0 my-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                width="24"
                height="24"
              >
                <path
                  d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM184 496H40c-13.3 0-24-10.7-24-24V328c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"
                />
              </svg>
            </button>

            <div
              v-if="sidebar_config.collapsed"
              class="text-center"
              style="font-size: 12px; font-weight: bold"
            >
              OnePos &copy;
            </div>
            <div v-else class="text-center">Powered By OnePos&copy;</div>
          </template>
        </sidebar-menu>
      </div>
      <div class="col full-height w-100">
        <div class="content full-height">
          <template v-if="!is_forbidden">
            <router-view />
          </template>
          <template v-else>
            <forbidden_403 />
          </template>
        </div>
      </div>
    </div>
    <!-- End of sidebar and content -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import logout_modal from "@/components/auth/logout_modal";
import NavBar from "@/components/navbar";

// sidebar
import { SidebarMenu } from "vue-sidebar-menu";
import SidebarConfig from "@/js/sidebar/main";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

export default {
  name: "App",
  watch: {
    $route: function () {
      this.change_is_forbidden(false);
      this.reset_forbidden_errors();
    },
  },
  components: {
    NavBar,
    SidebarMenu,
    main_keyboard: () => import("@/components/keyboards/main_keyboard"),
    forbidden_403: () => import("@/components/forbidden_403"),
    logout_modal,
  },
  computed: {
    ...mapGetters("auth", ["is_logged_in", "user_info", "get_page_direction"]),
    ...mapGetters("utils", ["is_forbidden"]),
  },
  data() {
    return {
      sidebar_config: null,
      show_sidebar: true,
      small_screen: 768,
    };
  },
  methods: {
    ...mapActions("utils", ["alert_dom"]),
    ...mapActions("utils", ["change_is_forbidden", "reset_forbidden_errors"]),
    is_small_screen() {
      return window.innerWidth <= this.small_screen;
    },
    show_keyboard() {
      this.$refs.keyboard.$refs.keyboard.style.display = "block";
    },
    toggle_sidebar() {
      this.sidebar_config.collapsed = !this.sidebar_config.collapsed;
      if (this.is_small_screen()) {
        this.show_sidebar = false;
      }
    },
    full_screen() {
      this.$fullscreen.toggle();
    },
    init_sidebar() {
      // initializing sidebar class
      this.sidebar_config = new SidebarConfig(this);
    },
    init_alert_msg() {
      // initializing alert message and save it to state
      this.alert_dom(this.$refs.alertPlaceholder);
    },
    handle_resize() {
      this.show_sidebar = window.innerWidth > this.small_screen;
    },
  },
  mounted() {
    this.init_alert_msg();
    this.init_sidebar();

    window.addEventListener("resize", this.handle_resize);
    this.handle_resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handle_resize);
  },
};
</script>
