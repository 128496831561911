import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// buffer package for qrcode
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

// Bootstrap 5 // check dropdown when changing
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// fullscreen mode
import VueFullscreen from "vue-fullscreen";
Vue.use(VueFullscreen);

//  vue-loading screen
import loading from "vuejs-loading-screen";
Vue.use(loading, {
  bg: "rgba(0,9,27,0.75)",
  slot: `
  <div id="loading-screen">
  <div class="container">
  <div class="coast">
    <div class="wave-rel-wrap">
      <div class="wave"></div>
    </div>
  </div>
  <div class="coast delay">
    <div class="wave-rel-wrap">
      <div class="wave delay"></div>
    </div>
  </div>
  <div class="text text-a">O</div>
  <div class="text text-b">n</div>
  <div class="text text-c">e</div>
  <div class="text text-d">P</div>
  <div class="text text-e">o</div>
  <div class="text text-f">s</div>
</div>
</div>`,
});

// custom global functions
import { get_route_url, get_icon } from "@/global_functions";
Vue.prototype.$get_route_url = get_route_url;
Vue.prototype.$get_icon = get_icon;

import i18n from "@/i18n";

// vue global filters
Vue.filter("get_datetime", function (date) {
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const d = new Date(date);
  return d.toLocaleTimeString("ar-eg", dateOptions);
});

// Vue-instant Search Package
import "vue-instant/dist/vue-instant.css";
import VueInstant from "vue-instant/dist/vue-instant.common";
Vue.use(VueInstant);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    // Set the initial locale from the Vuex store
    this.$i18n.locale = this.$store.getters["auth/user_info"].lang;
  },
  render: (h) => h(App),
}).$mount("#app");
