<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.loss_and_damage'), route: 'damaged_products' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="products.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="products.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("common.product_name") }}</th>
              <th>{{ $t("common.quantity") }}</th>
              <th>{{ $t("common.total_before_tax") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(purchase, index) of products" :key="index">
              <td>{{ purchase.product_id }}</td>
              <td>{{ purchase.product }}</td>
              <td>{{ purchase.quantity }}</td>
              <td>{{ purchase.subtotal }}</td>
              <td>{{ purchase.total }}</td>
            </tr>
            <tr v-if="meta_data.meta_data">
              <!-- SKIP product_id, product, quantity columns-->
              <td v-for="index in 3" :key="index"></td>
              <td>{{ meta_data.meta_data.subtotal }}</td>
              <td>{{ meta_data.meta_data.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PurchaseByProduct",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/damaged_products/`,
      products: [],
      meta_data: {},
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.meta_data = response.data.pop();
          this.products = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching damaged products : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "damaged_products.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 60rem;
  max-height: 60rem;
  overflow: scroll;
}
</style>
