import axios from "axios";

export async function get_pdf(url, file_name, symbol = "?") {
  try {
    const response = await axios.get(`${url}${symbol}generate_pdf=true`, {
      responseType: "blob", // Set the response type to blob
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a download link and trigger a click event to start the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    // Extracting the filename from the Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    const match =
      contentDisposition && contentDisposition.match(/filename="(.*)"/);
    const filename = match && match[1] ? match[1] : file_name;

    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
}
