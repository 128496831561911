import InnerSettingsMain from "@/views/management/settings/InnerSettingsMain";
import OrdersSettings from "@/views/management/settings/OrdersSettings";
import ScalesSettings from "@/views/management/settings/ScalesSettings";
import CustomerDisplay from "@/views/management/settings/CustomerDisplay";
import TaxesView from "@/views/management/settings/TaxesView";
import PaymentTypes from "@/views/management/settings/PaymentTypes";
import DatabaseSettings from "@/views/management/settings/DatabaseSettings";
import PrinterSettings from "@/views/management/settings/PrinterSettings";

export default [
  {
    path: "/management/management",
    name: "settings",
    component: InnerSettingsMain,
    children: [
      {
        path: "payment/",
        component: OrdersSettings,
        name: "management_payment",
        meta: {
          title: () => {
            return `OnePos | Payment & Orders Settings`;
          },
        },
      },
      {
        path: "weighing_scales/",
        component: ScalesSettings,
        name: "management_scales",
        meta: {
          title: () => {
            return "Weighing Scale settings";
          },
        },
      },
      {
        path: "customer_display/",
        component: CustomerDisplay,
        name: "customer_display",
      },
      {
        path: "taxes/",
        component: TaxesView,
        name: "management_taxes",
        meta: {
          title: () => {
            return `OnePos | Taxes Settings`;
          },
        },
      },
      {
        path: "payment_types/",
        component: PaymentTypes,
        name: "payment_types",
        meta: {
          title: () => {
            return `OnePos | Payment Types`;
          },
        },
      },
      {
        path: "database/",
        component: DatabaseSettings,
        name: "management_database",
      },
      {
        path: "printers/",
        component: PrinterSettings,
        name: "management_printers",
        meta: {
          title: () => {
            return `OnePos | Printer Settings`;
          },
        },
      },
    ],
  },
];
