import InventoryDamage from "@/views/management/inventory/damage/InventoryDamage";
import NewInventoryDamage from "@/views/management/inventory/damage/NewInventoryDamage";
import DisplayInventoryDamage from "@/views/management/inventory/damage/DisplayInventoryDamage";

export default [
  {
    path: "inventory_damage/",
    component: InventoryDamage,
    name: "inventory_damage",
    meta: {
      title: () => {
        return "OnePos | inventory Damage";
      },
    },
  },
  {
    path: "inventory_damage/new",
    component: NewInventoryDamage,
    name: "new_inventory_damage",
    meta: {
      title: () => {
        return "OnePos |new inventory damage";
      },
    },
  },
  {
    path: "inventory_damage/damage_details/:report_id",
    component: DisplayInventoryDamage,
    name: "damage_details",
    meta: {
      title: (route) => {
        return `OnePos | InventoryDamage ${route.params.report_id} `;
      },
    },
  },
];
