import ProductsReport from "@/views/management/reports/inventory_reports/ProductsReport";
import ProductGroupsReport from "@/views/management/reports/inventory_reports/ProductGroupsReport";

export default [
  {
    path: "/management/reports/products_report/",
    component: ProductsReport,
    name: "products_report",
    meta: {
      title: () => {
        return "OnePos | Products Report";
      },
    },
  },
  {
    path: "/management/reports/product_groups_report/",
    component: ProductGroupsReport,
    name: "product_groups_report",
    meta: {
      title: () => {
        return "OnePos | Products Groups Report";
      },
    },
  },
];
