<template>
  <div class="footer">
    <div class="total">
      <div class="nums">
        <div class="line">
          <p>{{ $t("common.total_before_tax") }}:</p>
          <p>{{ counted_subtotal }}</p>
        </div>
        <!-- /.line -->
        <div class="line">
          <p>{{ $t("common.tax") }}:</p>
          <p>{{ counted_tax }}</p>
        </div>
        <!-- /.line -->
        <div class="line">
          <p>{{ $t("common.total") }}:</p>
          <p>{{ counted_total }}</p>
        </div>
        <!-- /.line -->
      </div>
      <div class="discount">
        <select
          ref="total_discount_tax_type"
          @change="total_discount_changes"
          class="form-select"
        >
          <option selected :value="dropdown_discount_type.after">
            {{ $t("common.discount_after_tax") }}
          </option>
          <option :value="dropdown_discount_type.before">
            {{ $t("common.discount_before_tax") }}
          </option>
        </select>
        <select
          ref="total_discount_type"
          @change="total_discount_changes"
          class="form-select"
        >
          <option
            :value="discount_types.fixed"
            :selected="discount_type === '$'"
          >
            {{ $t("common.fixed_discount") }}
          </option>
          <option
            :value="discount_types.percentage"
            :selected="discount_type === '%'"
          >
            {{ $t("common.percentage_discount") }}
          </option>
        </select>
        <input
          v-model="invoice_discount"
          @input="total_discount_changes"
          type="number"
          class="pos-input"
        />
      </div>

      <div class="d-flex gap-2 justify-content-end pt-5 pb-5">
        <button @click="save" class="pos-primary-btn col-3 col-sm-2">
          {{ $t("common.save") }}
        </button>
        <button
          @click="$router.push({ name: 'all_purchases' })"
          id="cancel-btn"
          class="btn btn-dark py-3 col-2 col-sm-1"
        >
          {{ $t("common.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import reset_total_to_zero from "@/js/purchases/purchases";
import { fixed_float } from "@/helpers";

export default {
  name: "FooterSection",
  props: ["discount_type", "products", "count_items", "update_product"],
  computed: {
    invoice_discount: {
      get() {
        return this.$store.getters["purchase_crud/get_invoice_discount"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_invoice_discount", val);
      },
    },
    counted_subtotal: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_subtotal"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_subtotal", val);
      },
    },
    counted_tax: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_tax"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_tax", val);
      },
    },
    counted_total: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_total"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_total", val);
      },
    },
  },
  data() {
    return {
      dropdown_discount_type: {
        after: "discount_after_tax", //   discounting from total
        before: "discount_before_tax", // discounting from subtotal
      },
      discount_types: {
        fixed: "f",
        percentage: "p",
      },
    };
  },
  methods: {
    get_init_discount(discount_type, type) {
      /*
       * Getting initial discount value that user have entered if it's fixed or percentage
       * if it is a percentage value we will convert it to be percentage
       * @type if function needs to be counted on total or subtotal (used in percentage)
       * */
      let total_discount = 0;
      let _total_or_subtotal, _discount, _total_discount;
      switch (discount_type) {
        case this.discount_types.fixed:
          total_discount = parseFloat(this.invoice_discount) || 0;
          break;

        case this.discount_types.percentage:
          _total_or_subtotal =
            type === "subtotal"
              ? parseFloat(this.counted_subtotal)
              : parseFloat(this.counted_total);

          _discount = parseFloat(this.invoice_discount);
          _total_discount = (_total_or_subtotal * _discount) / 100;
          total_discount = _total_discount || 0;
          break;
      }
      return total_discount;
    },
    discount_after_tax(discount) {
      let taxes = 0;
      let discount_op = discount / this.counted_total;
      console.log("discount_op: ", discount_op);

      for (let product of this.products) {
        if (product.tax.length > 0) {
          // 15% -> 1.15 which is 115/100
          const product_tax = product.tax[0].percentage_value / 100 + 1;
          const product_price =
            parseFloat(product.price_with_tax) * parseFloat(product.quantity);

          // ratio that product takes from discount
          const product_discount_ratio = product_price * discount_op;
          const product_total = product_price - product_discount_ratio;
          const product_subtotal = product_total / product_tax;
          const product_tax_ratio = product_total - product_subtotal;

          taxes += product_tax_ratio;
        }
      }

      this.counted_tax = fixed_float(taxes);
      this.counted_total = fixed_float(this.counted_total - discount);
      this.counted_subtotal = fixed_float(this.counted_total - taxes);
      reset_total_to_zero(this);
    },
    discount_before_tax(discount) {
      let total = 0;
      const discount_op = discount / this.counted_subtotal;
      console.log("discount_op: ", discount_op);

      for (let product of this.products) {
        if (product.tax.length > 0) {
          // 15% -> 1.15 which is 115/100
          const product_tax = product.tax[0].percentage_value / 100 + 1;
          // note that product.subtotal is already subtotal * quantity
          const product_discount_ratio = product.subtotal * discount_op;
          total += (product.subtotal - product_discount_ratio) * product_tax;
        }
      }
      this.counted_total = fixed_float(total);
      this.counted_subtotal = fixed_float(this.counted_subtotal - discount);
      this.counted_tax = fixed_float(total - this.counted_subtotal);
      reset_total_to_zero(this);
    },
    total_discount_changes() {
      // recalculate items on each discount change otherwise the total amount
      // will be reduced everytime discount changes
      this.count_items();

      const tax_type = this.$refs.total_discount_tax_type; // "before" or "after" tax
      const discount_type = this.$refs.total_discount_type; // "f"$ or "p"%

      console.warn("discount : ", discount_type.value);

      let selected_type = tax_type[tax_type.selectedIndex].value;
      switch (selected_type) {
        case this.dropdown_discount_type.after:
          this.discount_after_tax(
            this.get_init_discount(discount_type.value, "total")
          );
          break;

        case this.dropdown_discount_type.before:
          this.discount_before_tax(
            this.get_init_discount(discount_type.value, "subtotal")
          );
          break;
      }
    },
    save() {
      // check if external number or supplier is not empty
      if (
        this.$parent.$refs.header_section.$refs.ext_id.value.length <= 0 ||
        !this.$parent.$refs.header_section.selected_supplier.id
      ) {
        this.$store.dispatch("utils/push_alert", {
          message: `You Must Provide  External Number And Supplier`,
          type: "danger",
        });
        return;
      }

      // const discount = 0;
      const purchase_info = {
        ex_id: this.$parent.$refs.header_section.$refs.ext_id.value,
        supplier: this.$parent.$refs.header_section.selected_supplier.id,
        date: this.$parent.$refs.header_section.$refs.inv_date.value,
        paid: this.$parent.$refs.header_section.$refs.paid.checked,
        discount: this.invoice_discount || 0,
        discount_type: this.$refs.total_discount_type.value === "f" ? "$" : "%",
        subtotal: parseFloat(this.counted_subtotal).toFixed(2),
        total: parseFloat(this.counted_total).toFixed(2),
        tax_total: parseFloat(this.counted_tax).toFixed(2),
      };

      const s_obj = this.products.map((product) => ({
        product: product.id,
        quantity: product.quantity,
        subtotal: product.subtotal,
        price_with_tax: product.price_with_tax,
        discount: product.discount,

        tax: product.tax.map((t) => t.id),
        tax_total: parseFloat(product.tax_total).toFixed(2),
        total: product.total,
      }));
      console.log("after server object: ", s_obj);
      if (this.$parent.new_page) {
        console.log("new_page");
        this.$http
          .post("/purchases/purchases", {
            purchase_info: purchase_info,
            products: s_obj,
          })
          .then(() => {
            this.$router.push({ name: "all_purchases" });
          })
          .catch((e) => {
            console.log("Error while trying add new purchase", e);
          });
      } else if (this.$parent.refund_page) {
        this.$http
          .post("/purchases/refund", {
            purchase_info: { refunded: true, ...purchase_info },
            products: s_obj,
          })
          .then(() => {
            this.$router.push({ name: "purchases_refund" });
          })
          .catch((e) => {
            console.log("Error while trying add new purchase", e);
          });
      } else if (this.$parent.purchases_update || this.$parent.refund_update) {
        const url = this.$parent.purchases_update
          ? "/purchases/purchases"
          : "/purchases/refund";
        this.$http
          .put(url, {
            purchase_info: { id: this.$parent.info.id, ...purchase_info },
            products: s_obj,
          })
          .then(() => {
            if (this.$parent.purchases_update) {
              this.$router.push({
                name: "purchase_details",
                params: { report_id: this.$route.params.id },
              });
            } else {
              this.$router.push({
                name: "purchase_refund_details",
                params: { report_id: this.$route.params.id },
              });
            }
            this.$store.dispatch("utils/push_alert", {
              message: `Purchase Updated Successfully`,
              type: "success",
            });
          })
          .catch((e) => {
            console.log("Error while trying add new purchase", e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;

  .total {
    //height: 20%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid $border-color;
    gap: 1rem;

    .nums,
    .discount {
      display: flex;
      gap: 1rem;
    }

    .nums {
      & > * {
        flex: 1;
        display: flex;
        justify-content: space-around;

        p {
          font-size: 20px;
        }

        p:nth-child(1) {
          color: #808080;
        }

        p:nth-child(2) {
          color: $secondary-500;
        }
      }
    }

    .discount {
      input {
        text-align: center;
      }
    }
  }
}
</style>
