<!-- TODO: make Merge refundedPurchase and PurchasesView in one Page" -->
<template>
  <div class="purchases-view">
    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              <span style="font-size: 1.2rem">
                {{ $t("sidebar.purchases") }}
                {{ this.current_purchase.id || "" }},
              </span>
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_purchase" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!-- End of ConfirmDelete Modal -->
    <div class="header">
      <div class="sec-name">{{ $t("purchase.purchase_refund") }}</div>
      <div
        class="d-flex flex-wrap align-items-baseline justify-content-between mt-3"
      >
        <div
          class="col-md-9 d-flex flex-wrap align-items-baseline justify-content-between"
        >
          <select ref="supplier_dropdown" class="form-select w-25">
            <option
              v-for="supplier in suppliers"
              :key="supplier.id"
              :value="supplier.name"
            >
              {{ supplier.name }}
            </option>
            <option selected value>{{ $t("common.chose_a_supplier") }}</option>
          </select>
          <div class="static-floating-label-content">
            <input
              ref="ref_input"
              class="static-floating-input"
              type="text"
              placeholder=""
            />
            <label class="static-floating-label">
              {{ $t("common.ref_number") }}
            </label>
          </div>
          <label>
            {{ $t("common.from") }} :
            <input ref="from_date" type="date" class="pos-input" />
          </label>
          <label>
            {{ $t("common.to") }} :
            <input ref="to_date" type="date" class="pos-input" />
          </label>
          <div class="btn-group">
            <button
              ref="status_button"
              type="button"
              class="btn btn-info text-light dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              {{ $t("common.status") }}
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item text-success"
                  @click="set_filter_status('paid')"
                >
                  {{ $t("common.paid") }}</a
                >
              </li>
              <li @click="set_filter_status('unpaid')">
                <a class="dropdown-item text-warning">{{
                  $t("common.unpaid")
                }}</a>
              </li>
              <li @click="set_filter_status('')">
                <a class="dropdown-item text-info">{{
                  $t("common.default")
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 d-flex justify-content-end">
          <button @click="search_filter" class="btn btn-outline-primary w-100">
            {{ $t("common.search") }}
          </button>
        </div>
      </div>
    </div>
    <div class="body">
      <div @scroll="on_table_scroll" class="table-wrapper">
        <table class="primary-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("common.ref_number") }}</th>
              <th>{{ $t("common.supplier") }}</th>
              <th>{{ $t("common.date") }}</th>
              <th>{{ $t("common.subtotal") }}</th>
              <th>{{ $t("common.tax") }}</th>
              <th>{{ $t("common.total") }}</th>
              <th>{{ $t("common.paid") }}</th>
              <th>{{ $t("common.created_by") }}</th>
              <th>{{ $t("common.last_modified_by") }}</th>
              <th>{{ $t("common.control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="{
                id,
                external_id,
                supplier,
                date,
                subtotal,
                tax_total,
                total,
                paid,
                created_by,
                last_modified_by,
              } in purchases"
              :key="id"
            >
              <td>{{ id }}</td>

              <td>{{ external_id }}</td>
              <td class="cell-ellipsis" :title="supplier">{{ supplier }}</td>
              <!--            todo: convert to local date time-->
              <td>{{ new Date(date).toLocaleDateString() }}</td>
              <td>{{ subtotal }}</td>
              <td>{{ tax_total }}</td>
              <td>{{ total }}</td>
              <td><input type="checkbox" :checked="paid" disabled /></td>
              <td class="cell-ellipsis" :title="created_by">
                {{ created_by }}
              </td>
              <td class="cell-ellipsis" :title="last_modified_by">
                {{ last_modified_by }}
              </td>

              <td>
                <div class="d-flex justify-content-center">
                  <a
                    :href="
                      $get_route_url('purchase_refund_details', {
                        report_id: id,
                      })
                    "
                    class="btn btn-primary mx-1"
                  >
                    <img :src="$get_icon('view')" alt="" />
                  </a>

                  <a
                    :href="
                      $get_route_url('purchases_refund_update', { id: id })
                    "
                    class="btn btn-success mx-1"
                  >
                    <img :src="$get_icon('edit')" alt="" />
                  </a>

                  <button
                    @click="set_current_purchase(id)"
                    data-bs-toggle="modal"
                    data-bs-target="#delmodal"
                    class="btn btn-danger mx-1"
                  >
                    <img :src="$get_icon('delete')" alt="" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer">
      <div class="row justify-content-center mt-3">
        <div class="col-md-2">
          <span>Page {{ pagination.current_page }} </span>of
          {{ pagination.total_pages }}
        </div>
      </div>
      <div class="row justify-content-end">
        <button
          @click="$router.push({ name: 'purchases_new_refund' })"
          class="col-auto py-3 pos-primary-btn"
        >
          {{ $t("purchase.add_new_purchase_refund") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";

export default {
  name: "PurchasesView",
  data() {
    return {
      purchases: [],
      suppliers: [],

      // current selected purchase
      current_purchase: {},

      // filter
      filter_status: "",
      // pagination
      pagination: {
        current_page: 1,
      },
    };
  },
  methods: {
    close_current_modal,
    get_purchases() {
      this.$isLoading(true);
      const next_page = this.pagination.next;
      this.$http
        .get(next_page ? next_page : `/purchases/refund?page=1`)
        .then((response) => {
          this.purchases.push(...response.data.results);
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(`Error while fetching refunded purchases ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    get_suppliers() {
      this.$http
        .get(`/accounts/get_suppliers/`)
        .then((response) => {
          this.suppliers = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while fetching suppliers ! : ${e}`);
        });
    },

    set_current_purchase(purchase_id) {
      this.current_purchase = this.purchases.find((p) => p.id === purchase_id);
    },
    delete_purchase() {
      this.$isLoading(true);

      this.$http({
        method: "DELETE",
        url: "/purchases/purchases",
        data: this.current_purchase,
      })
        .then(() => {
          this.close_current_modal();
          this.purchases = this.purchases.filter(
            (p) => p.id !== this.current_purchase.id
          );
          this.$store.dispatch("utils/push_alert", {
            message: `Purchase Deleted Successfully`,
            type: "success",
          });
        })
        .catch((e) => {
          console.log(`Error while deleting Purchases ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    // search filters
    search_filter() {
      this.$isLoading(true);
      const from_date = this.$refs.from_date.value
        ? new Date(this.$refs.from_date.value).toISOString()
        : "";

      const to_date = this.$refs.to_date.value
        ? new Date(this.$refs.to_date.value).toISOString()
        : "";

      const url = `/purchases/refund?supplier=${this.$refs.supplier_dropdown.value}&ref=${this.$refs.ref_input.value}&status=${this.filter_status}&from_date=${from_date}&to_date=${to_date}`;
      this.$http
        .get(url)
        .then((response) => {
          this.pagination = response.data.pagination;
          this.purchases = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while filtering inventory count : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    set_filter_status(status) {
      let self = this;
      // setTimeout because bootstrap dropdown won't close with vue click event binding
      setTimeout(function () {
        self.filter_status = status;
        const btn = self.$refs.status_button;
        const default_class = "btn btn-info text-light dropdown-toggle";

        if (status.toLowerCase() === "paid") {
          btn.className = "btn btn-success text-light dropdown-toggle";
          btn.innerText = "Paid";
        } else if (status.toLowerCase() === "unpaid") {
          btn.className = "btn btn-warning text-light dropdown-toggle";
          btn.innerText = "UnPaid";
        } else {
          btn.className = default_class;
          btn.innerText = "status";
        }
        // btn.setAttribute("aria-expanded", "false");
      }, 50);
    },

    on_table_scroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      // paginate to the next page if user reached the bottom of the table
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.pagination.next) {
          this.get_purchases();
        }
      }
    },
  },
  mounted() {
    this.get_purchases();
    this.get_suppliers();
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/settings/purchases/purchase-view";
</style>
