<template>
  <input
    ref="search_input"
    v-model="search_val"
    @input="name_search"
    @keypress.enter="barcode_search"
    @focus="$store.dispatch('keyboard/set_active_input', $event.path[0])"
    type="search"
    class="pos-input"
    :placeholder="$t('common.search_by_product_name_or_barcode')"
  />
</template>

<script>
// import { debounce } from "@/helpers";
import { is_valid_ean } from "@/js/sell_screen/scales.js";

export default {
  name: "search_products",
  data() {
    return {
      types: {
        barcode: "b",
        name: "n",
      },
      search_val: "",
      products: [],
      barcode: null, // current barcode
    };
  },
  methods: {
    reset_products() {
      if (this.search_val.length > 0) {
        this.$parent.sf = true;
      } else {
        this.$parent.sf = false;
        this.$parent.searched_products = [];
      }
    },
    name_search() {
      this.reset_products();
      // check if its a barcode
      if (this.time) {
        clearTimeout(this.time);
      }

      // todo : add matched
      const matched = [];
      // let r = new RegExp("^" + this.search_val + "$");
      // const matched = this.$parent.cached_products.filter((product) => {
      //   return r.test(product.name);
      // });

      if (matched.length > 0) {
        this.$parent.searched_products = [...matched];
      } else {
        // console.log("searching .... ");
        this.time = setTimeout(() => {
          this.get_products_request(this.types.name);
        }, 800);
      }
    },
    barcode_search() {
      this.barcode = this.search_val;
      this.search_val = "";
      this.reset_products();
      if (!isNaN(this.barcode)) {
        if (this.barcode.length >= 8) {
          if (is_valid_ean(this.barcode)) {
            // add the received product automatically to the orders
            // check if the barcode is in the current products or cached_products
            const products = [
              ...this.$parent.products,
              ...this.$parent.cached_products,
            ];
            for (let i = 0; i < products.length; i++) {
              if (this.barcode === products[i].barcode) {
                this.$parent.add_item(products[i]);
                return 0;
              }
            }
            this.get_products_request(this.types.barcode);
          } else {
            // todo: alert using bootstrap alerts
            // console.log("Barcode is not valid");
            alert("Barcode is not valid");
          }
        }
      }
    },
    get_products_request(type) {
      if (type === this.types.barcode) {
        this.$http
          .get(`/inventory/get_products/?search=${this.barcode}`)
          .then((response) => {
            // console.log(
            //   `BARCODE RESPONSE: ${JSON.stringify(response.data.results)}`
            // );
            if (response.data.results.length > 0) {
              const item = response.data.results[0];
              this.$parent.products = [item];
            }

            // this.pagination = response.data.pagination;
          })
          .catch((e) => {
            console.log(`Error while searching products by barcode ! : ${e}`);
          });
      } else if (type === this.types.name) {
        // todo: review on matched and cached
        this.$http
          .get(`/inventory/get_products/?search=${this.search_val}`)
          .then((response) => {
            // add the results to cached products
            response.data.results.forEach(
              (item) => (item.ordered_quantity = 1)
            );
            this.$parent.products = response.data.results;
          })
          .catch((e) => {
            console.log(`Error while searching products by name ! : ${e}`);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  position: absolute;
  top: 0.28rem;
  left: 0.28rem;
  width: 32px;
  height: 32px;
}
.search-bar {
  font-family: "Manrope", serif;
  font-style: normal;
  font-weight: 500;
  //font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.6);
  background: #f8f9fc;
  border: 1px solid #e0e9f4;
  border-radius: 8px;
  padding-left: 2.2rem;
}
svg {
  padding-top: 10px;
  margin-top: 10px;
}
</style>
