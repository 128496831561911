import { add_promo_item } from "@/js/sell_screen/promo/add_promo_item";
import store from "@/store/index";

function prevent_negative(vm, item, stored_qty, increased_qty) {
  // if prevent negative is enabled
  if (
    vm.settings.prevent_negative &&
    (stored_qty > item.quantity || increased_qty > item.quantity)
  ) {
    vm.$store.dispatch("utils/push_alert", {
      message: `You cannot sell product quantity less than 0 check negative option in the settings`,
      type: "danger",
    });
    return true;
  }
  return false;
}

export function show_itembox_modal(vm, order_item) {
  // convert clicked_itembox_product itemboxes passed as props to itembox_modal component
  vm.clicked_itembox_product = order_item;
  // open the itembox_modal window
  vm.$refs.show_itembox_modal_btn.click();
}

export function add_item(vm, order_item) {
  if (order_item.promo) {
    return add_promo_item(order_item);
  }

  let increased_qty = 1;
  const stored_qty = vm.$store.getters["keyboard/get_current_qty"];
  const item_index = store.getters[
    "sell_screen/get_current_order"
  ].items.findIndex(
    (current_order_item) => current_order_item.id === order_item.id
  );

  // change counter of ordered_quantity property
  if (stored_qty > 0) {
    // if there is quantity
    increased_qty = stored_qty;
  } else {
    // if item already exists and there is no quantity
    if (item_index > -1) {
      const order_item =
        store.getters["sell_screen/get_order_item"](item_index);

      increased_qty = order_item.ordered_quantity + 1;
    }
  }
  if (prevent_negative(vm, order_item, stored_qty, increased_qty)) {
    return 0;
  }

  store.dispatch("sell_screen/add_order_item", order_item);
  // vm.check_reorder_qty(order_item); todo: fix
  vm.$store.dispatch("keyboard/reset_quantity");
}
