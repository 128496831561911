<template>
  <div class="inventory-count pb-5 pb-md-0">
    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delete-modal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span style="font-size: 1.2rem">
              {{ $t("reports.report") }} {{ selected_item.id }}
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_items" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of ConfirmDelete Modal -->

    <div class="d-flex justify-content-between">
      <div class="d-none d-md-block">
        <p class="sec-name">
          {{ $t("inventory.inventory_count") }}
        </p>
      </div>
      <div class="d-flex col-12 col-md-auto justify-content-between">
        <button @click="filter = !filter" class="btn btn-light">
          <img v-show="filter" :src="$get_icon('FilterOnIcon')" alt="" />
          <img v-show="!filter" :src="$get_icon('FilterOffIcon')" alt="" />
        </button>
        <button
          @click="$router.push({ name: 'new_inventory_count' })"
          class="btn btn-primary"
        >
          {{ $t("inventory.add_new_inventory_count") }}
        </button>
      </div>
    </div>

    <div
      v-if="filter"
      class="filters d-flex flex-wrap gap-3 align-items-center justify-content-start justify-content-md-between mt-3"
    >
      <div>
        <p>{{ $t("common.from") }}</p>
        <input ref="from_date" type="date" class="pos-input px-4 py-2 ms-3" />
      </div>

      <div>
        <p>{{ $t("common.to") }}</p>
        <input ref="to_date" type="date" class="pos-input px-4 py-2 ms-3" />
      </div>

      <div>
        <p>{{ $t("common.user") }}</p>
        <input
          ref="user_input"
          class="pos-input px-2 py-2 ms-3"
          list="users"
          :placeholder="$t('common.search_by_user')"
        />
        <datalist id="users">
          <option
            v-for="{ pk, username } in users"
            :key="pk"
            :value="username"
          ></option>
        </datalist>
      </div>
      <div class="btn-group col-12 col-md-auto">
        <button
          ref="status_button"
          type="button"
          class="btn btn-info text-light dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          {{ $t("inventory.status") }}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item text-success"
              @click="set_filter_status('accepted')"
            >
              {{ $t("inventory.approved") }}</a
            >
          </li>
          <li @click="set_filter_status('pending')">
            <a class="dropdown-item text-warning">{{
              $t("inventory.pending")
            }}</a>
          </li>
          <li @click="set_filter_status('declined')">
            <a class="dropdown-item text-danger">
              {{ $t("inventory.declined") }}</a
            >
          </li>
          <li @click="set_filter_status('')">
            <a class="dropdown-item text-info">{{ $t("common.default") }}</a>
          </li>
        </ul>
      </div>
      <button
        @click="search_filter"
        class="pos-primary-btn px-5 py-2 col-12 col-md-auto"
      >
        {{ $t("common.search") }}
      </button>
    </div>

    <table class="primary-table responsive-table mt-4">
      <thead>
        <tr>
          <th>ID</th>
          <th>{{ $t("common.user") }}</th>
          <th>{{ $t("common.date") }}</th>
          <th>{{ $t("inventory.status") }}</th>
          <th>{{ $t("common.last_modified_by") }}</th>
          <th>{{ $t("common.details") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="{
            id,
            created_by,
            timestamp,
            status,
            last_modified_by,
          } in counts"
          :key="id"
          @click="set_selected_item(id)"
        >
          <td data-label="ID">{{ id }}</td>
          <td :data-label="$t('common.user')">{{ created_by }}</td>
          <td :data-label="$t('common.date')">{{ timestamp | date }}</td>
          <td
            :data-label="$t('inventory.status')"
            style="font-size: 18px"
            :class="{
              'text-warning': status === 'pending',
              'text-success': status === 'accepted',
              'text-danger': status === 'declined',
            }"
          >
            {{ $t(`inventory.${status}`) }}
          </td>
          <td :data-label="$t('common.last_modified_by')">
            {{ last_modified_by }}
          </td>
          <td :data-label="$t('common.details')">
            <div class="d-flex justify-content-center gap-3">
              <button
                @click="
                  $router.push({
                    name: 'count_details',
                    params: { report_id: id, status: status },
                  })
                "
                class="btn btn-primary"
              >
                <img :src="$get_icon('view')" alt="" />
              </button>
              <button
                @click="set_selected_item(id)"
                data-bs-toggle="modal"
                data-bs-target="#delete-modal"
                class="btn btn-danger"
              >
                <img :src="$get_icon('delete')" alt="" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="w-50 mx-auto">
      <div class="pagination d-flex flex-wrap justify-content-between mt-5">
        <button
          @click="pre_page"
          :disabled="!pagination.previous"
          class="btn btn-light"
        >
          {{ $t("common.previous") }}
        </button>
        <span class="text-center"
          >Page {{ pagination.current_page }} of
          {{ pagination.total_pages }}</span
        >
        <button
          @click="next_page"
          :disabled="!pagination.next"
          class="btn btn-light"
        >
          {{ $t("common.next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";
import { mapActions } from "vuex";

export default {
  name: "InventoryCount",

  data() {
    return {
      filter: false,
      selected_item: {},
      counts: [],
      users: [],
      filter_status: "",
      pagination: {
        count: 0,
        current_page: 1,
        next: null,
        previous: null,
        total_pages: 1,
      },
    };
  },
  filters: {
    date: function (date) {
      const d = new Date(date);
      return d.toLocaleDateString();
    },
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden", "append_forbidden_error"]),
    close_current_modal,
    set_selected_item(id) {
      this.selected_item = this.counts.find((product) => product.id === id);
    },
    get_inventory_counts() {
      this.$http
        .get(
          `/inventory/get_inventory_count/?page=${this.pagination.current_page}`
        )
        .then((response) => {
          this.counts = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
            this.append_forbidden_error(
              "Make Sure You Have Permission To View Inventory Count Operations"
            );
          }
          console.log(`Error while fetching inventory counts ! : ${e}`);
        });
    },
    next_page() {
      if (this.pagination.next) {
        this.pagination.current_page += 1;
        this.get_inventory_counts();
      }
    },
    pre_page() {
      if (this.pagination.previous) {
        this.pagination.current_page -= 1;
        this.get_inventory_counts();
      }
    },
    get_users() {
      this.$http
        .get(`/accounts/get_branch_users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
            this.append_forbidden_error(
              "Make Sure You Have Permission To View Users"
            );
          }
          console.log(`Error while fetching users : ${e}`);
        });
    },
    search_filter() {
      const user = this.$refs.user_input.value;
      const from_date = this.$refs.from_date.value
        ? new Date(this.$refs.from_date.value).toISOString()
        : "";

      const to_date = this.$refs.to_date.value
        ? new Date(this.$refs.to_date.value).toISOString()
        : "";

      const url = `/inventory/get_inventory_count/?user=${user}&status=${this.filter_status}&from_date=${from_date}&to_date=${to_date}`;
      this.$http
        .get(url)
        .then((response) => {
          this.counts = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(`Error while filtering inventory count : ${e}`);
        });
    },
    set_filter_status(status) {
      let self = this;
      // setTimeout because bootstrap dropdown won't close with vue click event binding
      setTimeout(function () {
        self.filter_status = status;
        const btn = self.$refs.status_button;
        const default_class = "btn btn-info text-light dropdown-toggle";
        if (status.toLowerCase() === "accepted") {
          btn.className = "btn btn-success text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.approved");
        } else if (status.toLowerCase() === "pending") {
          btn.className = "btn btn-warning text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.pending");
        } else if (status.toLowerCase() === "declined") {
          btn.className = "btn btn-danger text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.declined");
        } else {
          btn.className = default_class;
          btn.innerText = self.$t("inventory.status");
        }
        // btn.setAttribute("aria-expanded", "false");
      }, 50);
    },
    delete_items() {
      this.$http({
        method: "DELETE",
        url: "/inventory/delete_inventory_count",
        data: [this.selected_item.id],
      })
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: `Report Deleted Successfully`,
            type: "success",
          });

          this.counts = this.counts.filter(
            (report) => report.id !== this.selected_item.id
          );
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: `Cannot Delete the Report`,
            type: "danger",
          });
          console.log(`Error while deleting inventory_counts ! : ${e}`);
        });

      this.close_current_modal();
    },
  },
  mounted() {
    this.get_inventory_counts();
    this.get_users();
  },
};
</script>

<style lang="scss" scoped>
.inventory-count {
  .filters {
    height: 7%;
    width: 100%;

    .dropdown-menu li {
      padding: 0.24rem;
      a {
        font-size: 1.5rem;
      }
    }
  }

  .footer {
    button {
      padding: 1rem 0;
    }
  }
}
</style>
