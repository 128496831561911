export default {
  void_order: "إلغاء الفاتورة",
  invoices: "الفواتير",
  addons: "الإضافات",
  save_sale: "المبيعات المحفوظة",
  change_price: "تغيير السعر",
  payments: "انواع الدفع",
  group: "مجموعة",

  // clients
  add_new_client: "اضافة عميل جديد",
  clients_search_desc: "ابحث عن عميل بالاسم او رقم الهاتف",

  // addons
  addon_details: "تفاصيل الاضافات",
  addon_type: "نوع الاضافة",
  additional_price: "السعر الاضافي",

  // discount modal
  cart_discount: "خصم علي العربة",
  item_discount: "خصم علي العناصر",
  apply_discount_to: "تطبيق الخصم علي",
  no_item_selected_msg: "قم باختيار عنصر اولاً لتطبيق الخصم علية",
  enter_discount_placeholder: "ادخل قيمة الخصم",

  // split payments
  payment_require_customer_error: "نوع الدفع يتطلب ان تختار عميل اولاً",

  // sell screen invoices
  invoice_type: "نوع الفواتير",
  all: "الكل",
  paid: "مدفوعة",
  refund: "مرتجع",

  order_by: "ترتيب بواسطة",
  last: "الاخير",
  first: "الاول",
};
