export default [
  {
    path: "/user_profile",
    name: "user_profile",
    component: () => import("@/views/UserProfile"),
    meta: {
      title: () => {
        return "OnePos | User Profile";
      },
    },
  },
];
