export default {
  // navbar buttons
  create_new_csid: "إنشاء شهادة جديدة",
  renew_csid: "تجديد الشهادة",

  // new_csid_layout
  already_have_csid_msg: "لقد إنشئت شهادة ختم تشفير بالفعل", // valid_csid
  create_new_csid_button: "إنشاء شهادة جديدة",

  // renew_csid_layout
  create_csid_msg: "يجب عليك إنشاء شهادة ختم التشفير CSID اولاً قبل التجديد",
  renew_csid_button: "تجديد الشهادة",
};
