const state = {
  navbar_content: {
    // "html" OR "component"
    type: "",
    // raw html or component
    content: "",
  },
};

const actions = {
  set_navbar_content({ commit }, payload) {
    commit("set_navbar_content", payload);
  },
};

const mutations = {
  set_navbar_content(state, payload) {
    state.navbar_content = payload;
  },
};

const getters = {
  get_navbar_content: () => {
    return state.navbar_content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
