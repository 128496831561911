import DamagedProducts from "@/views/management/reports/Damage/DamagedProducts";

export default [
  {
    path: "/management/reports/damaged_products/",
    component: DamagedProducts,
    name: "damaged_products",
    meta: {
      title: () => {
        return "OnePos | Purchase Damaged Products";
      },
    },
  },
];
