// import new_product from "./new_product/new_product_en";

export default {
  //quantities in the stock
  quantities_in_the_stock: "Quantities in the stock",
  negative_quantity: "Negative Quantity",
  zero_quantity: "Zero Quantity",
  non_zero_quantity: "Non Zero Quantity",
  // quantities in the stock -> squares title
  items_with_zero_quantity: "Items With Zero Quantity",
  items_with_quantity_greater_than_zero:
    "Items with Quantity is Greater than Zero",

  // inventory count
  inventory_count: "Inventory Count",
  new_inventory_count: "New Inventory Count",
  status: "Status",
  approve: "Approve",
  approved: "Approved",
  accepted: "Accepted",
  pending: "Pending",
  decline: "Decline",
  declined: "Declined",
  add_new_inventory_count: "Add New Inventory Count",

  // display count
  inventory_count_number: "Inventory Count ID",
  inventory_damage_number: "Inventory Damage ID",

  counted_qty: "Counted Quantity",
  expected_qty: "Expected Quantity",
  increase_qty: "Increase Quantity",
  shortage_qty: "Shortage Quantity",
  shortage_price: "Shortage Price",

  // inventory Damage
  inventory_damage: "Inventory Damage",
  new_inventory_damage: "New Inventory Damage",
  add_new_inventory_damage: "Add New Inventory Damage",
  damaged: "Damaged",
  damaged_price: "Damaged total",

  //transfer
  transfer: "Transfer",
  inventory_transfer: "Inventory Transfer",
  report: "Report",
  new_transfer_operation: "New Transfer Operation",
  from_product: "From Product",
  to_product: "To Product",
  notes: "Notes",
  no_notes: "There is No Notes",
  from_branch: "From Branch",
  to_branch: "To Branch",
  select_a_branch: "Select a branch",

  product_want_move_to: "Type to search product you want to move from ...",
  adding_notes: "Add Notes to this operation",
};
