import PriceTags from "@/views/management/products/price_tags/PriceTags";

export default [
  {
    path: "/management/price_tags",
    name: "price_tags",
    component: PriceTags,
    meta: {
      title: () => {
        return "OnePos | Price Tags";
      },
    },
  },
];
