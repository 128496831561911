import ProductMain from "@/views/management/products/ProductMain";

import groups_routes from "@/router/routes/management/products/groups/groups_routes";
import Product_routes from "@/router/routes/management/products/product/product_routes";
import Addons_routes from "@/router/routes/management/products/addons/addons_routes";
import Price_tags_routes from "@/router/routes/management/products/price_tags/price_tags_routes";
import Item_box_routes from "@/router/routes/management/products/item_box/item_box_routes";

export default [
  {
    path: "/management/products",
    name: "management_products",
    component: ProductMain,
    meta: {
      title: () => {
        return "OnePos | Products";
      },
    },
  },
  ...groups_routes,
  ...Product_routes,
  ...Addons_routes,
  ...Price_tags_routes,
  ...Item_box_routes,
];
