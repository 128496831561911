function animated_dismiss_alert(e) {
  setTimeout(() => {
    e.classList.add("fade-out-right");
    setTimeout(() => {
      e.remove();
    }, 500);
  }, 3000);
}

const state = {
  requested_url: null,
  alert_dom: localStorage.getItem("alert_dom") || null,
  is_forbidden: false,
  forbidden_errors: [],
};

const actions = {
  change_is_forbidden({ commit }, bool) {
    commit("change_is_forbidden", bool);
  },
  append_forbidden_error({ commit }, message) {
    commit("append_forbidden_error", message);
  },
  reset_forbidden_errors({ commit }, message) {
    commit("reset_forbidden_errors", message);
  },
  save_requested_url({ commit }, url) {
    commit("save_requested_url", url);
  },
  clear_requested_url({ commit }) {
    commit("clear_requested_url");
  },
  alert_dom({ commit }, dom) {
    commit("set_alert_dom", dom);
  },
  push_alert({ commit }, payload) {
    commit("push_alert", payload);
  },
};

const mutations = {
  change_is_forbidden(state, bool) {
    state.is_forbidden = bool;
  },
  append_forbidden_error(state, error_message) {
    state.forbidden_errors.push(error_message);
  },
  reset_forbidden_errors(state) {
    state.forbidden_errors = [];
  },
  save_requested_url(state, url) {
    state.requested_url = url;
  },
  clear_requested_url(state) {
    state.requested_url = null;
  },
  set_alert_dom: (state, dom) => {
    state.alert_dom = dom;
  },
  push_alert: (state, payload) => {
    const alert = (message, type) => {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");
      state.alert_dom.append(wrapper);
      animated_dismiss_alert(wrapper);
    };
    alert(payload.message, payload.type);
  },
};

const getters = {
  is_forbidden: (state) => {
    return state.is_forbidden;
  },
  get_forbidden_errors: (state) => {
    return state.forbidden_errors;
  },
  requested_url: (state) => {
    return state.requested_url;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
