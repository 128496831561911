import BranchesMain from "@/views/management/branches/BranchesMain";

export default [
  {
    path: "/management/branches/",
    name: "branches",
    component: BranchesMain,
    meta: {
      title: () => {
        return `OnePos | Branches`;
      },
    },
  },
];
