import MainView from "@/views/management/users_and_secuirty/MainView";

import Users_routes from "@/router/routes/management/users_and_security/users/users_routes";
import Permission_routes from "@/router/routes/management/users_and_security/permissions/permission_routes";

export default [
  {
    path: "/management/users_security",
    name: "users_security",
    component: MainView,
    children: [...Users_routes, ...Permission_routes],
  },
];
