export default [
  {
    path: "/management/products/groups",
    name: "groups_management",
    component: () => import("@/views/management/products/groups/GroupsMain"),
    meta: {
      title: () => {
        return "OnePos | Manage Groups";
      },
    },
  },
];
