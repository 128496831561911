<template>
  <div class="inventory full-height">
    <div class="d-flex flex-column flex-md-row gap-3 full-height">
      <div class="layout-sidebar col-12 col-md-3">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            {
              name: $t('sidebar.inventory'),
              route: '',
            },
          ]"
          class="pb-2"
        />
        <div class="layout-menu-buttons d-flex flex-wrap gap-3">
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'stock' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("inventory.quantities_in_the_stock") }}
            </a>
          </router-link>

          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'inventory_count' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("inventory.inventory_count") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'inventory_damage' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("inventory.inventory_damage") }}
            </a>
          </router-link>
          <router-link
            custom
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'inventory_transfer' }"
            class="col col-md-12"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[isActive && 'section-active']"
            >
              {{ $t("inventory.transfer") }}
            </a>
          </router-link>
        </div>
      </div>
      <div class="content-wrapper col">
        <div class="router-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "InventoryMain",
  components: { section_route },
};
</script>

<style lang="scss" scoped></style>
