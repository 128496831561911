<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.purchase_products'), route: 'purchase_by_product' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="purchases.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="purchases.length > 0" class="table-wrapper p-3">
        <table class="table table-striped responsive-table text-center">
          <thead class="sticky-table-header">
            <tr>
              <th>ID</th>
              <th>{{ $t("common.product_name") }}</th>
              <th>{{ $t("common.quantity") }}</th>
              <th>{{ $t("common.total_before_tax") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(purchase, index) of purchases" :key="index">
              <td data-label="ID">{{ purchase.product_id }}</td>
              <td :data-label="$t('common.product_name')">
                {{ purchase.product }}
              </td>
              <td :data-label="$t('common.quantity')">
                {{ purchase.quantity }}
              </td>
              <td :data-label="$t('common.total_before_tax')">
                {{ purchase.subtotal }}
              </td>
              <td :data-label="$t('common.total')">{{ purchase.total }}</td>
            </tr>
            <tr v-if="meta_data.meta_data">
              <!-- SKIP product_id, product, quantity columns-->
              <td
                class="d-none d-sm-table-cell"
                v-for="index in 3"
                :key="index"
              ></td>
              <td
                :data-label="$t('common.total_before_tax')"
                class="text-danger"
              >
                {{ meta_data.meta_data.subtotal }}
              </td>
              <td :data-label="$t('common.total')" class="text-danger">
                {{ meta_data.meta_data.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PurchaseByProduct",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/purchase_by_product/`,
      purchases: [],
      meta_data: {},
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.meta_data = response.data.pop();
          this.purchases = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching purchase_by_product : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "purchase_by_product.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 60rem;
  max-height: 60rem;
  overflow: scroll;
}
</style>
