<template>
  <div class="payment-types">
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="add-payment-modal"
      tabindex="-1"
      aria-labelledby="addPaymentModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-update-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-header row">
            <h5
              class="modal-title text-start col-md-8 text-danger"
              id="addPaymentModalLabel"
            >
              {{ $t("settings.add_new_payment") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="row mt-2" style="gap: 2rem">
              <div class="row col-md-12">
                <p class="col-md-3">{{ $t("common.name") }}</p>
                <div class="col-md-9 g-0">
                  <input
                    v-model="selected_payment_form.name"
                    type="text"
                    class="pos-input w-100"
                  />
                </div>
              </div>
              <div class="row col-md-12 justify-content-between">
                <p class="col-md-3">{{ $t("common.enabled") }}</p>
                <div class="col-md-8 g-0">
                  <input
                    v-model="selected_payment_form.enabled"
                    type="checkbox"
                    style="transform: scale(2)"
                  />
                </div>
              </div>
              <div class="row col-md-12 justify-content-between">
                <p class="col-md-3">{{ $t("settings.customer_required") }}</p>
                <div class="col-md-8 g-0">
                  <input
                    v-model="selected_payment_form.customer_required"
                    type="checkbox"
                    style="transform: scale(2)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <button
              @click="reset_modals"
              ref="edit-close-modal-btn"
              type="button"
              class="btn btn-secondary px-5"
              data-bs-dismiss="modal"
            >
              {{ $t("common.cancel") }}
            </button>
            <button
              @click="new_payment"
              type="button"
              class="pos-primary-btn py-2 px-5"
            >
              {{ $t("common.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Add Modal -->

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="edit-payment-modal"
      tabindex="-2"
      aria-labelledby="editPaymentModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            type="button"
            class="d-none btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-header row">
            <h5
              class="modal-title text-start col-md-8 text-danger"
              id="editPaymentModalLabel"
            >
              {{ $t("common.edit") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="row mt-2" style="gap: 2rem">
              <div class="row col-md-12">
                <p class="col-md-3">{{ $t("common.name") }}</p>
                <div class="col-md-9 g-0">
                  <input
                    v-model="selected_payment_form.name"
                    type="text"
                    class="pos-input w-100"
                  />
                </div>
              </div>
              <div class="row col-md-12 justify-content-between">
                <p class="col-md-3">{{ $t("common.enabled") }}</p>
                <div class="col-md-8 g-0">
                  <input
                    v-model="selected_payment_form.enabled"
                    type="checkbox"
                    style="transform: scale(2)"
                  />
                </div>
              </div>
              <div class="row col-md-12 justify-content-between">
                <p class="col-md-3">{{ $t("settings.customer_required") }}</p>
                <div class="col-md-8 g-0">
                  <input
                    v-model="selected_payment_form.customer_required"
                    type="checkbox"
                    style="transform: scale(2)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <button
              @click="update_payment"
              ref="close-group-modal-btn"
              type="button"
              class="btn btn-secondary px-5"
              data-bs-dismiss="modal"
            >
              {{ $t("common.save") }}
            </button>
            <button
              @click="reset_modals"
              type="button"
              class="pos-primary-btn py-2 px-5"
            >
              {{ $t("common.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Edit Modal -->

    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="del-modal"
      tabindex="-3"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span style="font-size: 1.2rem">
              {{ selected_payment.name }} ?</span
            >
          </div>
          <div class="modal-footer">
            <button @click="delete_payments" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <button
      id="del-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#delmodal"
    ></button>
    <!-- End of ConfirmDelete Modal -->

    <div class="sec-name d-flex flex-wrap justify-content-between mb-3">
      <p>{{ $t("settings.payment_types") }}</p>
      <button
        class="pos-primary-btn px-5"
        id="add-payment-modal-btn"
        data-bs-toggle="modal"
        data-bs-target="#add-payment-modal"
      >
        {{ $t("common.add") }}
      </button>
    </div>
    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th>{{ $t("common.name") }}</th>
            <th>{{ $t("common.enabled") }}</th>
            <th>{{ $t("settings.customer_required") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in payments" :key="payment.id">
            <td>{{ payment.name }}</td>
            <td>
              <input type="checkbox" :checked="payment.enabled" disabled />
            </td>
            <td>
              <input
                type="checkbox"
                :checked="payment.customer_required"
                disabled
              />
            </td>
            <td class="d-flex gap-3 justify-content-center">
              <img
                @click="set_selected_payment(payment)"
                data-bs-toggle="modal"
                data-bs-target="#edit-payment-modal"
                :src="$get_icon('edit')"
                class="btn btn-success"
                alt=""
              />
              <img
                @click="set_selected_payment(payment)"
                data-bs-toggle="modal"
                data-bs-target="#del-modal"
                :src="$get_icon('delete')"
                class="btn btn-danger"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- /.primary-table -->
    </div>
    <!-- /.table-wrapper -->
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";

export default {
  name: "PaymentTypes",
  data() {
    return {
      selected_payment: {},
      selected_payment_form: {},
      payments: [],
    };
  },
  methods: {
    close_current_modal: close_current_modal,
    get_payments() {
      this.$http
        .get(`/payments/get_payments/`)
        .then((response) => {
          this.payments = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching payment types ! : ${e}`);
        });
    },
    set_selected_payment(payment) {
      this.selected_payment = Object.assign({}, payment);
      this.selected_payment_form = Object.assign({}, payment);
    },
    new_payment() {
      const body_form_data = new FormData();
      body_form_data.append("name", this.selected_payment_form.name);
      body_form_data.append("enabled", this.selected_payment_form.enabled);
      body_form_data.append(
        "customer_required",
        this.selected_payment_form.customer_required
      );
      this.$isLoading(true);
      this.$http({
        method: "POST",
        url: "/payments/new_payment",
        data: body_form_data,
      })
        .then((response) => {
          this.payments.push(response.data);
          this.$store.dispatch("utils/push_alert", {
            message: "New Payment Added Successfully",
            type: "success",
          });
        })
        .catch((errors) => {
          console.log("errors: ", errors);
          const errors_data = errors.response.data;

          for (let key in errors_data) {
            this.$store.dispatch("utils/push_alert", {
              message: `${key}: ${errors_data[key][0]}`,
              type: "danger",
            });
          }

          console.log(
            "response error while trying to create new payment: ",
            errors.response
          );
        })
        .finally(() => {
          this.$isLoading(false);
          this.close_current_modal();
          this.reset_modals();
        });
    },
    update_payment() {
      const body_form_data = new FormData();
      body_form_data.append("name", this.selected_payment_form.name);
      body_form_data.append("enabled", this.selected_payment_form.enabled);
      body_form_data.append(
        "customer_required",
        this.selected_payment_form.customer_required
      );
      this.$isLoading(true);
      this.$http({
        method: "PUT",
        url: `/payments/update_payment/${this.selected_payment_form.id}`,
        data: body_form_data,
      })
        .then((response) => {
          const payment_index = this.payments.findIndex(
            (e) => e.id === this.selected_payment_form.id
          );
          this.payments[payment_index] = response.data;
          this.$store.dispatch("utils/push_alert", {
            message: "Payment Updated Successfully",
            type: "success",
          });
        })
        .catch((errors) => {
          const errors_data = errors.response.data;

          for (let key in errors_data) {
            this.$store.dispatch("utils/push_alert", {
              message: `${key}: ${errors_data[key][0]}`,
              type: "danger",
            });
          }
          console.log(
            "response error while trying to create new payment: ",
            errors.response
          );
        })
        .finally(() => {
          this.$isLoading(false);
          this.reset_modals();
        });
    },
    delete_payments() {
      this.$isLoading(true);
      this.$http({
        method: "DELETE",
        url: "/payments/delete_payments/",
        data: [this.selected_payment.id],
      })
        .then(() => {
          this.get_payments();
          this.close_current_modal();
          this.$store.dispatch("utils/push_alert", {
            message: "Payment Deleted Successfully",
            type: "warning",
          });
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: e.response.data,
            type: "danger",
          });
          console.log(`Error while deleting payments ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
          this.reset_modals();
        });
    },
    reset_modals() {
      const default_payments = {
        id: null,
        name: "",
        enabled: true,
        customer_required: false,
      };
      this.selected_payment_form = Object.assign({}, default_payments);
      this.selected_payment = Object.assign({}, default_payments);
    },
  },
  mounted() {
    this.get_payments();
    this.reset_modals();
  },
};
</script>

<style lang="scss" scoped>
.payment-types {
  width: 100%;
  height: 100%;
  .table-wrapper {
    min-height: 76%;
    max-height: 76%;
    overflow: auto;
  }
  input[type="checkbox"] {
    transform: scale(1.5);
  }
  .buttons {
    width: 60%;
    margin: 3rem auto;
    display: flex;
    gap: 1rem;
    button {
      padding: 1rem;
      flex: 1;
    }
  }
}
</style>
