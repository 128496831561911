<template>
  <div class="transfer-display">
    <!-- Update product modal -->
    <div
      class="modal fade"
      id="update-item-modal"
      tabindex="-1"
      aria-labelledby="updateItemModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <h4 class="modal-title w-100">
              {{ $t("common.quantity") }}

              <span
                v-if="current_transfer_item.name"
                class="text-success"
                style="font-size: 1.7rem"
              >
                {{ $t("common.to") }}
                {{ current_transfer_item.name }}
              </span>
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <input
              v-model="current_transfer_item.quantity"
              type="text"
              pattern="[0-9]+(\.[0-9]+)?"
              class="form-control"
              style="padding: 0.6rem"
            />
          </div>
          <div class="modal-footer">
            <button
              @click="update_product"
              class="btn btn-outline-warning px-5"
            >
              {{ $t("common.edit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end of Update modal -->

    <!--    DELETE PRODUCT CONFIRMATION MODAL -->
    <div class="modal fade" id="del-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              <span style="font-size: 1.2rem">
                {{ current_transfer_item.from_product.name }} ->
                {{ current_transfer_item.to_product.name }}
              </span>
            </span>
          </div>

          <div class="modal-footer">
            <button @click="delete_product" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!--    END OF DELETE CONFIRMATION  MODAL-->

    <div class="sec-name">
      <span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.4999 12H3.66992"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <span
        @click="$router.push({ name: 'inventory_transfer' })"
        class="ms-1 fade-route"
      >
        {{ $t("inventory.inventory_transfer") }}
      </span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
          stroke="#90A3BF"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>
        {{ $t("inventory.report") }} {{ this.$route.params.report_id }}</span
      >
    </div>

    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("inventory.from_product") }}</th>
            <th>{{ $t("inventory.to_product") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th v-if="report.status === 'pending'"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="transfer_item in report.stock_transfer_items"
            :key="transfer_item.id"
          >
            <td>{{ transfer_item.id }}</td>
            <td>{{ transfer_item.from_product.name }}</td>
            <td>{{ transfer_item.to_product.name }}</td>

            <td>{{ transfer_item.quantity }}</td>
            <td v-if="report.status === 'pending'" style="width: 10%">
              <div class="d-flex justify-content-between">
                <button
                  @click="
                    set_current_transfer_item(transfer_item.id);
                    open_modal('update-item-modal');
                  "
                  class="btn btn-success mx-1"
                >
                  <img :src="$get_icon('edit')" alt="" />
                </button>
                <button
                  @click="set_current_transfer_item(transfer_item.id)"
                  data-bs-toggle="modal"
                  data-bs-target="#del-modal"
                  class="btn btn-danger mx-1"
                >
                  <img :src="$get_icon('delete')" alt="" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between">
      <div class="col-md-6 row">
        <h3 class="text-danger col-md-12">{{ $t("inventory.notes") }} :</h3>
        <h5>{{ report.notes || $t("inventory.no_notes") }}</h5>
      </div>
      <div class="col-md-4">
        <div class="info-text">
          <span class="desc"> {{ $t("common.from") }} : </span>
          <span class="num">{{ report.from_stock }}</span>
          <!-- /.desc -->
        </div>
        <!-- /.info-text -->
        <div class="info-text">
          <span class="desc"> {{ $t("common.to") }} : </span>
          <span class="num">{{ report.to_stock }}</span>
          <!-- /.desc -->
        </div>
        <!-- /.info-text -->
        <div class="info-text">
          <span class="desc"> {{ $t("common.user") }} : </span>
          <span class="num">{{ report.created_by }}</span>
          <!-- /.desc -->
        </div>
        <!-- /.info-text -->
        <div class="info-text">
          <span class="desc"> {{ $t("common.date") }} : </span>
          <span class="num">{{ report.timestamp | localize_date }}</span>
          <!-- /.desc -->
        </div>
        <!-- /.info-text -->
      </div>
    </div>

    <div class="buttons">
      <div class="left"></div>
      <!-- /.left -->
      <div class="right" v-if="report.status === 'pending'">
        <button @click="update_status('disapprove')" class="btn btn-danger">
          {{ $t("inventory.decline") }}
        </button>
        <button @click="update_status('approve')" class="pos-primary-btn">
          {{ $t("inventory.approve") }}
        </button>
        <!-- /.pos-primary-btn -->
      </div>
      <!-- /.right -->
    </div>
    <!-- /.buttons -->
  </div>
</template>

<script>
import { close_current_modal, open_modal } from "@/helpers";

export default {
  name: "DisplayInventoryTransfer",
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  | ${datetime.toLocaleTimeString()}
      `;
    },
  },
  data() {
    return {
      report: {
        stock_transfer_items: [
          {
            id: null,
            from_product: {
              name: null,
            },
            to_product: {
              name: null,
            },
            quantity: null,
            stock_transfer: null,
          },
        ],
      },
      current_transfer_item: {
        from_product: {
          name: "",
        },
        to_product: {
          name: "",
        },
      },
    };
  },
  methods: {
    open_modal,
    close_current_modal,
    get_report() {
      this.$http
        .get(`/inventory/stock_transfer/${this.$route.params.report_id}`)
        .then((response) => {
          this.report = response.data;
        });
    },
    set_current_transfer_item(transfer_item_id) {
      this.current_transfer_item = Object.assign(
        {},
        this.report.stock_transfer_items.find(
          (item) => item.id === transfer_item_id
        )
      );
    },
    update_status(status) {
      this.$http
        .post("/inventory/update_stock_transfer_status", {
          pk: this.$route.params.report_id,
          status: status,
          items: this.report.stock_transfer_items,
        })
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: `Transfer Order ${status} Successfully.`,
            type: "success",
          });
          this.$router.push({ name: "inventory_transfer" });
        })
        .catch((e) => {
          const errors_data = e.response.data;
          for (let key in errors_data) {
            this.$store.dispatch("utils/push_alert", {
              message: `${key}: ${errors_data[key][0]}`,
              type: "danger",
            });
          }
          console.log("Error while trying update ", e);
        });
    },
    update_product() {
      const index = this.report.stock_transfer_items.findIndex(
        (p) => p.id === this.current_transfer_item.id
      );
      if (index >= 0) {
        this.report.stock_transfer_items.splice(
          index,
          1,
          this.current_transfer_item
        );
      }
      close_current_modal();
    },
    delete_product() {
      this.report.stock_transfer_items =
        this.report.stock_transfer_items.filter(
          (p) => p.id !== this.current_transfer_item.id
        );
      this.close_current_modal();
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style lang="scss" scoped>
.transfer-display {
  width: 100%;
  height: 100%;
  .table-wrapper {
    min-height: 75%;
    max-height: 75%;
    overflow: auto;
  }

  input[type="checkbox"] {
    transform: scale(1.6);
  }
  .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    .right,
    .left {
      display: flex;
      gap: 1.5rem;
      button {
        border-radius: 5px;
        padding: 0.5rem 1.5rem;
      }
    }
  }
}
</style>
