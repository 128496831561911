export default {
  counted_items: "Counted Items",
  dashboard: "Dashboard",
  inventory_count: "Inventory Count",
  orders: "Orders",
  orders_count: "Orders Count",
  orders_per_hour: "Orders Per Hour",
  purchase: "Purchase",
  refund_total: "Refund Total",
  refunds: "Refunds",
  refunds_count: "Refunds Count",
  sales: "Sales",
  top_cashiers: "Top Cashiers",
  top_cashiers_make_refunds: "Top Cashiers Who Make Refunds",
  top_customers: "Top Customers",
  top_payment_types: "Top Payment Types",
  top_product_groups: "Top Product Groups",
  top_products: "Top Products",
  total_refunds: "Total Refunds",
  total_sales: "Total Sales",
  total_value: "Total Value",
};
