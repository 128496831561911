function reset_total_to_zero(self) {
  // checks if discount or tax is less than zero then returns all calculate to 0
  if (
    self.counted_total < 0 ||
    isNaN(parseFloat(self.counted_total)) ||
    self.counted_subtotal < 0 ||
    isNaN(parseFloat(self.counted_subtotal)) ||
    self.counted_tax < 0 ||
    isNaN(parseFloat(self.counted_tax))
  ) {
    self.counted_total = 0;
    self.counted_subtotal = 0;
    self.counted_tax = 0;
    self.total_discount_input = 0;
  }
}

export default reset_total_to_zero;
