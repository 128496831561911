import zatca_info from "./zatca_info/ar";
import zatca_csid from "./zatca_csid/ar";

export default {
  // zatca main page sidebar
  add_zatca_info: "إضافة بيانات الهيئة",
  csid: "شهادة ختم التشفير CSID",
  ...zatca_info,
  ...zatca_csid,
};
