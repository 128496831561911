<template>
  <Doughnut :options="chartOptions" :data="chartData" />
</template>
<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "top_products_doughnut",
  props: ["products_data"],
  components: {
    Doughnut,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              "rgba(255,99,132,1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(100,170,62,1)",
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(79,239,23,0.2)",
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    load_products_chart() {
      if (this.products_data.length > 0) {
        for (let i = 0; i < this.products_data.length; i++) {
          this.chartData.labels.push(this.products_data[i].product_name);
          this.chartData.datasets[0].data.push(this.products_data[i].sales);
        }
      } else {
        this.chartData.datasets[0].data.push(1);
      }
    },
  },
  created() {
    this.load_products_chart();
  },
};
</script>
