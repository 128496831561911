export default [
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/auth/JoinView"),
    meta: {
      title: () => {
        return "OnePos | Join";
      },
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/JoinView"),
    meta: {
      title: () => {
        return `OnePos | Login`;
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/JoinView"),
    meta: {
      title: () => {
        return `OnePos | Register`;
      },
    },
  },
  {
    path: "/activate/:pk/:token/",
    name: "activate",
    component: () => import("@/views/auth/VerificationView"),
    meta: {
      title: () => {
        return `OnePos | Email Verification`;
      },
    },
  },
  {
    path: "/reset_password/:pk/:token",
    name: "reset_password",
    component: () => import("@/views/auth/ResetPasswordView"),
    meta: {
      title: () => {
        return `OnePos | Reset Password`;
      },
    },
  },
  {
    path: "/request_new_password",
    name: "request_new_password",
    component: () => import("@/views/auth/RequestNewPassword"),
    meta: {
      title: () => {
        return `OnePos | Forget Password`;
      },
    },
  },
];
