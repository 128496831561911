<template>
  <div class="purchase-display pb-5">
    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              <span style="font-size: 1.2rem">
                {{ $t("common.invoice") }}
                {{ this.$route.params.report_id }},
              </span>
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_purchase" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!-- End of ConfirmDelete Modal -->
    <div class="sec-name">
      <span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.4999 12H3.66992"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <span
        v-if="$route.name === `purchase_details`"
        @click="$router.push({ name: 'all_purchases' })"
        class="ms-1 fade-route"
      >
        {{ $t("sidebar.purchases") }}
      </span>
      <span
        v-else
        @click="$router.push({ name: 'purchases_refund' })"
        class="ms-1 fade-route"
      >
        {{ $t("purchase.purchase_refund") }}
      </span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
          stroke="#90A3BF"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span> {{ this.$route.params.report_id }}</span>
    </div>
    <div class="d-flex justify-content-end control-btns">
      <button @click="print_invoice" class="btn btn-primary mx-1">
        <img :src="$get_icon('print')" alt="" />
      </button>

      <a
        :href="
          $get_route_url(
            $route.name === `purchase_details`
              ? 'purchases_update'
              : 'purchases_refund_update',
            { id: $route.params.report_id }
          )
        "
        class="btn btn-success mx-1"
      >
        <img :src="$get_icon('edit')" alt="" />
      </a>

      <button
        data-bs-toggle="modal"
        data-bs-target="#delmodal"
        class="btn btn-danger mx-1"
      >
        <img :src="$get_icon('delete')" alt="" />
      </button>
    </div>

    <!-- Invoice Info -->
    <div
      class="row justify-content-between"
      style="font-size: 1.2rem; margin-top: 3rem"
    >
      <div class="row col-md-8">
        <div class="row col-md-12">
          <div class="col-md-3">{{ $t("common.invoice_no") }} :</div>
          <div class="col-md-1">{{ info.id }}</div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-3">{{ $t("common.date") }} :</div>
          <div class="col-md-1">
            {{ new Date(info.date).toLocaleDateString() }}
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-3">{{ $t("common.created_by") }} :</div>
          <div class="col-md-5">{{ info.created_by }}</div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-3">{{ $t("common.last_modified_by") }} :</div>
          <div class="col-md-1">{{ info.last_modified_by || "-" }}</div>
        </div>
      </div>
      <div class="row col-md-3">
        <div class="row">
          <div class="col-md-6">{{ $t("common.supplier") }} :</div>
          <div class="col-md-4">
            {{ info.supplier || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">{{ $t("common.ref_number") }}:</div>
          <div class="col-md-4">
            {{ info.external_id || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Paid :</div>
          <div class="col-md-4">
            <input type="checkbox" :checked="info.paid" disabled />
          </div>
        </div>
      </div>
    </div>
    <!--    End of Invoice Info-->

    <div style="padding: 1.35rem 0 3rem 0">
      <hr />
    </div>

    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("common.name") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th>{{ $t("common.price_before_tax") }}</th>
            <th>{{ $t("common.tax") }}</th>
            <th>{{ $t("common.price") }}</th>
            <th>{{ $t("common.discount") }}</th>
            <th>{{ $t("common.total_before_tax") }}</th>
            <th>{{ $t("common.total") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.product }}</td>
            <td>{{ product.quantity }}</td>

            <td>{{ product.subtotal }}</td>

            <td v-if="product.tax">
              <span v-for="t in product.tax" :key="t.id"
                >{{ t.percentage_value }}%</span
              >
            </td>
            <td v-else></td>
            <td>{{ product.price_with_tax }}</td>

            <td>{{ product.discount }}</td>
            <td>{{ product.subtotal }}</td>
            <td>
              {{ product.total }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="total-info w-25">
      <div class="row">
        <div class="col-md-6">{{ $t("common.total_before_tax") }} :</div>
        <div class="col-md-6">{{ info.subtotal }}</div>
      </div>
      <div class="row">
        <div class="col-md-6">{{ $t("common.taxes") }}</div>
        <div class="col-md-6">{{ info.tax_total }}</div>
      </div>
      <div class="row">
        <div class="col-md-6">{{ $t("common.discount") }}</div>
        <div class="col-md-6">{{ info.discount }}{{ info.discount_type }}</div>
      </div>
      <div class="row">
        <div class="col-md-6">{{ $t("common.total") }} :</div>
        <div class="col-md-6">{{ info.total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { close_current_modal } from "@/helpers";

export default {
  name: "PurchaseDetails",
  components: {},
  data() {
    return {
      info: [],
      products: [],
      pagination: {},
    };
  },
  methods: {
    close_current_modal,
    get_details() {
      this.$http
        .get(`/purchases/purchases_details/${this.$route.params.report_id}`)
        .then((response) => {
          this.info = response.data.results.info;
          this.products = response.data.results.products;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(
            `Error while fetching purchase report ${this.$route.params.report_id} : ${e}`
          );
        });
    },
    delete_purchase() {
      this.$http({
        method: "DELETE",
        url: "/purchases/purchases",
        data: { id: this.$route.params.report_id },
      })
        .then(() => {
          this.close_current_modal();
          this.$store.dispatch("utils/push_alert", {
            message: `Purchase Deleted Successfully`,
            type: "success",
          });
          this.$router.push({ name: "all_purchases" });
        })
        .catch((e) => {
          console.log(`Error while deleting Purchases ! : ${e}`);
        });
    },
    print_invoice() {
      const purchase =
        document.querySelectorAll(".purchase-display")[0].innerHTML;
      const style_sheets = document.querySelectorAll(
        'link[rel="stylesheet"], style'
      );

      // Get all stylesheets HTML
      const stylesHtml = [...style_sheets]
        .map((node) => node.outerHTML)
        .join("");

      // Open the print window
      const print_win = window.open(
        "",
        `Purchase ${this.$route.params.report_id}`
      );
      print_win.document.write(`
        <!DOCTYPE html>
             <html lang="en">
                <head>
                    <title>Purchase ${this.$route.params.report_id}</title>
                    ${stylesHtml}
                </head>
          <!-- closes the page if user closed the print dialog-->
          <body onload="setTimeout(window.close)">
            <!-- Note: check changes in this class in purchase_print_media.scss -->
             <div class="purchase-display ">${purchase}</div>
          </body>
        </html>`);
      print_win.document.close();
      print_win.focus();
      print_win.print();
    },
  },
  mounted() {
    this.get_details();
  },
};
</script>

<style lang="scss" scoped>
.purchase-display {
  position: relative;
  width: 100%;
  height: 100%;

  .table-wrapper {
    //min-height: 75%;
    //max-height: 75%;
    overflow: auto;
  }
  .total-info {
    margin-top: 1rem;
    font-size: 1.25rem;
    .row {
      border-bottom: 2px solid rgba(206, 14, 14, 0.36);
      padding: 0.25rem;
    }
  }
}
@import "src/scss/settings/purchases/purchase_print_media";
</style>
