import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Auth_routes from "@/router/routes/auth/auth_routes";
import Sell_screen_routes from "@/router/routes/sell_screen/sell_screen_routes";
import User_profile_routes from "@/router/routes/user_profile/user_profile_routes";
import Sales_history_routes from "@/router/routes/sales_history/sales_history_routes";
import Management_routes from "@/router/routes/management/management_routes";
import Zatca_routes from "@/router/routes/zatca/zatca_routes";

// 404 page
import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter);

const routes = [
  ...Auth_routes,
  ...User_profile_routes,
  ...Sell_screen_routes,
  ...Sales_history_routes,
  ...Management_routes,
  ...Zatca_routes,
  {
    path: "/",
    name: "home",
    component: () => import("@/views/management/ManagementView"),
    meta: {
      title: () => {
        return "OnePos | Management";
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  base: "/",
  routes: [...routes, { path: "*", component: PageNotFound }],
});

function toggle_sidebar_padding(to) {
  if (to.name === "login" || to.name === "register") {
    document.getElementById("app").classList.add("p-0");
  } else {
    document.getElementById("app").classList.remove("p-0");
  }
}

router.beforeEach((to, from, next) => {
  // todo: check to.meta.requiresAuth
  if (!store.getters["auth/is_logged_in"]) {
    if (
      to.path === "/login" ||
      to.path === "/register" ||
      to.path === "/join" ||
      to.name === "activate" ||
      to.name === "reset_password" ||
      to.name === "request_new_password"
    ) {
      // Prevent infinite loop by checking if already on the login route
      next();
    } else {
      // Save the requested URL in Vuex store or localStorage
      store.dispatch("utils/save_requested_url", to.path);
      // Redirect the user to the login page
      next("/join");
    }
  } else {
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to);
    toggle_sidebar_padding(to);
  });
});

export default router;
