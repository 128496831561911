<template>
  <div class="modal fade" :id="modal_id" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="border: none">
          <h4 class="modal-title w-100">
            <span
              v-if="selected_item"
              class="text-success"
              style="font-size: 1.7rem"
            >
              {{ selected_item.name }}
            </span>
          </h4>

          <button
            ref="qty-close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <slot name="inside-modal-body"></slot>

          <slot name="keypad">
            <!-- KEYPAD COMPONENT -->
            <keypad class="mt-4" :enter="set_product"></keypad>
            <!-- KEYPAD COMPONENT END -->
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import keypad from "@/components/keyboards/main_keypad";

export default {
  name: "purchase_modal",
  props: ["modal_id", "selected_item", "set_product"],

  components: {
    keypad,
  },
};
</script>

<style scoped></style>
