export default {
  // forbidden
  forbidden: "محظور",
  forbidden_desc: "ليس لديك صلاحية للوصول الي هذه الصفحة",
  save: "حفظ",
  search: "بحث",
  details: "تفاصيل",
  name: "الاسم",
  type: "النوع",
  promo: "عرض",
  branch: "الفرع",

  // crud
  add: "اضافة",
  edit: "تعديل",
  delete: "حذف",
  cancel: "إلغاء",
  control: "تحكم",
  active: "نشط",
  new: "جديد",
  add_new: "اضافة جديد",
  remove: "إزالة",
  chose: "اختر",

  // date
  from: "من",
  to: "الي",
  date: "التاريخ",
  date_time: "الوقت / التاريخ",

  // users
  user: "المستخدم",
  users: "المستخدمين",
  last_modified_by: "اخر تعديل بواسطة",
  last_modified: "اخر تعديل ",

  last_visit: "اخر زيارة",
  cashier: "الكاشير",
  client: "العميل",
  customers: "العملاء",
  customer: "العميل",
  supplier: "المورد",
  suppliers: "الموردين",
  customer_or_supplier: "عميل او مورد",
  created_by: "إنشئت بواسطة",

  // user info
  first_name: "الإسم الاول",
  last_name: "الاسم الثاني",
  last_login: "اخر تسجيل دخول",
  phone: "الهاتف",
  telephone: "تليفون",
  email: "البريد الالكتروني",
  address: "العنوان",
  creation_date: "تاريخ التسجيل",
  vat_reg_num: "رقم التسجيل الضريبي",
  password: "كلمة المرور",

  // places
  city: "المدينة",
  governorate: "المحافظة",
  place: "المكان",

  // counting
  amount: "القيمة",
  subtotal: "الاجمالي قبل الضريبة",
  tax: "الضريبة",
  taxes: "الضرائب",
  tax_inc: "شامل الضريبة",
  tax_total: "اجمالي الضريبة",
  total: "الاجمالي",
  price: "السعر",
  price_before_tax: "السعر قبل الضريبة",
  quantity: "الكمية",
  discount: "الخصم",
  s_discount: "خصم",

  // Split payments
  change: "ادفع",
  balance: "الرصيد",
  received: "المستلم",
  remaining_to_rec: "المتبقي ليتم استلامه",
  ok: "تم",

  discount_before_tax: "تطبيق الخصم قبل الضريبة",
  discount_after_tax: "تطبيق الخصم بعد الضريبة",
  fixed_discount: "خصم بمبلغ ثابت",
  percentage_discount: "خصم مئوي",

  total_before_discount: "الاجمالي قبل الخصم",
  total_before_tax: "الاجمالي قبل الضريبة",
  total_after_tax: "الاجمالي شامل الضريبة",

  total_cost_before_tax: "اجمالي التكلفة قبل الضريبة",
  total_cost_after_tax: "اجمالي التكلفة بعد الضريبة",

  total_cost_before_discount: "اجمالي التكلفة قبل الضريبة",
  total_cost_after_discount: "اجمالي التكلفة بعد الضريبة",

  invoice: "فاتورة",
  invoice_no: "رقم الفاتورة",
  value: "القيمة",
  items_count: "عدد العناصر",
  sale_price_before_tax: "سعر البيع قبل الضريبة",
  sale_price: "سعر البيع",

  cost: "التكلفة",
  cost_before_tax: "التكلفة قبل الضريبة",
  cost_after_tax: "التكلفة بعد الضريبة",

  unit_price: "سعر الوحدة",
  count: "العدد",
  ref_number: "الرقم المرجعي",
  paid: "مدفوعة",
  unpaid: "غير مدفوعة",
  status: "الحالة",

  fixed: "مبلغ ثابت",
  percentage: "نسبة",

  // names
  product_name: "اسم المنتج",
  products: "المنتجات",
  group: "المجموعة",
  code: "كود الصنف",
  barcode: "الباركود",
  item_boxes: "صناديق المنتج",

  // placeholders
  search_client_or_supplier: "البحث بواسطة عميل او مورد ...",
  search_by_user: "البحث بواسطة اسم المستخدم",
  search_by_product_name_or_barcode: "اسم المنتج او باركود المنتج",

  // dropdowns
  chose_a_group: "----- اختر المجموعة -----",
  chose_a_supplier: "----- اختر مورد -----",
  default: "--- افتراضي ---",
  chose_a_tax: "--- اختر ضريبة ---",

  // popups
  delete_confirm: "هل انت متاكد من الحذف ",

  // utils
  next: "التالي",
  previous: "السابق",
  print: "طباعة",
  enable: "تفعيل",
  enabled: "مفعلة",

  // days
  day_sat: "السبت",
  day_sun: "الاحد",
  day_mon: "الاثنين",
  day_tue: "الثلاثاء",
  day_wed: "الاربع",
  day_thu: "الخميس",
  day_fri: "الجمعة",
  days: "الايام",
  everyday: "كل الايام",
};
