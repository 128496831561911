import TaxesReport from "@/views/management/reports/taxes_reports/TaxesReport";

export default [
  {
    path: "/management/reports/taxes_report/",
    component: TaxesReport,
    name: "taxes_report",
    meta: {
      title: () => {
        return "OnePos | Taxes Report";
      },
    },
  },
];
