<template>
  <div class="new-promo full-height row pb-5">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('sidebar.promos'), route: 'promos' },

        {
          name: `${$t('common.promo')} ${promo.name}`,
          route: '',
        },
      ]"
      class="pb-2"
    />

    <div class="inputs col-md-12">
      <div class="row">
        <p class="col-md-2 name">{{ $t("promo.promo_name") }}</p>
        <div class="col-md-7 p-0">
          <input
            class="form-control"
            type="text"
            placeholder=""
            :value="promo.name"
            disabled
          />
        </div>

        <div class="col row justify-content-end">
          <p class="col-md-4">{{ $t("common.active") }}</p>
          <p class="col-md-5">
            <!-- Rounded switch -->
            <label class="switch">
              <input type="checkbox" :checked="promo.is_active" disabled />
              <span class="slider round"></span>
            </label>
          </p>
        </div>
      </div>
      <div class="row align-items-baseline mt-3">
        <p class="col-md-2 name">{{ $t("promo.start_date") }}</p>
        <input
          type="text"
          class="col-md-10 pos-input py-2"
          :value="promo.start_date | localize_date"
          disabled
        />
      </div>
      <div class="row align-items-baseline mt-3">
        <p class="col-md-2 name">{{ $t("promo.end_date") }}</p>
        <input
          type="text"
          class="col-md-10 pos-input py-2"
          :value="promo.end_date | localize_date"
          disabled
        />
      </div>
      <div class="row mt-3">
        <p class="col-md-2 name">{{ $t("common.days") }}</p>
        <div id="days" class="col-md-9 days">
          <label>
            <input type="checkbox" value="all" disabled />
            {{ $t("common.everyday") }}</label
          >
          <label>
            <input type="checkbox" value="0" disabled />
            {{ $t("common.day_sat") }}</label
          >
          <label>
            <input type="checkbox" value="1" disabled />
            {{ $t("common.day_sun") }}</label
          >
          <label>
            <input type="checkbox" value="2" disabled />
            {{ $t("common.day_mon") }}</label
          >
          <label>
            <input type="checkbox" value="3" disabled />
            {{ $t("common.day_tue") }}</label
          >
          <label>
            <input type="checkbox" value="4" disabled />
            {{ $t("common.day_wed") }}</label
          >
          <label>
            <input type="checkbox" value="5" disabled />
            {{ $t("common.day_thu") }}</label
          >
          <label>
            <input type="checkbox" value="6" disabled />
            {{ $t("common.day_fri") }}</label
          >
        </div>
      </div>
    </div>

    <div class="products col-md-12">
      <div class="row" id="search-box">
        <div class="col-md-12">
          <div class="products-wrapper mt-3">
            <div class="table-wrapper">
              <table class="primary-table w-100">
                <thead>
                  <tr>
                    <th>{{ $t("common.product_name") }}</th>
                    <th class="text-primary">{{ $t("common.discount") }}</th>
                    <th class="text-primary">
                      {{ $t("promo.quantity_condition") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in promo.promoitem_set" :key="product.id">
                    <td>{{ product.product.name }}</td>

                    <td>
                      <div class="row">
                        <select class="form-select col-md-6" disabled>
                          <option
                            value="f"
                            :selected="product.discount_in_fixed_price > 0"
                          >
                            {{ $t("common.fixed") }} $
                          </option>
                          <option
                            value="p"
                            :selected="product.discount_in_percentage > 0"
                          >
                            {{ $t("common.percentage") }} %
                          </option>
                        </select>

                        <input
                          type="text"
                          placeholder=" Discount Amount"
                          class="form-control col-md-5 mt-3"
                          :value="
                            product.discount_in_fixed_price > 0
                              ? product.discount_in_fixed_price
                              : product.discount_in_percentage
                          "
                          disabled
                        />
                      </div>
                    </td>

                    <td class="row">
                      <label class="col-md-6 text-start">
                        <input
                          type="checkbox"
                          :checked="product.is_conditional"
                          disabled
                        />
                        {{ $t("common.enable") }}
                      </label>

                      <label class="col-md-12">
                        <input
                          :checked="product.is_all"
                          type="radio"
                          :name="'is_conditional' + product.id"
                          disabled
                        />
                        {{ $t("promo.apply_to_all") }}
                        <input
                          type="text"
                          class="form-control"
                          :value="product.apply_to_all"
                          disabled
                        />
                      </label>

                      <label class="col mt-1">
                        <input
                          :checked="!product.is_all"
                          type="radio"
                          :name="'is_conditional' + product.id"
                          disabled
                        />
                        {{ $t("promo.apply_to_next") }}
                        <input
                          type="text"
                          class="form-control"
                          :value="product.apply_to_next"
                          disabled
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

export default {
  name: "ViewPromo",
  components: { section_route },
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  | ${datetime.toLocaleTimeString()}
      `;
    },
  },
  data() {
    return {
      promo: {
        name: "",
      },
    };
  },
  methods: {
    get_promo() {
      this.$http
        .get(`/promo/promo_details/${this.$route.params.id}`)
        .then((response) => {
          this.promo = response.data[0];
          document.title = `OnePos | Promo ${this.promo.name}`;
          this.set_days();
        })
        .catch((e) => {
          console.log("error while getting a promo details : ", e);
        });
    },
    set_days() {
      const all = "0123456";
      const days_dom = document.querySelectorAll(
        'div#days input[type="checkbox"]'
      );

      if (this.promo.days_of_week.includes(all)) {
        days_dom[0].checked = true;
      }
      for (let day of days_dom) {
        if (this.promo.days_of_week.includes(day.value)) {
          day.checked = true;
        }
      }
    },
  },
  mounted() {
    this.get_promo();
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/settings/promo/main";
</style>
