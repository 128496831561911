<template>
  <div class="settings-payment">
    <div class="sec-name">{{ $t("settings.orders") }}</div>
    <div class="d-flex flex-column gap-3 mt-4">
      <div class="line">
        <div class="left">
          <p class="header">{{ $t("settings.prevent_negative") }}</p>
          <p class="desc">{{ $t("settings.prevent_negative_desc") }}</p>
        </div>
        <div class="right">
          <label class="switch">
            <input v-model="form.prevent_negative" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="line">
        <div class="left">
          <p class="header">{{ $t("settings.reset_invoice") }}</p>
          <p class="desc">
            {{ $t("settings.rest_invoice_desc") }}
          </p>
        </div>
        <label class="switch">
          <input v-model="form.reset_invoice" type="checkbox" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="line">
        <div class="left">
          <p class="header">{{ $t("settings.allow_changing_price") }}</p>
          <p class="desc">{{ $t("settings.allow_changing_price_desc") }}</p>
        </div>

        <label class="switch">
          <input v-model="form.allow_price_changing" type="checkbox" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="line">
        <div class="left">
          <p class="header">{{ $t("settings.discount_type") }}</p>
          <p class="desc">{{ $t("settings.discount_type_desc") }}</p>
        </div>
        <div class="right">
          <select v-model="form.discount_type" class="form-select">
            <option value="before_tax">
              {{ $t("settings.before_tax") }}
            </option>
            <option value="after_tax" selected>
              {{ $t("settings.after_tax") }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="d-flex buttons gap-3 justify-content-end mt-5">
      <button @click="save_order_settings" class="pos-primary-btn px-5 p-3">
        {{ $t("common.save") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersSettings",
  data() {
    return {
      form: {
        prevent_negative: false,
        reset_invoice: false,
        allow_price_changing: false,
        discount_type: "after_tax",
      },
    };
  },
  methods: {
    get_order_settings() {
      this.$http
        .get(`/settings/get_order_settings/`)
        .then((response) => {
          this.form = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching settings ! : ${e}`);
        });
    },
    cancel() {
      this.$router.push({ name: "settings" });
    },
    save_order_settings() {
      this.$http
        .put("/settings/update_order_settings/", this.form)
        .then((response) => {
          this.form = response.data;
          this.$store.dispatch("utils/push_alert", {
            message: `Settings Saved Successfully`,
            type: "success",
          });
        })
        .catch((e) => {
          console.log("Error while updating orderSettings", e);
        });
    },
  },

  mounted() {
    this.get_order_settings();
  },
};
</script>

<style lang="scss" scoped>
.settings-payment {
  position: relative;
  width: 100%;
  height: 100%;
  .line {
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 20px;
      color: $secondary-500;
      padding: 0;
      margin: 0;
    }
    .desc {
      color: #808080;
    }
  }
}
</style>
