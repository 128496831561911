<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.daily_sales'), route: 'daily_sales' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="orders.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="orders.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>{{ $t("common.date") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) of orders" :key="index">
              <td>{{ order.date }}</td>
              <td>{{ order.total }}</td>
            </tr>
            <tr v-if="meta_data.meta_data">
              <td></td>
              <td>{{ meta_data.meta_data.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "DailySales",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/daily_sales/`,
      orders: [],
      meta_data: {},
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.meta_data = response.data.pop();
          this.orders = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching orders : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "daily_report.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style scoped></style>
