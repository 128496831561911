import { Modal } from "bootstrap";

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function is_decimal_number(val) {
  const regex = new RegExp(/^\d+(\.\d{1,2})?$/);
  return regex.test(val);
}

export function close_current_modal() {
  //  close_current modal
  document.documentElement.querySelector(".modal.fade.show .btn-close").click();
}

export function sum_array(array) {
  let sum = 0;

  array.forEach((item) => {
    sum += item;
  });
  return sum.toFixed(2);
}

export function fixed_float(n) {
  // fixing float to 2 and return parsed Float type
  return parseFloat(parseFloat(n).toFixed(2));
}

export function focus_input(query_selector, time = 480) {
  /*
   Focus on Input after period of time
   */
  const input_node = document.querySelector(query_selector);
  setTimeout(function () {
    input_node.focus();
  }, time);
}

export function check_url(str) {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function to_local_datetime(datetime) {
  const utcDatetime = new Date(datetime);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return new Date(utcDatetime.toLocaleString("en-US", options))
    .toISOString()
    .replace(".000", "")
    .replace("Z", "");
}

export function open_modal(modal_document_id) {
  const new_modal = document.getElementById(modal_document_id);
  const myModal = new Modal(new_modal);
  myModal.show();
}

export function convertTo12HourFormat(time24) {
  const [hours, minutes = "00"] = time24.split(":");
  let period = "AM";
  let hour = parseInt(hours, 10);

  if (hour >= 12) {
    period = "PM";
    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${hour}:${minutes} ${period}`;
}

export function clean_url_params(url) {
  let params = new URLSearchParams(url);
  let keys_to_delete = [];
  params.forEach((value, key) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === "null" ||
      value === "undefined"
    ) {
      keys_to_delete.push(key);
    }
  });

  keys_to_delete.forEach((key) => {
    params.delete(key);
  });

  return params.toString();
}

// Function to count repeated elements in array by id
export const count_repeated_by_id = (counts, element) => {
  counts[element.id] = (counts[element.id] || 0) + 1;
  // will return object of each product id as a key and repeated as value {11: 1, 12: 3, 6: 1}
  return counts;
};

export function get_percentage(total, percentage) {
  return fixed_float((total * percentage) / 100);
}
