var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section_route',{attrs:{"routes":[
      { name: _vm.$t('sidebar.management'), route: 'management' },
      { name: _vm.$t('reports.reports'), route: 'reports' },
      {
        name: _vm.$t('reports.purchase_suppliers'),
        route: 'purchase_by_suppliers',
      },
    ]}}),_c('div',{staticClass:"row py-3 p-3 justify-content-end"},[_c('button',{staticClass:"btn btn-warning mx-lg-5 col-sm-3 col-12",attrs:{"disabled":_vm.suppliers.length === 0},on:{"click":_vm.get_pdf_file}},[_vm._v(" "+_vm._s(_vm.$t("reports.save_as_pdf"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.suppliers.length > 0),expression:"suppliers.length > 0"}],staticClass:"table-wrapper p-3"},[_c('table',{staticClass:"table table-striped text-center"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("common.suppliers")))]),_c('th',[_vm._v(_vm._s(_vm.$t("common.total_before_tax")))]),_c('th',[_vm._v(_vm._s(_vm.$t("common.total")))])])]),_c('tbody',[_vm._l((_vm.suppliers),function(supplier,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(supplier.name))]),_c('td',[_vm._v(_vm._s(supplier.subtotal))]),_c('td',[_vm._v(_vm._s(supplier.total))])])}),(_vm.meta_data.meta_data)?_c('tr',[_c('td'),_c('td',[_vm._v(_vm._s(_vm.meta_data.meta_data.subtotal))]),_c('td',[_vm._v(_vm._s(_vm.meta_data.meta_data.total))])]):_vm._e()],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }