export default {
  // orders
  orders: "Orders",
  prevent_negative: "Prevent negative Inventory",
  prevent_negative_desc: "stop selling when a product quantity hits 0",
  reset_invoice: "Reset Invoice Number when ending the day",
  rest_invoice_desc:
    "Receipt number starts from 0 when any user ending the day",

  allow_changing_price: "Allow changing price on sale",
  allow_changing_price_desc:
    "user can change the price of product while selling",

  discount_type: "Discount Type",
  discount_type_desc:
    "The Way You Want to apply discount after tax or before tax",
  before_tax: "Before Tax",
  after_tax: "After Tax",

  // weighting Scale
  w_scale: "Weighing Scales & Barcode",
  w_scale_desc: "Enable Weighting Scale to selling screen search",
  price_desc: "Scale Prints Price Instead Of Quantity",
  price_decimal_places: "Price Decimal Places",
  price_decimal_places_desc: "Number Of Decimal Places for Product Price",
  weight_decimal_places: "Weight Decimal Places",
  weight_decimal_places_desc:
    "Number Of Decimal Places for Product Weight in ean 18 and above",

  // taxes
  add_new_tax: "Add New Tax",
  value_in_percentage: "Value in Percentage",

  // payment types
  payment_types: "Payment Types",
  add_new_payment: "Add New Payment",
  mark_as_paid: "Mark as Paid",
  customer_required: "Customer Required",
};
