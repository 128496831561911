import PurchasesMain from "@/views/management/purchases/PurchasesMain";

import RefundedPurchases from "@/views/management/purchases/RefundedPurchases";
import PurchaseCrud from "@/views/management/purchases/PurchaseCrud";
import PurchasesView from "@/views/management/purchases/PurchasesView";
import PurchaseDetails from "@/views/management/purchases/PurchaseDetails";

// TODO: try to improve dependency in [PurchaseCrud, PurchaseDetails]

export default [
  {
    path: "/management/purchases/",
    name: "purchases",
    component: PurchasesMain,
    children: [
      {
        path: "all_purchases/",
        name: "all_purchases",
        component: PurchasesView,
        meta: {
          title: () => {
            return `OnePos | Purchases`;
          },
        },
      },
      {
        path: "all_purchases/purchase_details/:report_id",
        component: PurchaseDetails,
        name: "purchase_details",
        meta: {
          title: (route) => {
            return `OnePos | Purchase No ${route.params.report_id}`;
          },
        },
      },
      {
        path: "refund/",
        name: "purchases_refund",
        component: RefundedPurchases,
        meta: {
          title: () => {
            return `OnePos | Purchases Refund`;
          },
        },
      },
      {
        path: "refund/purchase_details/:report_id",
        component: PurchaseDetails,
        name: "purchase_refund_details",
        meta: {
          title: (route) => {
            return `OnePos | Purchase No ${route.params.report_id}`;
          },
        },
      },
      {
        path: "purchases/new_purchase/",
        name: "purchases_new",
        component: PurchaseCrud,
        meta: {
          title: () => {
            return "OnePos | New Purchase";
          },
        },
      },
      {
        path: "purchases/update/:id",
        component: PurchaseCrud,
        name: "purchases_update",
        meta: {
          title: (route) => {
            return `OnePos | Edit Purchase No ${route.params.id}`;
          },
        },
      },
      {
        path: "refund/update/:id",
        component: PurchaseCrud,
        name: "purchases_refund_update",
        meta: {
          title: (route) => {
            return `OnePos | Edit Refund No ${route.params.id}`;
          },
        },
      },
      {
        path: "refund/new_refund/",
        name: "purchases_new_refund",
        component: PurchaseCrud,
        meta: {
          title: () => {
            return `OnePos | New Refund`;
          },
        },
      },
    ],
  },
];
