import ClientAndSupplier from "@/views/management/ClientAndSupplier";

export default [
  {
    path: "/management/client_and_supplier/",
    name: "client_and_supplier",
    component: ClientAndSupplier,
    meta: {
      title: () => {
        return `OnePos | Clients and Supplier`;
      },
    },
  },
];
