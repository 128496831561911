export default [
  {
    path: "/management/products/add_new_product",
    name: "new_product",
    component: () =>
      import("@/views/management/products/CreateUpdateProduct.vue"),
    meta: {
      title: () => {
        return "OnePos | New Product";
      },
    },
  },

  {
    path: "/management/products/edit_product/:product_id",
    name: "edit_product",
    component: () =>
      import("@/views/management/products/CreateUpdateProduct.vue"),
    meta: {
      title: (route) => {
        return `OnePos | Edit Product ${route.params.product_id}`; // manually edited in the view to get name instead of product_id
      },
    },
  },
];
