import PurchaseByProduct from "@/views/management/reports/purchase_reports/PurchaseByProduct";
import PurchaseBySuppliers from "@/views/management/reports/purchase_reports/PurchaseBySuppliers";
import PurchaseDiscount from "@/views/management/reports/purchase_reports/PurchaseDiscount";
import PurchaseInvoices from "@/views/management/reports/purchase_reports/PurchaseInvoices";

export default [
  {
    path: "/management/reports/purchase_by_product/",
    component: PurchaseByProduct,
    name: "purchase_by_product",
    meta: {
      title: () => {
        return "OnePos | Purchase By Product";
      },
    },
  },
  {
    path: "/management/reports/purchase_by_suppliers/",
    component: PurchaseBySuppliers,
    name: "purchase_by_suppliers",
    meta: {
      title: () => {
        return "OnePos | Purchase By Suppliers";
      },
    },
  },
  {
    path: "/management/reports/purchase_discount/",
    component: PurchaseDiscount,
    name: "purchase_discount",
    meta: {
      title: () => {
        return "OnePos | Purchase Discount";
      },
    },
  },
  {
    path: "/management/reports/purchase_invoices/",
    component: PurchaseInvoices,
    name: "purchase_invoices",
    meta: {
      title: () => {
        return "OnePos | Purchase Invoices";
      },
    },
  },
];
