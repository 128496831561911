<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.product_groups'), route: 'product_groups' },
      ]"
    />
    <div class="row py-3 justify-content-end p-3">
      <button
        @click="get_pdf_file"
        :disabled="groups.length === 0"
        class="btn btn-warning col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
    </div>
    <div v-show="groups.length > 0" class="table-wrapper p-3">
      <table class="table table-striped responsive-table text-center">
        <thead class="sticky-table-header">
          <tr>
            <th>{{ $t("common.group") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th>{{ $t("common.total_before_tax") }}</th>
            <th>{{ $t("common.total") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group of groups" :key="group.id">
            <td :data-label="$t('common.group')">
              {{ group.product__group__name }}
            </td>
            <td :data-label="$t('common.quantity')">{{ group.quantity }}</td>
            <td :data-label="$t('common.total_before_tax')">
              {{ group.total_before_tax }}
            </td>
            <td :data-label="$t('common.total')">
              {{ group.total_after_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "ProductGroupsReport",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/inventory_product_groups/`,
      groups: [],
    };
  },
  methods: {
    get_groups() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "product_groups.pdf");
    },
  },
  mounted() {
    this.get_groups();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 50rem;
  max-height: 50rem;
  overflow: scroll;
}
</style>
