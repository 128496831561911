import StockQuantities from "@/views/management/inventory/StockQuantities";

export default [
  {
    path: "stock/",
    component: StockQuantities,
    name: "stock",
    meta: {
      title: () => {
        return "OnePos | Stock Quantities";
      },
    },
  },
];
