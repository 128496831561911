import CustomerOrders from "@/views/management/reports/customer_reports/CustomerOrders";

export default [
  {
    path: "/management/reports/customer_orders/",
    component: CustomerOrders,
    name: "customer_orders",
    meta: {
      title: () => {
        return "OnePos | Customer Orders";
      },
    },
  },
];
