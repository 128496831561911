<template>
  <div class="inventory-transfer pb-5">
    <modal :modal_id="'delete-confirm-modal'">
      <template #title-content>Confirm </template>
      <template #body>
        <div>
          <span class="text-danger" style="font-size: 1.2rem">
            {{ $t("common.delete_confirm") }}
          </span>
          <span class="text-success" style="font-size: 1.2rem">
            <span style="font-size: 1.2rem">
              {{ $t("inventory.report") }} {{ selected_transfer.id }},
            </span>
          </span>
        </div>
      </template>
      <template #footer>
        <button @click="delete_transfer" class="btn btn-danger px-4">
          {{ $t("common.delete") }}
        </button>
        <button @click="close_current_modal" class="btn btn-secondary px-5">
          {{ $t("common.cancel") }}
        </button>
      </template>
    </modal>

    <div class="d-flex justify-content-between">
      <div class="d-none d-md-block">
        <p class="sec-name">
          {{ $t("inventory.inventory_transfer") }}
        </p>
      </div>
      <div class="d-flex col-12 col-md-auto justify-content-between">
        <button @click="filter = !filter" class="btn btn-light">
          <img v-show="filter" :src="$get_icon('FilterOnIcon')" alt="" />
          <img v-show="!filter" :src="$get_icon('FilterOffIcon')" alt="" />
        </button>
        <button
          @click="$router.push({ name: 'new_transfer' })"
          class="pos-primary-btn me-3 float-end px-5 py-2"
        >
          {{ $t("inventory.new_transfer_operation") }}
        </button>
      </div>
    </div>
    <div
      v-if="filter"
      class="filters d-flex flex-wrap gap-3 align-items-center justify-content-start justify-content-md-between mt-3"
    >
      <div>
        <p>{{ $t("common.from") }}</p>
        <input ref="from_date" type="date" class="pos-input px-4 py-2 ms-3" />
      </div>

      <div>
        <p>{{ $t("common.to") }}</p>
        <input ref="to_date" type="date" class="pos-input px-4 py-2 ms-3" />
      </div>

      <div>
        <p>{{ $t("common.user") }}</p>
        <input
          ref="user_input"
          class="pos-input px-2 py-2 ms-3"
          list="users"
          :placeholder="$t('common.search_by_user')"
        />
        <datalist id="users">
          <option
            v-for="{ pk, username } in users"
            :key="pk"
            :value="username"
          ></option>
        </datalist>
      </div>

      <div class="btn-group col-12 col-md-auto">
        <button
          ref="status_button"
          type="button"
          class="btn btn-info text-light dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          {{ $t("inventory.status") }}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item text-success"
              @click="set_filter_status('accepted')"
            >
              {{ $t("inventory.approved") }}</a
            >
          </li>
          <li @click="set_filter_status('pending')">
            <a class="dropdown-item text-warning">{{
              $t("inventory.pending")
            }}</a>
          </li>
          <li @click="set_filter_status('declined')">
            <a class="dropdown-item text-danger">
              {{ $t("inventory.declined") }}</a
            >
          </li>
          <li @click="set_filter_status('')">
            <a class="dropdown-item text-info">{{ $t("common.default") }}</a>
          </li>
        </ul>
      </div>
      <button
        @click="search_filter"
        class="pos-primary-btn px-5 py-2 col-12 col-md-auto"
      >
        {{ $t("common.search") }}
      </button>
    </div>

    <table class="primary-table responsive-table mt-4">
      <thead>
        <tr>
          <th>ID</th>
          <th>{{ $t("common.user") }}</th>
          <th>{{ $t("common.date") }}</th>
          <th>{{ $t("common.from") }}</th>
          <th>{{ $t("common.to") }}</th>
          <th>{{ $t("inventory.status") }}</th>
          <th>{{ $t("common.details") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="{
            id,
            user,
            timestamp,
            from_stock,
            to_stock,
            status,
          } in transfer_operations"
          :key="id"
        >
          <td data-label="ID">{{ id }}</td>
          <td :data-label="$t('common.user')">{{ user }}</td>
          <td :data-label="$t('common.date')">
            {{ timestamp | localize_date }}
          </td>
          <td :data-label="$t('common.from')">{{ from_stock }}</td>
          <td :data-label="$t('common.to')">{{ to_stock }}</td>
          <td
            :data-label="$t('inventory.status')"
            style="color: #935416; font-size: 18px"
          >
            {{ $t(`inventory.${status}`) }}
          </td>
          <td :data-label="$t('common.details')">
            <div class="d-flex justify-content-center flex-wrap gap-1">
              <img
                @click="
                  $router.push({
                    name: 'transfer_details',
                    params: { report_id: id },
                  })
                "
                title="View Transfer"
                :src="$get_icon('view')"
                class="btn btn-primary"
                alt=""
              />
              <img
                @click="set_selected_transfer(id)"
                data-bs-toggle="modal"
                data-bs-target="#delete-confirm-modal"
                :src="$get_icon('delete')"
                class="btn btn-danger"
                alt=""
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="w-50 mx-auto">
      <div class="pagination d-flex flex-wrap justify-content-between mt-5">
        <button
          @click="pre_page"
          :disabled="!pagination.previous"
          class="btn btn-light"
        >
          {{ $t("common.previous") }}
        </button>
        <span class="text-center"
          >Page {{ pagination.current_page }} of
          {{ pagination.total_pages }}</span
        >
        <button
          @click="next_page"
          :disabled="!pagination.next"
          class="btn btn-light"
        >
          {{ $t("common.next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "@/components/bootstrap_modal";
import { close_current_modal } from "@/helpers";
import { mapActions } from "vuex";

export default {
  name: "InventoryTransfer",
  components: {
    modal,
  },
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  | ${datetime.toLocaleTimeString()}
      `;
    },
  },
  data() {
    return {
      filter: false,
      transfer_operations: [],
      filter_status: "",
      selected_transfer: {},
      users: [],
      pagination: {
        count: 0,
        current_page: 1,
        next: null,
        previous: null,
        total_pages: 1,
      },
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden", "append_forbidden_error"]),
    close_current_modal,
    get_transfer_operations() {
      this.$isLoading(true);
      this.$http
        .get(`/inventory/stock_transfer/?page=${this.pagination.current_page}`)
        .then((response) => {
          this.transfer_operations = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
            this.append_forbidden_error(
              "Make Sure You Have Permission To View Stock Transfer Operations"
            );
          }
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    next_page() {
      if (this.pagination.next) {
        this.pagination.current_page += 1;
        this.get_transfer_operations();
      }
    },
    pre_page() {
      if (this.pagination.previous) {
        this.pagination.current_page -= 1;
        this.get_transfer_operations();
      }
    },
    get_users() {
      this.$http
        .get(`/accounts/get_branch_users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    delete_transfer() {
      close_current_modal();
      const transfer_id = this.selected_transfer.id;
      this.$http.delete(`/inventory/stock_transfer/${transfer_id}`).then(() => {
        this.$store.dispatch("utils/push_alert", {
          message: "Operation Deleted Successfully",
          type: "warning",
        });

        this.transfer_operations = this.transfer_operations.filter(
          (operation) => operation.id !== transfer_id
        );
      });
    },
    set_selected_transfer(transfer_id) {
      this.selected_transfer = this.transfer_operations.find(
        (t) => t.id === transfer_id
      );
    },
    set_filter_status(status) {
      let self = this;
      // setTimeout because bootstrap dropdown won't close with vue click event binding
      setTimeout(function () {
        self.filter_status = status;
        const btn = self.$refs.status_button;
        const default_class = "btn btn-info text-light dropdown-toggle";
        if (status.toLowerCase() === "accepted") {
          btn.className = "btn btn-success text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.approved");
        } else if (status.toLowerCase() === "pending") {
          btn.className = "btn btn-warning text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.pending");
        } else if (status.toLowerCase() === "declined") {
          btn.className = "btn btn-danger text-light dropdown-toggle";
          btn.innerText = self.$t("inventory.declined");
        } else {
          btn.className = default_class;
          btn.innerText = self.$t("inventory.status");
        }
        // btn.setAttribute("aria-expanded", "false");
      }, 50);
    },
    search_filter() {
      const user = this.$refs.user_input.value;
      const from_date = this.$refs.from_date.value
        ? new Date(this.$refs.from_date.value).toISOString()
        : "";

      const to_date = this.$refs.to_date.value
        ? new Date(this.$refs.to_date.value).toISOString()
        : "";

      const url = `/inventory/stock_transfer/?user=${user}&status=${this.filter_status}&from_date=${from_date}&to_date=${to_date}`;
      this.$isLoading(true);

      this.$http
        .get(url)
        .then((response) => {
          this.transfer_operations = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(`Error while filtering inventory transfer : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
  },
  mounted() {
    this.get_transfer_operations();
  },
};
</script>

<style scoped></style>
