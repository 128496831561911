import new_product from "./new_product/new_product_ar";

export default {
  ...new_product,
  manage_groups: "ادارة المجموعات",
  add_new_group: "اضافة مجموعة جديدة",
  add_new_product: "اضافة منتج جديد",
  group_name: "اسم المجموعة",
  edit_group: "تعديل المجموعة",
  confirm_delete_product: "هل انت متأكد من حذفك للمنتج ؟",
  // taxes
  add_new_tax: "إضافة ضريبة جديدة",
  value_in_percentage: "القيمة بالنسبة المئوية",
  new_tax_placeholder: "15 تعني 15%",
};
