import AddonsMain from "@/views/management/products/addons/AddonsMain";

export default [
  {
    path: "/management/addons",
    name: "addons",
    component: AddonsMain,
    meta: {
      title: () => {
        return "OnePos | Addons";
      },
    },
  },
];
