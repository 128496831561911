export default {
  //quantities in the stock
  quantities_in_the_stock: "الكميات في المخزون",
  negative_quantity: "كمية بالسالب",
  zero_quantity: "كمية تساوي صفر",
  non_zero_quantity: "كميه اعلي من الصفر",
  // quantities in the stock -> squares title
  items_with_zero_quantity: "عدد المنتجات بكمية صفر",
  items_with_quantity_greater_than_zero: "عدد المنتجات اكبر من صفر",

  // inventory count
  inventory_count: "الجرد",
  new_inventory_count: "جرد جديد",
  status: "الحالة",
  approve: "موافقة",
  approved: "تم الموافقة",
  accepted: "تم الموافقة",
  pending: "قيد الانتظار",
  decline: "رفض",
  declined: "مرفوض",
  add_new_inventory_count: "اضافة جرد جديد",

  // display count
  inventory_count_number: "رقم الجرد",
  inventory_damage_number: "رقم التالف",

  counted_qty: "الكمية المعدودة",
  expected_qty: "الكمية الموجودة",
  increase_qty: "زيادة في الكمية",
  shortage_qty: "عجز في الكمية",
  shortage_price: "سعر العجز",

  // inventory Damage
  inventory_damage: "التالف",
  new_inventory_damage: "تالف جديد",
  add_new_inventory_damage: "اضافة تالف جديد",
  damaged: "التالف",
  damaged_price: "اجمالي التالف",

  //transfer
  transfer: "التحويل",
  inventory_transfer: "تحويل المخزون",
  report: "تقرير",
  new_transfer_operation: "عملية تحويل جديدة",
  from_product: "من المنتج",
  to_product: "الي المنتج",
  notes: "الملاحظات",
  no_notes: "لا توجد ملاحظات",
  from_branch: "من الفرع",
  to_branch: "الي الفرع",
  select_a_branch: "اختر الفرع",

  product_want_move_to: "اسم المنتج الذي تريد النقل منه",
  adding_notes: "اضف ملاحظات لعملية النقل",
};
