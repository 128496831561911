var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inventory full-height"},[_c('div',{staticClass:"d-flex flex-column flex-md-row gap-3 full-height"},[_c('div',{staticClass:"layout-sidebar col-12 col-md-3"},[_c('section_route',{staticClass:"pb-2",attrs:{"routes":[
          { name: _vm.$t('sidebar.management'), route: 'management' },
          {
            name: _vm.$t('sidebar.inventory'),
            route: '',
          },
        ]}}),_c('div',{staticClass:"layout-menu-buttons d-flex flex-wrap gap-3"},[_c('router-link',{staticClass:"col col-md-12",attrs:{"custom":"","to":{ name: 'stock' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("inventory.quantities_in_the_stock"))+" ")])]}}])}),_c('router-link',{staticClass:"col col-md-12",attrs:{"custom":"","to":{ name: 'inventory_count' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("inventory.inventory_count"))+" ")])]}}])}),_c('router-link',{staticClass:"col col-md-12",attrs:{"custom":"","to":{ name: 'inventory_damage' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("inventory.inventory_damage"))+" ")])]}}])}),_c('router-link',{staticClass:"col col-md-12",attrs:{"custom":"","to":{ name: 'inventory_transfer' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("inventory.transfer"))+" ")])]}}])})],1)],1),_c('div',{staticClass:"content-wrapper col"},[_c('div',{staticClass:"router-wrapper"},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }