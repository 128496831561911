<template>
  <div>
    <h3>{{ get_page_title() }}</h3>

    <div class="info mt-5">
      <div class="left">
        <div class="static-floating-label-content m-0">
          <input
            ref="ext_id"
            class="static-floating-input"
            type="text"
            placeholder=""
          />
          <label class="static-floating-label">{{
            $t("common.ref_number")
          }}</label>
        </div>

        <select
          ref="dropdown_supplier"
          @change="select_supplier"
          class="form-select"
        >
          <option
            v-for="supplier in suppliers"
            :key="supplier.id"
            :value="supplier.id"
          >
            {{ supplier.name }}
          </option>
          <option v-if="selected_supplier.id" :selected="selected_supplier">
            {{ selected_supplier.name }}
          </option>
          <option v-else selected value>
            {{ $t("common.chose_a_supplier") }}
          </option>
        </select>

        <input ref="inv_date" type="date" class="pos-input" />

        <label class="d-flex gap-2 align-items-center">
          <input ref="paid" type="checkbox" class="pos-input" />
          <span>{{ $t("common.paid") }}</span>
        </label>
      </div>
      <div class="right">
        <div class="input-dropdown">
          <search_products
            ref="search_component"
            :parent_component="$parent"
            class="search-products"
          />
          <ul v-if="dropdown_toggle" class="dropdown">
            <li
              v-for="product in searched_products"
              :key="product.id"
              @click="set_item(product)"
              class="row justify-content-between"
            >
              <img
                :src="product.image"
                alt=""
                width="32"
                height="32"
                class="col-md-3"
              />
              <p class="col-md-8" href="#">{{ product.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search_products from "@/components/sell_screen/search_products";

export default {
  name: "HeaderSection",
  props: ["page_type", "searched_products", "set_item"],
  components: {
    search_products,
  },
  data() {
    return {
      suppliers: [],
      selected_supplier: {},
      // search_products component variables
      dropdown_toggle: false,
    };
  },
  methods: {
    get_page_title() {
      if (this.page_type.new_page) {
        return this.$t("purchase.add_new_purchase");
      } else if (this.page_type.refund_page) {
        return this.$t("purchase.add_new_purchase_refund");
      } else if (this.page_type.purchases_update) {
        return `${this.$t("purchase.edit_purchase_no")} ${
          this.$route.params.id
        }`;
      } else if (this.page_type.refund_update) {
        return `${this.$t("purchase.edit_purchase_refund_no")} ${
          this.$route.params.id
        }`;
      }
    },
    get_suppliers() {
      this.$http
        .get(`/accounts/get_suppliers/`)
        .then((response) => {
          this.suppliers = response.data.results;
        })
        .finally(() => {
          if (this.purchases_update || this.refund_update) {
            document.title = `OnePos | Edit Purchase No ${this.$route.params.id}`;
            this.get_details();
          }
        })
        .catch((e) => {
          console.log(`Error while fetching suppliers ! : ${e}`);
        });
    },
    select_supplier(e) {
      const supplier_index = this.suppliers.findIndex(
        (supplier) => supplier.id === parseInt(e.target.value)
      );
      this.selected_supplier = this.suppliers[supplier_index];
    },
  },
  mounted() {
    this.get_suppliers();

    let self = this;
    this.$refs.search_component.$refs.search_input.addEventListener(
      "focus",
      function () {
        self.dropdown_toggle = true;
      }
    );
    this.$refs.search_component.$refs.search_input.addEventListener(
      "blur",
      function () {
        // so user can select the dropdown item increase delay
        // if modal not showing or set dropdown_toggle based
        // if there is showing modal
        setTimeout(() => {
          self.dropdown_toggle = false;
        }, 200);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  //margin-top: 0.5rem;
  gap: 2rem;

  .left {
    padding: 0;
    margin: 0;
    max-height: 3.5rem;
    justify-content: flex-end;

    align-items: center;
    display: flex;
    gap: 1rem;

    & > * {
      flex: 7;
      padding: 0.35rem !important;
    }

    & > label {
      flex: 2;
      flex-basis: 0;
      font-size: 20px;
      text-align: end;

      input {
        transform: scale(1.2);
      }
    }

    .did-floating-label-content {
      padding: 0;
      margin: 0;
    }
  }

  .right {
    display: flex;

    .input-dropdown {
      flex-grow: 3;
      //display: flex;
      //flex-wrap: wrap;
      position: relative;

      .search-products {
        list-style: none;

        //noinspection CssInvalidPseudoSelector
        :deep(.search-bar) {
          padding: 1rem 1rem 1rem 3rem;
          width: 100%;
        }

        //noinspection CssInvalidPseudoSelector
        :deep(.search-icon) {
          margin: 0;
        }
      }

      ul.dropdown {
        display: block;
        position: absolute;
        z-index: 99999;
        background: $main-white;
        border: 1px solid $primary-100;
        border-radius: 5px;
        width: 100%;

        li {
          padding: 10px 5px;
          list-style: none;
          &:hover {
            background: $primary-100;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
