import InventoryMain from "@/views/management/inventory/InventoryMain.vue";
import Stock_quantity_routes from "@/router/routes/management/inventory/stock_quantity/stock_quantity_routes";
import Inventory_count_routes from "@/router/routes/management/inventory/inventory_count/inventory_count_routes";
import Inventory_damage_routes from "@/router/routes/management/inventory/inventory_damage/inventory_damage_routes";
import Inventory_transfer_routes from "@/router/routes/management/inventory/inventory_transfer/inventory_transfer_routes";

export default [
  {
    path: "/management/inventory",
    name: "inventory",
    component: InventoryMain,
    children: [
      ...Stock_quantity_routes,
      ...Inventory_count_routes,
      ...Inventory_damage_routes,
      ...Inventory_transfer_routes,
    ],
  },
];
