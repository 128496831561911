<template>
  <div class="settings-taxes">
    <modal :modal_id="'add-tax-modal'" :close_btn_id="'add_close_modal'">
      <template #title-content> {{ $t("settings.add_new_tax") }}</template>
      <template #body>
        <div class="row mt-2" style="gap: 2rem">
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.name") }}</p>
            <div class="col-md-9 g-0">
              <input v-model="form.name" type="text" class="pos-input w-100" />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("settings.value_in_percentage") }}</p>
            <div class="col-md-9 g-0">
              <input
                v-model="form.percentage_value"
                type="text"
                pattern="[0-9]+"
                class="pos-input w-100"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button
          @click="reset_modal"
          ref="edit-close-modal-btn"
          type="button"
          class="btn btn-secondary px-5"
          data-bs-dismiss="modal"
        >
          {{ $t("common.cancel") }}
        </button>
        <button @click="save" type="button" class="pos-primary-btn py-2 px-5">
          {{ $t("common.save") }}
        </button>
      </template>
    </modal>
    <modal :modal_id="'edit-tax-modal'" :close_btn_id="'edit_close_modal'">
      <template #title-content
        >{{ $t("common.edit") }} {{ selected_item.name }}
      </template>
      <template #body>
        <div class="row mt-2" style="gap: 2rem">
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("common.name") }}</p>
            <div class="col-md-9 g-0">
              <input
                ref="update_name"
                :value="selected_item.name"
                type="text"
                class="pos-input w-100"
              />
            </div>
          </div>
          <div class="row col-md-12">
            <p class="col-md-3">{{ $t("settings.value_in_percentage") }}</p>
            <div class="col-md-9 g-0">
              <input
                ref="update_value"
                :value="selected_item.percentage_value"
                type="number"
                class="pos-input w-100"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary px-5"
          data-bs-dismiss="modal"
        >
          {{ $t("common.cancel") }}
        </button>
        <button @click="edit" type="button" class="pos-primary-btn py-2 px-5">
          {{ $t("common.save") }}
        </button>
      </template>
    </modal>
    <div class="sec-name">{{ $t("common.taxes") }}</div>
    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("common.name") }}</th>
            <th>{{ $t("common.amount") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{ id, name, percentage_value } in taxes"
            :key="id"
            @click="set_row(id)"
            :class="{
              'active-row': selected_rows.includes(id),
            }"
          >
            <td>
              <input type="checkbox" :checked="selected_rows.includes(id)" />
            </td>
            <td>{{ name }}</td>
            <td>{{ percentage_value }}%</td>
          </tr>
        </tbody>
      </table>
      <!-- /.primary-table -->
    </div>
    <!-- /.table-wrapper -->
    <div class="buttons">
      <button
        class="pos-button"
        @click="delete_tax"
        :disabled="selected_rows.length <= 0"
      >
        {{ $t("common.delete") }}
      </button>
      <!-- /.pos-button -->
      <button
        class="pos-button"
        data-bs-toggle="modal"
        data-bs-target="#edit-tax-modal"
        :disabled="selected_rows.length !== 1"
      >
        {{ $t("common.edit") }}
      </button>
      <!-- /.pos-button -->
      <button
        class="pos-button"
        data-bs-toggle="modal"
        data-bs-target="#add-tax-modal"
      >
        {{ $t("common.add") }}
      </button>

      <!-- /.pos-button -->
    </div>
    <!-- /.buttons -->
  </div>
</template>

<script>
import modal from "@/components/bootstrap_modal";

export default {
  components: {
    modal,
  },
  name: "TaxesView",
  data() {
    return {
      selected_rows: [], // ids
      selected_item: {},

      taxes: [],
      pagination: {},
      form: {
        name: "",
        percentage_value: "",
      },
    };
  },
  methods: {
    set_row(id) {
      if (this.selected_rows.includes(id)) {
        this.selected_rows.splice(this.selected_rows.indexOf(id), 1);
      } else {
        this.selected_rows.push(id);
        this.selected_item = this.taxes.find((tax) => tax.id === id);
      }
    },

    get_taxes() {
      this.$http
        .get(`/inventory/taxes`)
        .then((response) => {
          this.taxes = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          console.log(`Error while fetching taxes ! : ${e}`);
        });
    },
    save() {
      document.getElementById("add_close_modal").click();
      this.$http
        .post(`/inventory/taxes`, this.form)
        .then((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: "Tax Added Successfully",
            type: "success",
          });
          this.taxes.push(e.data);
        })
        .catch((errors) => {
          const errors_data = errors.response.data;
          for (let key in errors_data) {
            this.$store.dispatch("utils/push_alert", {
              message: `${key}: ${errors_data[key][0]}`,
              type: "danger",
            });
          }
          console.log(`Error while creating new taxes ! : ${errors}`);
        });
      this.reset_modal();
    },
    edit() {
      document.getElementById("edit_close_modal").click();

      this.$http
        .put(`/inventory/taxes`, {
          id: this.selected_item.id,
          name: this.$refs.update_name.value,
          percentage_value: this.$refs.update_value.value,
        })
        .then((response) => {
          const index = this.taxes.findIndex(
            (tax) => tax.id === this.selected_item.id
          );
          this.taxes.splice(index, 1, response.data);
          this.selected_rows = [];
        })
        .catch((e) => {
          console.log(`Error while updating a tax ! : ${e}`);
        });
    },
    delete_tax() {
      document.getElementById("edit_close_modal").click();
      this.$http
        .delete(`/inventory/taxes`, {
          data: { pks: this.selected_rows },
        })
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: "Tax Deleted Successfully",
            type: "success",
          });
          this.taxes = this.taxes.filter(
            (tax) => tax.id !== this.selected_item.id
          );
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: e.response.data,
            type: "danger",
          });
          console.log(`Error while deleting taxes ! : ${e}`);
        });
      this.selected_rows = [];
    },
    reset_modal() {
      this.form = {
        name: "",
        percentage_value: "",
      };
    },
  },
  created() {
    this.get_taxes();
  },
};
</script>

<style lang="scss" scoped>
.settings-taxes {
  width: 100%;
  height: 100%;
  .table-wrapper {
    min-height: 76%;
    max-height: 76%;
    overflow: auto;
  }
  input[type="checkbox"] {
    transform: scale(1.5);
  }
  .buttons {
    width: 60%;
    margin: 3rem auto;
    display: flex;
    gap: 1rem;
    button {
      padding: 1rem;
      flex: 1;
    }
  }
}
</style>
