import Dashboard_routes from "@/router/routes/management/dashboard/dashboard_routes";
import Reports_routes from "@/router/routes/management/reports/reports_routes";
import Products_routes from "@/router/routes/management/products/products_routes";
import Inventory_routes from "@/router/routes/management/inventory/inventory_routes";
import Users_and_security_routes from "@/router/routes/management/users_and_security/users_and_security_routes";
import Client_and_supplier_routes from "@/router/routes/management/client_and_supplier/client_and_supplier_routes";
import Purchases_routes from "@/router/routes/management/purchases/purchases_routes";
import Promo_routes from "@/router/routes/management/promos/promo_routes";
import Company_routes from "@/router/routes/management/company/company_routes";
import Branches_routes from "@/router/routes/management/branches/branches_routes";
import Setting_routes from "@/router/routes/management/settings/setting_routes";

export default [
  {
    path: "/management",
    name: "management",
    component: () => import("@/views/management/ManagementView"),
    meta: {
      title: () => {
        return "OnePos | Management";
      },
    },
  },
  ...Dashboard_routes,
  ...Reports_routes,
  ...Products_routes,
  ...Inventory_routes,
  ...Users_and_security_routes,
  ...Client_and_supplier_routes,
  ...Purchases_routes,
  ...Promo_routes,
  ...Company_routes,
  ...Branches_routes,
  ...Setting_routes,
];
