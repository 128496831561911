import permission_groups from "./permission_groups/main_ar";

export default {
  ...permission_groups,
  perms: "الصلاحيات",
  users_and_perms: "المستخدمين والصلاحيات",
  add_new_user: "اضافة مستخدم جديد",
  add_new_group: "اضافة مجموعة جديدة",
  group: "المجموعة",
  group_perms: "مجموعة الصلاحيات",
  group_name: "اسم المجموعة",
  chosen_perms: "الصلاحيات المختارة",
  change_password: "تغيير كلمة المرور",
  new_password: "كلمة المرور الجديدة",
  select_perms_group: "--- اختر مجموعة صلاحيات ----",
  group_delete_confirm: "هل أنت متأكد من حذف هذه المجموعة",

  // permission box title
  customer: "العملاء",
  users: "المستخدمين",
  groups: "المجموعات",
  permissions: "الصلاحيات",

  order_settings: "اعدادات البيع",
  order: "الفواتير",
  addons: "الاضافات",
  itembox: "صندوق العناصر",
  scale_settings: "اعدادات الميزان",

  inventory_count: "الجرد",
  inventory_damage: "التالف",

  branch: "الفروع",
  company: "الشركة",

  product: "المنتجات",
  refund: "المرتجع",
  stock_transfer: "تحويل المخزون",
  tax: "الضرائب",
  payment_amount: "مبالغ الدفع",
  payment_type: "أنواع الدفع",
  promo: "العروض الترويجية",
  purchase: "المشتريات",
};
