import { fixed_float, get_percentage } from "@/helpers";
import { get_tax_total_and_subtotal } from "@/js/counts";
import { OrderItem } from "@/js/sell_screen/order/order_item";

class PromoItem extends OrderItem {
  // eslint-disable-next-line no-unused-vars
  blueprint = {
    id: 41,
    is_conditional: false,
    is_all: false,
    apply_to_all: "0.00",
    apply_to_next: "0.00",
    discount_in_percentage: "0.00",
    discount_in_fixed_price: "5.00",
    timestamp: "2023-12-17T17:18:55.522462Z",
    promo: 30,
    product: 10,
  };

  constructor(order_item) {
    super(order_item.product);

    this.is_fixed = order_item.promo.discount_in_fixed_price > 0;
    this.is_percentage = order_item.promo.discount_in_percentage > 0;

    this.is_conditional = order_item.promo.is_conditional;
    // if the option is set apply_to_all otherwise it is on apply_to_next
    this.is_all = order_item.promo.is_all;
    this.apply_to_all = order_item.promo.apply_to_all;
    this.apply_to_next = order_item.promo.apply_to_next;

    // used to apply the star icon in sell_screen
    // and detect in database order_item that the item was a promo item
    this.is_promo = false;
  }
  not_conditional() {
    this.apply_promo_on_price();
  }

  fixed_promo_price() {
    // change the price based on fixed price in the promo
    this.price_with_tax = fixed_float(this.promo.discount_in_fixed_price);
    const { tax_total, subtotal } = get_tax_total_and_subtotal(
      this.price_with_tax,
      this.taxes
    );

    this.tax_total = tax_total;
    this.subtotal = subtotal;
  }
  percentage_promo_discount() {
    this.price_with_tax =
      this.price_with_tax -
      get_percentage(this.price_with_tax, this.promo.discount_in_percentage);

    const { tax_total, subtotal } = get_tax_total_and_subtotal(
      this.price_with_tax,
      this.taxes
    );

    this.tax_total = tax_total;
    this.subtotal = subtotal;
  }
  apply_promo_on_price() {
    if (this.is_fixed) {
      this.fixed_promo_price();
    } else if (this.is_percentage) {
      this.percentage_promo_discount();
    }

    if (this.is_fixed || this.is_percentage) {
      this.is_promo = true;
    }
  }
}

export { PromoItem };
