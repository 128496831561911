const state = {
  active_input: null,
  qty: 0,
};

const actions = {
  set_active_input({ commit }, node) {
    commit("set_active_input", node);
  },
  set_quantity({ commit }, qty) {
    commit("set_quantity", qty);
  },
  reset_quantity({ commit }) {
    commit("reset_quantity");
  },
};
const mutations = {
  set_active_input: (state, node) => {
    state.active_input = node;
  },
  set_quantity: (state, qty) => {
    state.qty = parseInt(qty);
  },
  reset_quantity: (state) => {
    state.qty = 0;
  },
};
const getters = {
  active_input: (state) => {
    return state.active_input;
  },
  get_current_qty: (state) => {
    return state.qty;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
