<template>
  <input
    ref="search_input"
    v-model="search_val"
    @input="name_search"
    :type="input_type || 'search'"
    :class="search_css_class"
    :style="search_css_style"
    :placeholder="$t('common.search_client_or_supplier')"
    class="pos-input"
  />
</template>

<script>
export default {
  name: "search_client_or_supplier",
  props: [
    "parent_component",
    "search_css_class",
    "search_css_style",
    "input_type",
    // custom operations before client pushed into the parents array
    // should return clients array
    "search_mixin",
  ],
  computed: {
    parent() {
      return this.parent_component || this.$parent;
    },
  },
  data() {
    return {
      search_val: "",
    };
  },
  methods: {
    name_search() {
      this.$parent.searched_clients = [];
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => {
        this.get_clients_request();
      }, 800);
    },

    alert_client_not_found(response) {
      if (response.data.results.length === 0) {
        this.$store.dispatch("utils/push_alert", {
          message: `Client or supplier Not found`,
          type: "danger",
        });
      }
    },
    get_clients_request() {
      if (this.search_val.length > 0) {
        this.$http
          .get(`/accounts/get_customer_or_supplier/?name=${this.search_val}`)
          .then((response) => {
            this.parent.searched_clients = response.data.results;
            this.alert_client_not_found(response);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  position: absolute;
  top: 0.28rem;
  left: 0.28rem;
  width: 32px;
  height: 32px;
}
.search-bar {
  font-family: "Manrope", serif;
  font-style: normal;
  font-weight: 500;
  //font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.6);
  background: #f8f9fc;
  border: 1px solid #e0e9f4;
  border-radius: 8px;
  padding-left: 2.2rem;
}
</style>
