<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('common.products'), route: 'products_report' },
      ]"
    />
    <div class="pt-3">
      <report_filters ref="report_filters_comp" />
    </div>
    <div class="d-flex justify-content-md-end justify-content-center p-3 gap-2">
      <button @click="get_products" class="btn btn-primary mt-3">
        {{ $t("reports.show_report") }}
      </button>
      <button
        @click="get_pdf_file"
        class="btn btn-warning mt-3"
        :disabled="this.report_products.length === 0"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
    </div>

    <div v-show="report_products.length > 0" class="table-wrapper p-3">
      <table class="table table-striped responsive-table text-center">
        <thead class="sticky-table-header">
          <tr>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th>{{ $t("common.total_before_tax") }}</th>
            <th>{{ $t("common.total") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product of report_products" :key="product.id">
            <td :data-label="$t('common.product_name')">
              {{ product.product__name }}
            </td>
            <td :data-label="$t('common.quantity')">{{ product.quantity }}</td>
            <td :data-label="$t('common.total_before_tax')">
              {{ product.total_before_tax }}
            </td>
            <td :data-label="$t('common.total')">
              {{ product.total_after_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import report_filters from "@/components/settings/reports/report_filters";
import { clean_url_params } from "@/helpers";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "ProductsReport",
  components: {
    report_filters,
    section_route,
  },
  data() {
    return {
      route_url: `/reports/inventory_products/`,
      filter_url: null,
      report_products: [],
    };
  },
  methods: {
    get_filter_url() {
      const { product_name, user, client, from_date, to_date } =
        this.$refs.report_filters_comp.get_filters();

      const filter_url = `?user=${user}&product=${product_name}&client=${client}&from_date=${from_date}&to_date=${to_date}`;
      return clean_url_params(filter_url);
    },
    get_products() {
      const cleaned_url = this.get_filter_url();
      this.filter_url = `${this.route_url}?${cleaned_url}`;

      this.$http
        .get(this.filter_url)
        .then((response) => {
          this.report_products = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.filter_url || this.route_url, "products.pdf", "&");
    },
  },
  mounted() {
    this.get_products();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 50rem;
  max-height: 50rem;
  overflow: scroll;
}
</style>
