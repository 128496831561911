var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"users_security full-height"},[_c('div',{staticClass:"d-flex flex-column flex-md-row gap-3 full-height"},[_c('div',{staticClass:"layout-sidebar col-12 col-md-3"},[_c('section_route',{staticClass:"pb-2",attrs:{"routes":[
          { name: _vm.$t('sidebar.management'), route: 'management' },
          {
            name: _vm.$t('sidebar.users_and_security'),
            route: 'users_crud',
          },
        ]}}),_c('div',{staticClass:"layout-menu-buttons d-flex flex-wrap gap-3"},[_c('router-link',{staticClass:"products",attrs:{"custom":"","to":{ name: 'users_crud' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('button',{staticClass:"col col-md-12",class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("common.users"))+" ")])]}}])}),_c('router-link',{staticClass:"col col-md-12",attrs:{"custom":"","to":{ name: 'users_permissions' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('button',{staticClass:"col col-md-12",class:[isActive && 'section-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("users_and_security.perms"))+" ")])]}}])})],1)],1),_c('div',{staticClass:"content-wrapper col"},[_c('div',{staticClass:"router-wrapper"},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }