const state = {
  settings: {
    scale: {
      price_decimal_places: -1,
      weight_decimal_places: -1,
      enabled: false,
      name: "", // i.e ean 13
      pattern: "", // i.e 1-5-6-1
      price: false,
    },
    id: -1,
    prevent_negative: false,
    reset_invoice: false,
    allow_price_changing: false,
    discount_type: "after_tax",
    stock: -1,
  },
};

const actions = {
  set_settings({ commit }, settings) {
    commit("set_settings", settings);
  },
};
const mutations = {
  set_settings: (state, settings) => {
    state.settings = settings;
  },
};
const getters = {
  get_settings: (state) => {
    return state.settings;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
