import purchase from "./purchase/purcahse_en.js";

export default {
  purchase,
  reports: "Reports",
  report: "Report",

  // inventory
  product_groups: "Product Groups",

  // customers
  customer_orders: "Customer Orders",

  // taxes
  tax_rates: "Tax Rates",

  // invoices
  invoices: "Invoices",
  invoice_list: "Invoice List",

  // payments
  payments: "Payments",
  payment_types: "Payment Types",
  payment_by_users: "Payments By Users",
  payment_types_by_customers: "Payment types by Customers",

  // refunds
  refunds: "Refunds",
  refunds_list: "Refunds List",

  // sales
  daily_sales: "Daily Sales",

  // purchases
  purchase_products: "Purchase Products",
  purchase_suppliers: "Purchase Suppliers",
  purchase_discount: "Purchase Discounts",
  purchase_invoices: "Purchase Invoices",

  // loss & damage
  loss_and_damage: "Loss & Damage",

  // common
  show_report: "Show Report",
  save_as_pdf: "Save As PDF",
};
