import ItemBoxMain from "@/views/management/products/item_box/ItemBoxMain";
import ViewItemBox from "@/views/management/products/item_box/ViewItemBox";
import CreateItemBox from "@/views/management/products/item_box/CreateItemBox";
import UpdateItemBox from "@/views/management/products/item_box/UpdateItemBox";

export default [
  {
    path: "/management/item_boxes",
    name: "item_boxes",
    component: ItemBoxMain,
    meta: {
      title: () => {
        return `OnePos | ItemBoxes`;
      },
    },
  },
  {
    path: "/management/item_boxes/item/:id",
    name: "view_item_box",
    component: ViewItemBox,
    meta: {
      title: (route) => {
        return `OnePos | ItemBox ${route.params.id}`;
      },
    },
  },
  {
    path: "/management/item_boxes/new_item_box",
    name: "new_item_box",
    component: CreateItemBox,
    meta: {
      title: () => {
        return `OnePos | New ItemBox`;
      },
    },
  },
  {
    path: "/management/item_boxes/update_item_box/:id",
    name: "update_item_box",
    component: UpdateItemBox,
    meta: {
      title: (route) => {
        return `OnePos | Update ItemBox ${route.params.id} `;
      },
    },
  },
];
