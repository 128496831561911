import DailySales from "@/views/management/reports/sales_reports/DailySales";

export default [
  {
    path: "/management/reports/daily_sales/",
    component: DailySales,
    name: "daily_sales",
    meta: {
      title: () => {
        return "OnePos | Refunds Daily Sales";
      },
    },
  },
];
