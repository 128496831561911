<template>
  <div class="database-settings">
    <div class="sec-name">Database</div>
    <div class="mt-4">
      <div class="line">
        <div class="left">
          <p class="header">Import Database</p>
          <p class="desc">
            Import clients or products that you have saved before
          </p>
        </div>
        <!-- /.left -->
        <div class="right">
          <label>
            <input type="radio" />
            Clients
          </label>
          <label>
            <input type="radio" />
            Products
          </label>
          <button class="pos-button">IMPORT</button>
          <!-- /.pos-button -->
        </div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
      <div class="line">
        <div class="left">
          <p class="header">Export Database</p>
          <p class="desc">backup your clients or products Database</p>
        </div>
        <!-- /.left -->
        <div class="right">
          <label>
            <input type="radio" />
            Clients
          </label>
          <label>
            <input type="radio" />
            Products
          </label>
          <button class="pos-button">&nbsp;&nbsp;Export&nbsp;</button>
          <!-- /.pos-button -->
        </div>
        <!-- /.right -->
      </div>
      <!-- /.line -->
    </div>
    <!-- /.mt-3 -->
  </div>
  <!-- /.database-settings -->
</template>

<script>
export default {
  name: "DatabaseSettings",
};
</script>

<style lang="scss" scoped>
.database-settings {
  width: 100%;
  height: 100%;
  .line {
    display: flex;
    justify-content: space-between;

    .header {
      font-size: 20px;
      color: $secondary-500;
      padding: 0;
      margin: 0;
    }
    .desc {
      color: #808080;
    }
    .left {
      flex-basis: 40%;
      //border: 1px solid red;
    }
    .right {
      flex-basis: 40%;
      display: flex;
      justify-content: center; /* center flex items vertically */
      align-items: center; /* center flex items horizontally */
      input {
        transform: scale(1.2);
      }
      label {
        font-size: 16.5px;
        font-weight: bold;
        //margin: 0 1rem;
      }
      & > * {
        //flex: 1 0;
        flex-grow: 1;
      }
    }
  }

  button {
    padding: 1rem;
    flex: 1;
  }
}
</style>
