<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.purchase_invoices'), route: 'purchase_invoices' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="purchases.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="purchases.length > 0" class="table-wrapper p-3">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("common.suppliers") }}</th>
              <th>{{ $t("common.user") }}</th>
              <th>{{ $t("reports.external_number") }}</th>
              <th>{{ $t("common.date") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(purchase, index) of purchases" :key="index">
              <td>{{ purchase.id }}</td>
              <td>{{ purchase.supplier__name }}</td>
              <td>{{ purchase.created_by__username }}</td>
              <td>{{ purchase.external_id }}</td>
              <td>{{ purchase.timestamp__date }}</td>
              <td>{{ purchase.total }}</td>
            </tr>
            <tr v-if="meta_data.meta_data">
              <td v-for="index in 5" :key="index"></td>
              <td>{{ meta_data.meta_data.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "PurchaseDiscount",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/purchase_invoices/`,
      purchases: [],
      meta_data: {},
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.meta_data = response.data.pop();
          this.purchases = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching purchase invoices : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "purchase_invoices.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 60rem;
  max-height: 60rem;
  overflow: scroll;
}
</style>
