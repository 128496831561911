const state = {
  invoice_discount: "",
  // new product modal bindings
  selected_product: {
    // cost_with_tax: 0,
    tax: [{}],
  },
  // invoice counted subtotal, tax, total in the footer component
  counted_subtotal: 0,
  counted_tax: 0,
  counted_total: 0,
};

const actions = {
  set_selected_product({ commit }, product) {
    commit("set_selected_product", product);
  },
  clear_selected_product({ commit }) {
    commit("clear_selected_product");
  },

  set_invoice_discount({ commit }, val) {
    commit("set_invoice_discount", val);
  },
  // invoice counted subtotal, tax, total
  set_counted_subtotal({ commit }, val) {
    commit("set_counted_subtotal", val);
  },
  set_counted_tax({ commit }, val) {
    commit("set_counted_tax", val);
  },
  set_counted_total({ commit }, val) {
    commit("set_counted_total", val);
  },
};
const mutations = {
  set_selected_product: (state, product) => {
    state.selected_product = product;
  },
  clear_selected_product: (state) => {
    state.selected_product = {};
  },

  set_invoice_discount: (state, val) => {
    state.invoice_discount = val;
  },

  // invoice counted subtotal, tax, total
  set_counted_subtotal: (state, val) => {
    state.counted_subtotal = val;
  },
  set_counted_tax: (state, val) => {
    state.counted_tax = val;
  },
  set_counted_total: (state, val) => {
    state.counted_total = val;
  },
};
const getters = {
  get_invoice_discount: (state) => {
    return state.invoice_discount;
  },
  get_selected_product: (state) => {
    return state.selected_product;
  },

  // invoice counted subtotal, tax, total
  get_counted_subtotal: (state) => {
    return state.counted_subtotal;
  },
  get_counted_tax: (state) => {
    return state.counted_tax;
  },
  get_counted_total: (state) => {
    return state.counted_total;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
