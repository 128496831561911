import ReportsView from "@/views/management/reports/ReportsView";
import Inventory_reports_routes from "@/router/routes/management/reports/inventory_reports/inventory_reports_routes";
import Customer_reports_routes from "@/router/routes/management/reports/customer_reports/customer_reports_routes";
import Tax_reports_routes from "@/router/routes/management/reports/tax_reports/tax_reports_routes";
import Invoices_reports_routes from "@/router/routes/management/reports/invoices_reports/invoices_reports_routes";
import Payments_reports_routes from "@/router/routes/management/reports/payments_reports/payments_reports_routes";
import Refunds_reports_routes from "@/router/routes/management/reports/refunds_reports/refunds_reports_routes";
import Sales_reports_routes from "@/router/routes/management/reports/sales_reports/sales_reports_routes";
import Purchases_reports_routes from "@/router/routes/management/reports/purchases_reports/purchases_reports_routes";
import Damaged_reports_routes from "@/router/routes/management/reports/damaged_reports/damaged_reports_routes";

export default [
  {
    path: "/management/reports",
    name: "reports",
    component: ReportsView,
    meta: {
      title: () => {
        return "OnePos | Reports";
      },
    },
  },
  ...Inventory_reports_routes,
  ...Customer_reports_routes,
  ...Tax_reports_routes,
  ...Invoices_reports_routes,
  ...Payments_reports_routes,
  ...Refunds_reports_routes,
  ...Sales_reports_routes,
  ...Purchases_reports_routes,
  ...Damaged_reports_routes,
];
