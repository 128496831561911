<template>
  <div class="dashboard">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('sidebar.dashboard'), route: 'dashboard' },
      ]"
    />
    <div class="d-flex flex-column gap-3">
      <div class="cards row row-wrap gap-2 justify-content-center ms-sm-0 ms-2">
        <dashboard_card
          :card_title="$t('dashboard.orders')"
          :icon_name="'CashIcon'"
          :report_data="this.cards.orders"
          :filter_fn="orders_filter_fn"
          class="col-lg col-sm-5 col-12"
        />
        <dashboard_card
          :card_title="$t('dashboard.refunds')"
          :icon_name="'RefundIcon'"
          :card_type_class="'pink-card'"
          :statics_naming="{
            total_text: $t('dashboard.total_refunds'),
            count_text: $t('dashboard.refunds_count'),
          }"
          :report_data="this.cards.refunds"
          :filter_fn="refund_filter_fn"
          class="col-lg col-sm-5 col-12"
        />
        <dashboard_card
          :icon_name="'InventoryCountIcon'"
          :card_title="$t('dashboard.inventory_count')"
          :card_type_class="'green-card'"
          :statics_naming="{
            total_text: $t('dashboard.total_value'),
            count_text: $t('dashboard.counted_items'),
          }"
          :report_data="this.cards.inventory_count"
          :filter_fn="inventory_count_filter_fn"
          class="col-lg col-sm-5 col-12"
        />
        <dashboard_card
          :card_title="$t('dashboard.purchase')"
          :icon_name="'PurchaseIcon'"
          :card_type_class="'yellow-card'"
          :report_data="this.cards.purchases"
          :filter_fn="purchase_filter_fn"
          class="col-lg col-sm-5 col-12"
        />
      </div>
      <div class="row section">
        <div class="col-md-9">
          <orders_chart
            :graph_data="monthly_sales.graph"
            :key="components_keys.sales_graph_key"
          />
        </div>
        <div class="col-md-3">
          <orders_per_hour_chart
            :orders_data="orders_per_hour"
            :key="components_keys.orders_per_hour_key"
          />
        </div>
      </div>
      <div class="row row-wrap gap-3">
        <div class="row col-md-12">
          <!-- Top Cashiers Table -->
          <div class="col-md-8 col-12">
            <h3>{{ $t("dashboard.top_cashiers") }}</h3>
            <table class="table table-dark table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{{ $t("common.cashier") }}</th>
                  <th class="text-center">
                    {{ $t("dashboard.orders_count") }}
                  </th>
                  <th>{{ $t("dashboard.sales") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cashier, index) in this.top_cashiers" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="
                        '/media/' + (cashier.avatar || 'default_avatar.png')
                      "
                      alt="customer photo"
                      width="44"
                      height="44"
                    />
                    <span class="ms-3">{{ cashier.name }}</span>
                  </td>
                  <td class="text-center">{{ cashier.orders_count }}</td>
                  <td>${{ cashier.sales }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- EndOf Top Cashiers Table -->

          <!-- Top Customers Table -->
          <div class="col-md-4 col-12">
            <h3>{{ $t("dashboard.top_customers") }}</h3>
            <table class="table table-success table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{{ $t("common.client") }}</th>
                  <th>{{ $t("common.last_visit") }}</th>
                  <th class="text-center">
                    {{ $t("dashboard.orders_count") }}
                  </th>
                  <th>{{ $t("common.value") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(customer, index) in this.top_customers"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="
                        '/media/' + (customer.avatar || 'default_avatar.png')
                      "
                      alt="customer photo"
                      width="44"
                      height="44"
                    />
                    <span class="ms-3">{{ customer.name }}</span>
                  </td>
                  <td>{{ customer.last_visit | localize_date }}</td>
                  <td class="text-center">{{ customer.orders_count }}</td>
                  <td>${{ customer.total_spent }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- EndOf Customers Table -->
        </div>

        <!-- Doughnut's -->
        <div class="row row-wrap justify-content-md-between col-12">
          <!-- Top Products Doughnut -->
          <div class="col-md-3 col-sm-6 col-12">
            <h3>{{ $t("dashboard.top_products") }}</h3>
            <div>
              <top_products_doughnut
                :products_data="top_products"
                :key="components_keys.top_products_key"
              />
            </div>
          </div>
          <!-- Top Products Doughnut -->

          <!-- Top Product Groups Doughnut -->
          <div class="col-md-3 col-sm-6 col-12">
            <h3>{{ $t("dashboard.top_product_groups") }}</h3>
            <div>
              <top_product_groups_doughnut
                :groups_data="top_products_groups"
                :key="components_keys.top_products_groups_key"
              />
            </div>
          </div>
          <!-- Top Product Groups Doughnut -->

          <!-- Top Payments Doughnut -->
          <div class="col-md-3 col-sm-6 col-12">
            <h3>{{ $t("dashboard.top_payment_types") }}</h3>
            <div>
              <top_payment_methods_doughnut
                :payment_data="top_payments_methods"
                :key="components_keys.top_payments_methods_key"
              />
            </div>
          </div>
          <!-- Top Payments Doughnut -->
        </div>
        <!-- EndOf Doughnut's -->

        <!-- Top Refunds Table -->
        <div class="col-md-12 mb-5">
          <h3>{{ $t("dashboard.top_cashiers_make_refunds") }}</h3>
          <table class="table table-danger table-striped">
            <thead>
              <tr>
                <th>No.</th>
                <th>{{ $t("common.cashier") }}</th>
                <th class="text-center">{{ $t("dashboard.orders_count") }}</th>
                <th>{{ $t("dashboard.refund_total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(refund, index) in top_refunds"
                :key="refund.created_by"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <img
                    :src="
                      '/media/' +
                      (refund.created_by_avatar || 'default_avatar.png')
                    "
                    alt=""
                    width="44"
                    height="44"
                  />
                  <span class="ms-3">{{ refund.cashier }}</span>
                </td>
                <td class="text-center">{{ refund.count }}</td>
                <td>{{ refund.refunds_total }}$</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Top Refunds Table -->
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";

import dashboard_card from "@/components/dashboard/dashboard_card";
import orders_chart from "@/components/dashboard/orders_chart";
import orders_per_hour_chart from "@/components/dashboard/orders_per_hour_chart";
// Doughnuts
import top_products_doughnut from "@/components/dashboard/top_products_doughnut";
import top_product_groups_doughnut from "@/components/dashboard/top_product_groups_doughnut";
import top_payment_methods_doughnut from "@/components/dashboard/top_payment_methods_doughnut";

import { mapActions } from "vuex";

export default {
  name: "DashboardView",
  components: {
    section_route,
    dashboard_card,
    orders_chart,
    orders_per_hour_chart,

    top_products_doughnut,
    top_product_groups_doughnut,
    top_payment_methods_doughnut,
  },
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  ${datetime.toLocaleTimeString()}
      `;
    },
  },
  data() {
    return {
      cards: {
        orders: {
          total: 0,
          count: 0,
        },
        refunds: {
          total: 0,
          count: 0,
        },
        inventory_count: {
          total: 0,
          count: 0,
        },
        purchases: {
          total: 0,
          count: 0,
        },
      },
      monthly_sales: {
        graph: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        best_month: {},
      },
      orders_per_hour: [],
      top_customers: [],
      top_cashiers: [],

      top_products: [],
      top_products_groups: [],

      top_payments_methods: [],
      top_refunds: [],

      // force components to rerender by changing the keys
      components_keys: {
        sales_graph_key: 0,
        orders_per_hour_key: 0,
        top_products_key: 0,
        top_products_groups_key: 0,
        top_payments_methods_key: 0,
      },
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden"]),
    get_dashboard_data() {
      this.$http
        .get(`/reports/branch_dashboard`)
        .then((response) => {
          console.log("response: ", response.data);
          this.cards = response.data.cards;

          // monthly sales
          this.monthly_sales.graph = response.data.monthly_sales.graph;
          this.monthly_sales.best_month =
            response.data.monthly_sales.best_month;
          this.components_keys.sales_graph_key += 1;

          // orders per hour
          this.orders_per_hour = response.data.orders_per_hour;
          this.components_keys.orders_per_hour_key += 1;

          // top customers
          this.top_customers = response.data.top_customers;

          // top cashiers
          this.top_cashiers = response.data.top_cashiers;

          // top products
          this.top_products = response.data.top_products;
          this.components_keys.top_products_key += 1;

          // top products Groups
          this.top_products_groups = response.data.top_products_groups;
          this.components_keys.top_products_groups_key += 1;

          // top payments methods
          this.top_payments_methods = response.data.top_payments_methods;
          this.components_keys.top_payments_methods_key += 1;

          // top cashiers made refunds
          this.top_refunds = response.data.top_refunds;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
          }
          console.log(`Error while getting dashboard data ! : ${e}`);
        });
    },
    orders_filter_fn(date) {
      this.$http
        .get(`/reports/cards_filter?type=orders&date=${date}`)
        .then((response) => {
          this.cards.orders = response.data;
        });
    },
    refund_filter_fn(date) {
      this.$http
        .get(`/reports/cards_filter?type=refunds&date=${date}`)
        .then((response) => {
          this.cards.refunds = response.data;
        });
    },
    inventory_count_filter_fn(date) {
      this.$http
        .get(`/reports/cards_filter?type=inventory_count&date=${date}`)
        .then((response) => {
          console.log("response: ", response);
          this.cards.inventory_count = response.data;
        });
    },
    purchase_filter_fn(date) {
      this.$http
        .get(`/reports/cards_filter?type=purchase&date=${date}`)
        .then((response) => {
          this.cards.purchases = response.data;
        });
    },
  },
  mounted() {
    this.get_dashboard_data();
  },
};
</script>

<style lang="scss" scoped>
.section {
  min-height: 300px;
  max-height: 300px;
}
.table {
  border: 1px solid white;
  border-radius: 5px !important;
}

.table-danger {
  --bs-table-bg: #ffa07a;
  --bs-table-striped-bg: #f08080;
}
</style>
