export default [
  {
    name: "permissions_new_group",
    path: "permissions/new_group/",
    component: () => import("@/views/management/users_and_secuirty/GroupCrud"),
    meta: {
      title: () => {
        return "OnePos | New Permission Group";
      },
    },
  },
  {
    name: "permissions_group_details",
    path: "permissions/group_details/:group_id",
    component: () => import("@/views/management/users_and_secuirty/GroupCrud"),
    meta: {
      title: (route) => {
        return `OnePos | ${route.params.group_id}  Group`; // manually edited in the view
      },
    },
  },
];
