<template>
  <div class="table-wrapper">
    <table class="primary-table mt-2">
      <thead>
        <tr>
          <th>ID</th>
          <th>{{ $t("common.name") }}</th>
          <th>{{ $t("common.quantity") }}</th>
          <th>{{ $t("common.price_before_tax") }}</th>
          <th>{{ $t("common.tax") }}</th>
          <th>{{ $t("common.price") }}</th>
          <th>{{ $t("common.discount") }}</th>
          <th>{{ $t("common.tax_total") }}</th>
          <th>{{ $t("common.total_before_tax") }}</th>
          <th>{{ $t("common.total") }}</th>
          <th>{{ $t("common.control") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">
          <td>{{ product.id }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.quantity }}</td>

          <td>{{ product.subtotal }}</td>

          <td v-if="product.tax">
            <span v-for="t in product.tax" :key="t.id"
              >{{ t.percentage_value }}%</span
            >
          </td>
          <td v-else></td>
          <td>{{ product.price_with_tax }}</td>

          <td>{{ product.discount }}</td>
          <td>{{ product.tax_total }}</td>
          <td>{{ product.subtotal }}</td>
          <td>
            {{ product.total }}
          </td>
          <td>
            <button
              @click="
                select_product(product.id);
                edit_item();
              "
              class="btn btn-success mx-1"
            >
              <img :src="$get_icon('edit')" alt="" />
            </button>
            <button
              @click="select_product(product.id)"
              data-bs-toggle="modal"
              data-bs-target="#delmodal"
              class="btn btn-danger mx-1"
            >
              <img :src="$get_icon('delete')" alt="" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MiddleSection",
  props: ["products"],

  data() {
    return {};
  },
  methods: {
    ...mapActions("purchase_crud", ["set_selected_product"]),
    async select_product(product_id) {
      const product = this.products.find(
        (product) => product.id === product_id
      );
      this.set_selected_product(product);

      // in case of update recalculate selected product total
      await this.$nextTick();
      this.$parent.qty_changes();
    },
    edit_item() {
      this.$parent.current_action = "update";
      this.$parent.update_product();
    },
    delete_item() {},
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  min-height: 50%;
  max-height: 50%;
  overflow: auto;
}
</style>
