export default {
  days_of_week: "Days Of Week",
  start_date: "Start Date",
  end_date: "End Date",

  new_promo: "New Promo",
  edit_promo: "Edit Promo",

  promo_name: "Promo Name",
  quantity_condition: "Quantity Condition",

  apply_to_all: "Apply To All",
  apply_to_next: "Apply To Next",
};
