export default function routes(vm) {
  return [
    // {
    //   // shows the text on collapse
    //   header: false,
    //   title: "Main Navigation",
    //   // collapse nested sections
    //   hiddenOnCollapse: true,
    // },
    {
      href: vm.$get_route_url("user_profile"),
      title: vm.$t("sidebar.profile"),
      icon: {
        element: "img",
        attributes: { src: vm.$get_icon("UserIcon") },
      },
    },
    {
      href: vm.$get_route_url("sell_screen"),
      title: vm.$t("sidebar.sell"),
      // icon: "fa fa-chart-area",
      icon: {
        element: "img",
        attributes: { src: vm.$get_icon("HomeIcon") },
      },
    },
    {
      href: vm.$get_route_url("history"),
      title: vm.$t("sidebar.history"),
      // icon: "fa fa-user",
      icon: {
        //adjust element
        element: "img",
        attributes: { src: vm.$get_icon("HistoryIcon") },
      },
    },
    {
      // href: vm.$get_route_url("settings"),
      title: vm.$t("sidebar.management"),
      // icon: "fa fa-chart-area",
      icon: {
        //adjust element
        element: "img",
        attributes: { src: vm.$get_icon("SettingIcon") },
      },
      child: [
        {
          href: vm.$get_route_url("dashboard"),
          title: vm.$t("sidebar.dashboard"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("DashboardIcon") },
          },
        },
        {
          href: vm.$get_route_url("reports"),
          title: vm.$t("sidebar.data_report"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("DataReportIcon") },
          },
        },
        {
          // href: vm.$get_route_url("management_products"),
          title: vm.$t("sidebar.products"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("ProductIcon") },
          },
          child: [
            {
              href: vm.$get_route_url("management_products"),
              title: vm.$t("sidebar.all_products"),
              icon: {
                element: "img",
                attributes: { src: vm.$get_icon("AllProductsIcon") },
              },
            },
            {
              href: vm.$get_route_url("addons"),
              title: vm.$t("sidebar.addons"),
              icon: {
                element: "img",
                attributes: { src: vm.$get_icon("AddonsIcon") },
              },
            },
            {
              href: vm.$get_route_url("item_boxes"),
              title: vm.$t("sidebar.item_box"),
              icon: {
                element: "img",
                attributes: { src: vm.$get_icon("ItemBoxIcon") },
              },
            },
            {
              href: vm.$get_route_url("price_tags"),
              title: vm.$t("sidebar.price_tags"),
              icon: {
                element: "img",
                attributes: { src: vm.$get_icon("PriceTagsIcon") },
              },
            },
          ],
        },
        {
          href: vm.$get_route_url("stock"),
          title: vm.$t("sidebar.inventory"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("InventoryIcon") },
          },
        },
        {
          href: vm.$get_route_url("users_crud"),
          title: vm.$t("sidebar.users_and_security"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("SecurityIcon") },
          },
        },
        {
          href: vm.$get_route_url("client_and_supplier"),
          title: vm.$t("sidebar.clients_and_suppliers"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("ClientsIcon") },
          },
        },
        {
          href: vm.$get_route_url("all_purchases"),
          title: vm.$t("sidebar.purchases"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("PurchaseIcon") },
          },
        },
        {
          href: vm.$get_route_url("promos"),
          title: vm.$t("sidebar.promos"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("PromoIcon") },
          },
        },
        {
          href: vm.$get_route_url("company"),
          title: vm.$t("sidebar.company"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("CompanyIcon") },
          },
        },
        {
          href: vm.$get_route_url("branches"),
          title: vm.$t("sidebar.branches"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("BranchesIcon") },
          },
        },
        {
          href: vm.$get_route_url("zatca_main"),
          title: vm.$t("sidebar.zatca"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("ZatcaIcon") },
          },
        },
        {
          href: vm.$get_route_url("management_payment"),
          title: vm.$t("sidebar.settings"),
          icon: {
            element: "img",
            attributes: { src: vm.$get_icon("SettingsIcon") },
          },
        },
      ],
    },
    {
      // href: vm.$get_route_url("logout"),
      title: vm.$t("sidebar.logout"),
      icon: {
        element: "img",
        attributes: { src: vm.$get_icon("LogoutIcon") },
      },
    },
  ];
}
