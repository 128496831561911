<template>
  <div class="branches pb-5">
    <!--        NEW   BRANCH MODAL -->
    <modal :modal_id="'new-modal'">
      <template #title-content>
        {{ $t("branches.create_new_branch") }}
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.name"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.branch_name")
            }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.telephone"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("common.telephone")
            }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.phone"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.phone") }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.city"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.city") }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.governorate"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("common.governorate")
            }}</label>
          </div>

          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.work_hours"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.work_hours")
            }}</label>
          </div>
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.place"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.place") }}</label>
          </div>
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.description"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.description")
            }}</label>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="close_current_modal()" class="pos-button px-5 py-3">
          {{ $t("common.delete") }}
        </button>
        <button @click="new_branch" class="pos-primary-btn px-5 py-3">
          {{ $t("common.add") }}
        </button>
      </template>
    </modal>
    <!--        END OF NEW BRANCH MODAL-->

    <!--    DELETE CONFIRMATION MODAL -->
    <div class="modal fade" id="del-modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.2rem">
              {{ $t("branches.delete_confirm") }}
            </span>
            <span class="text-success" style="font-size: 1.2rem">
              <span style="font-size: 1.2rem">
                {{ current_branch.name }}
              </span>
            </span>
          </div>

          <div class="modal-footer">
            <button @click="confirm_delete_branch" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button
              @click="close_current_modal()"
              class="btn btn-secondary px-5"
            >
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <!--    END OF DELETE CONFIRMATION  MODAL-->

    <!--        Update BRANCH MODAL -->
    <modal :modal_id="'update-modal'">
      <template #title-content>
        {{ $t("common.edit") }} {{ current_branch.name }}
        {{ $t("common.branch") }}</template
      >
      <template #body>
        <div class="row">
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.name"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.branch_name")
            }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.telephone"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("common.telephone")
            }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.phone"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.phone") }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.city"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.city") }}</label>
          </div>
          <div class="col-md-6 did-floating-label-content">
            <input
              v-model="current_branch.governorate"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("common.governorate")
            }}</label>
          </div>

          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.work_hours"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.work_hours")
            }}</label>
          </div>
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.place"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{ $t("common.place") }}</label>
          </div>
          <div class="col-md-12 did-floating-label-content">
            <input
              v-model="current_branch.description"
              class="did-floating-input"
              type="text"
              placeholder=""
            />
            <label class="did-floating-label">{{
              $t("branches.description")
            }}</label>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="update_branch" class="pos-primary-btn px-5 py-3">
          {{ $t("common.save") }}
        </button>
        <button @click="close_current_modal()" class="pos-button px-5 py-3">
          {{ $t("common.cancel") }}
        </button>
      </template>
    </modal>
    <!--        END OF Update BRANCH MODAL-->

    <div class="d-flex justify-content-between">
      <div class="col">
        <section_route
          :routes="[
            { name: $t('sidebar.management'), route: 'management' },
            {
              name: $t('sidebar.branches'),
              route: '',
            },
          ]"
          class="pb-2"
        />
      </div>
      <div class="col d-flex justify-content-end">
        <button
          @click="
            () => {
              this.current_branch = {};
            }
          "
          class="pos-primary-btn py-3 px-3 col-6"
          data-bs-toggle="modal"
          data-bs-target="#new-modal"
        >
          {{ $t("branches.create_new_branch") }}
        </button>
      </div>
    </div>

    <table class="primary-table responsive-table mt-5">
      <thead>
        <tr>
          <th>{{ $t("common.name") }}</th>
          <th>{{ $t("branches.work_hours") }}</th>
          <th>{{ $t("common.governorate") }}</th>
          <th>{{ $t("common.city") }}</th>
          <th>{{ $t("common.place") }}</th>
          <th>{{ $t("common.phone") }}</th>
          <th>{{ $t("common.telephone") }}</th>
          <th>{{ $t("common.creation_date") }}</th>
          <th>{{ $t("branches.description") }}</th>
          <th>{{ $t("common.control") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="branch in branches" :key="branch.id">
          <td :data-label="$t('common.name')">{{ branch.name }}</td>
          <td :data-label="$t('branches.work_hours')">
            {{ branch.work_hours || "-" }}
          </td>
          <td :data-label="$t('common.governorate')">
            {{ branch.governorate || "-" }}
          </td>
          <td :data-label="$t('common.city')">{{ branch.city || "-" }}</td>
          <td :data-label="$t('common.place')">{{ branch.place || "-" }}</td>
          <td :data-label="$t('common.phone')">{{ branch.phone || "-" }}</td>
          <td :data-label="$t('common.telephone')">
            {{ branch.telephone || "-" }}
          </td>
          <td :data-label="$t('common.creation_date')">
            {{ new Date(branch.created).toLocaleDateString() }}
          </td>
          <td :data-label="$t('branches.description')">
            {{ branch.description || "-" }}
          </td>
          <td :data-label="$t('common.control')">
            <div class="d-flex justify-content-center">
              <button
                @click="set_current_branch(branch.id)"
                data-bs-toggle="modal"
                data-bs-target="#update-modal"
                class="btn btn-success mx-1"
              >
                <img :src="$get_icon('edit')" alt="" />
              </button>
              <button
                @click="set_current_branch(branch.id)"
                data-bs-toggle="modal"
                data-bs-target="#del-modal"
                class="btn btn-danger mx-1"
              >
                <img :src="$get_icon('delete')" alt="" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import modal from "@/components/bootstrap_modal";
import { close_current_modal } from "@/helpers";
import { mapActions } from "vuex";

export default {
  name: "BranchesMain",
  components: {
    section_route,
    modal,
  },
  data() {
    return {
      current_branch: {},
      branches: [],
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden"]),
    // in case if it used in html template
    close_current_modal,
    get_branches() {
      this.$http
        .get(`/branches/branches`)
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
          }
          console.log(`Error while fetching branches ! : ${e}`);
        });
    },
    get_branch_by_id(branch_id) {
      return this.branches.find((branch) => branch.id === branch_id);
    },
    set_current_branch(branch_id) {
      this.current_branch = Object.assign({}, this.get_branch_by_id(branch_id));
    },
    new_branch() {
      this.$http
        .post("/branches/branches", this.current_branch)
        .then((response) => {
          this.branches.push(response.data);
          close_current_modal();
          this.$store.dispatch("utils/push_alert", {
            message: `Branch Created Successfully`,
            type: "success",
          });
        })
        .catch((e) => {
          console.log("Error while trying add new branch", e);
        });
    },
    update_branch() {
      this.$http
        .put("/branches/branches", this.current_branch)
        .then((response) => {
          const index = this.branches.findIndex(
            (branch) => branch.id === response.data.id
          );
          this.branches.splice(index, 1, response.data);
          close_current_modal();
        })
        .catch((e) => {
          console.log("Error while updating branch", e);
        });
    },
    delete_branch_request() {
      this.$http({
        method: "DELETE",
        url: "/branches/branches",
        data: { id: this.current_branch.id },
      })
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: `Branch Deleted Successfully`,
            type: "warning",
          });
          this.branches = this.branches.filter(
            (branch) => branch.id !== this.current_branch.id
          );
        })
        .catch((e) => {
          this.$store.dispatch("utils/push_alert", {
            message: `${e.response.data}`,
            type: "danger",
          });
          console.log(`Error while deleting branches ! : ${e}`);
        });
      close_current_modal();
    },
    confirm_delete_branch() {
      this.delete_branch_request();
    },
  },
  created() {
    this.get_branches();
  },
};
</script>

<style lang="scss" scoped>
.branches {
  .modal {
    z-index: 999999;
  }

  background: $main-white;
  margin: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: $main-white;
  color: #323840;
  border: 1px solid $secondary-background-color;
  border-radius: 4px;
  box-sizing: border-box;

  outline: none;
  ~ .did-floating-label {
    top: -8px;
    font-size: 13px;
  }
}
</style>
