export default {
  // join
  grow_your_business: "نمي اعمالك بسرعة",
  easy_to_start: "سهولة في البدء والاستخدام",
  all_you_need: "كل ما تحتاجه وأكثر",

  // login
  login: "تسجيل الدخول",
  error: "خطأ",
  credential_error: "إسم المستخدم او كلمة المرور غير صحيحة",
  activation_error: "عليك تفعيل حسابك اولاً تفقد بريدك الالكتروني",
  username: "اسم المستخدم",
  password: "كلمة المرور",

  signup_helper: " ليس لديك حساب ؟",
  signup: "سجل",
  signin: "سجل الدخول",

  password_reset_helper: "نسيت كلمة المرور؟",
  password_reset: "استعادة كلمة المرور",

  // register
  register: "سجل",
  country: "البلد",
  company_name: "إسم الشركة",
  signin_helper: "لديك حساب بالفعل ؟",
  email_helper: "ادخل بريدك الالكتروني وتاكد منه",
  password_helper: "يجب ان يكون من 8-20 حرف",
  re_password: "اعد كتابة كلمة المرور",
};
