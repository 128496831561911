export default {
  counted_items: "العناصر المعدودة",
  inventory_count: "الجرد",
  orders: "الطلبات",
  orders_count: "عدد الطلبات",
  orders_per_hour: "عدد الطلبات في الساعة",
  purchase: "المشتريات",
  refund_total: "اجمالي المرتجع",
  refunds: "المرتجعات",
  refunds_count: "عدد المرتجعات",
  sales: "المبيعات",
  top_cashiers: "افضل الموظفين مبيعاً",
  top_cashiers_make_refunds: "اكثر موظفين قامو باسترجاع",
  top_customers: "افضل عملاء",
  top_payment_types: "اعلي انواع الدفع",
  top_product_groups: "افضل المجموعات",
  top_products: "افضل المنتجات",
  total_refunds: "اجمالي المرتجعات",
  total_sales: "اجمالي المبيعات",
  total_value: "الاجمالي",
};
