import RefundsReport from "@/views/management/reports/refunds_reports/RefundsReport";

export default [
  {
    path: "/management/reports/refunds/",
    component: RefundsReport,
    name: "refunds_report",
    meta: {
      title: () => {
        return "OnePos | Refunds Report";
      },
    },
  },
];
