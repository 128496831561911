<template>
  <div>
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        { name: $t('reports.reports'), route: 'reports' },
        { name: $t('reports.customer_orders'), route: 'customer_orders' },
      ]"
    />
    <div class="row py-3 p-3 justify-content-end">
      <button
        @click="get_pdf_file"
        :disabled="customer_orders.length === 0"
        class="btn btn-warning mx-lg-5 col-sm-3 col-12"
      >
        {{ $t("reports.save_as_pdf") }}
      </button>
      <div v-show="customer_orders.length > 0" class="table-wrapper p-3">
        <table class="table table-striped responsive-table text-center">
          <thead class="sticky-table-header">
            <tr>
              <th>{{ $t("common.customer") }}</th>
              <th>{{ $t("common.total_before_tax") }}</th>
              <th>{{ $t("common.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(customer_order, index) of customer_orders" :key="index">
              <td :data-label="$t('common.customer')">
                {{ customer_order.client__name }}
              </td>
              <td :data-label="$t('common.total_before_tax')">
                {{ customer_order.total_before_tax }}
              </td>
              <td :data-label="$t('common.total')">
                {{ customer_order.total_after_tax }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { get_pdf } from "@/js/reports/helpers";

export default {
  name: "customer_orders",
  components: {
    section_route,
  },
  data() {
    return {
      route_url: `/reports/customer_orders/`,
      customer_orders: [],
    };
  },
  methods: {
    get_report() {
      this.$http
        .get(this.route_url)
        .then((response) => {
          this.customer_orders = response.data;
        })
        .catch((e) => {
          console.log(`Error while fetching users : ${e}`);
        });
    },
    get_pdf_file() {
      get_pdf(this.route_url, "customer_orders.pdf");
    },
  },
  mounted() {
    this.get_report();
  },
};
</script>

<style scoped></style>
