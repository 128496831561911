<template>
  <div class="payment-keypad row">
    <div class="col-9 pe-0">
      <div class="row">
        <h4 class="col-md-4">{{ $t("common.balance") }}</h4>
        <!-- /.col-md-7 -->
        <div class="col-md-8 mb-3">
          <input
            :value="$parent.order.total"
            class="form-control"
            type="text"
            disabled
          />
        </div>
        <!-- /.col-md-4 -->
        <h4 class="col-md-4">{{ $t("common.received") }}</h4>
        <!-- /.col-md-7 -->
        <div class="col-md-8 mb-2">
          <input id="r-input" class="form-control" type="text" />
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
      <main_keypad ref="main_keypad" :enter="enter"></main_keypad>
    </div>
    <div class="col shortcuts">
      <div class="row gap-1 text-center justify-content-around">
        <div @click="quick_insert($parent.r)" class="box col-md-12">
          {{ $parent.r }}
        </div>

        <div @click="quick_insert(500)" class="box col-12 col-md-5">500$</div>
        <div @click="quick_insert(200)" class="box col-12 col-md-5">200$</div>
        <div @click="quick_insert(100)" class="box col-12 col-md-5">100$</div>
        <div @click="quick_insert(50)" class="box col-12 col-md-5">50$</div>
        <div @click="quick_insert(10)" class="box col-12 col-md-5">10$</div>
        <div @click="$refs.main_keypad.insert(5)" class="box col-12 col-md-5">
          5$
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import main_keypad from "@/components/keyboards/main_keypad";

export default {
  name: "payment_keypad",
  components: {
    main_keypad,
  },
  methods: {
    enter(qty) {
      if (qty > 0) {
        this.$parent.entered_payments.push({
          ...this.$parent.current_payment,
          amount: qty,
        });
        this.$parent.calc_remaining();
      }
    },
    insert(char) {
      const node = this.$store.getters["keyboard/active_input"];
      node.value += char;
      node.focus();
    },
    quick_insert(char) {
      this.$refs.main_keypad.insert(char);
      this.$refs.main_keypad.add_quantity();
    },
    delete_char() {
      const node = this.$store.getters["keyboard/active_input"];
      node.value = node.value.slice(0, -1);
      node.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.box.col-md-6 {
  width: 48.5%;
}
.payment-keypad.row {
  padding: 1rem 0.5rem;
}
.shortcuts {
  .box {
    padding: 1.9rem 0;
    border: 2px solid $secondary-300;
    border-radius: 8px;
    &:not(:first-child) {
      //margin: 1rem auto 0 0;
    }
  }
  .box:first-child {
    margin-bottom: 20px;
  }
}
</style>
