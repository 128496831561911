export default {
  // customer
  add_customer: "إضافة مستخدمين جدد",
  change_customer: "تعديل بيانات المستخدمين",
  delete_customer: "حذف المستخدمين",
  view_customer: "عرض المستخدمين",

  // permission groups
  add_customgroup: "إضافة مجموعة صلاحيات",
  change_customgroup: "تعديل في مجموعات الصلاحيات",
  delete_customgroup: "حذف مجموعات الصلاحيات",
  view_customgroup: "عرض مجموعات الصلاحيات",

  // permissions
  add_permission: "اختيار صلاحيات",
  change_permission: "تعديل علي الصلاحيات بداخل المجموعات",
  delete_permission: "ازاله صلاحيات ",
  view_permission: "عرض الصلاحيات بداخل المجموعات",

  // user
  add_user: "إضافة مستخدمين جدد",
  change_user: "التعديل علي بيانات المستخدمين",
  delete_user: "حذف المستخدمين",
  view_user: "عرض المستخدمين",

  // branch
  add_branch: "إضافة فروع جديدة",
  change_branch: "تعديل بيانات الفروع",
  delete_branch: "حذف الفروع",
  view_branch: "عرض الفروع",

  // Company
  change_company: "تعديل في بيانات الشركة",
  view_company: "عرض بيانات الشركة",

  // settings -> Order settings
  change_ordersettings: "التعديل في اعدادات الطلبات",
  view_ordersettings: "عرض اعدادات الطلبات",

  // settings -> Scale settings
  change_scalesettings: "التعديل في اعدادات الميزان",
  view_scalesettings: "عرض اعدادات الميزان",

  // Product
  add_product: "إضافة منتجات جديدة",
  change_product: "تعديل المنتجات",
  delete_product: "حذف المنتجات",
  view_product: "عرض المنتجات",

  // Product Groups
  add_productgroups: "اضافه مجموعة صلاحيات",
  change_productgroups: "تعديل مجموعة صلاحيات",
  delete_productgroups: "حذف مجموعة صلاحيات",
  view_productgroups: "عرض مجموعة صلاحيات",

  // Product taxes
  add_producttax: "اضافة ضرائب المنتجات",
  change_producttax: "تعديل ضرائب المنتجات",
  delete_producttax: "حذف ضرائب المنتجات",
  view_producttax: "عرض ضرائب المنتجات",

  // order
  add_order: "اضافة فواتير بيع",
  change_order: "تعديل علي فواتير البيع",
  delete_order: "حذف فواتير البيع",
  view_order: "عرض فواتير البيع",

  // ItemBox
  add_itembox: "اضافة صناديق للمنتجات",
  change_itembox: "تعديل صناديق المنتجات",
  delete_itembox: "حذف صناديق المنتجات",
  view_itembox: "عرض صناديق المنتجات",

  // addons
  add_addon: "إضافة اضافات جديدة",
  change_addon: "التعديل في الاضافات",
  delete_addon: "حذف الاضافات",
  view_addon: "عرض الاضافات",

  // addons -> addon types
  add_addontype: "اضافة انواع اضافات",
  change_addontype: "التعديل في انواع الاضافات",
  delete_addontype: "حذف انواع الاضافات",
  view_addontype: "عرض انواع الاضافات",

  // inventory count
  add_inventorycount: "إضافة جرد جديد",
  change_inventorycount: "تعديل في الجرد",
  delete_inventorycount: "حذف الجرد",
  view_inventorycount: "عرض عمليات الجرد",

  // inventory Damage
  add_inventorydamage: "إضافة تالف جديد",
  change_inventorydamage: "تعديل في التالف",
  delete_inventorydamage: "حذف التالف",
  view_inventorydamage: "عرض التالف",

  // refund
  add_refund: "اضافة مرتجع",
  change_refund: "تعديل المرتجع",
  delete_refund: "حذف المرتجع",
  view_refund: "عرض فواتير المرتجعات",

  // stock Transfer
  add_stocktransfer: "اضافه طلبات نقل بين الفروع",
  change_stocktransfer: "تعديل طلبات النقل بين الفروع",
  delete_stocktransfer: "حذف طلبات النقل",
  view_stocktransfer: "عرض طلبات النقل",

  // Taxes
  add_tax: "اضافة ضرائب",
  change_tax: "تعدديل الضرائب",
  delete_tax: "حذف الضرائب",
  view_tax: "عرض الضرائب",

  // payment amount
  add_paymentamount: "تقسيم المدفوعات في شاشه البيع",
  change_paymentamount: "تعديل في انظمه الدفع",
  delete_paymentamount: "حذف انظمة الدفع",
  view_paymentamount: "عرض انظمه الدفع",

  // payment types
  add_paymenttype: "إضافة أنواع جديدة للدفع",
  change_paymenttype: "تعديل أنواع الدفع",
  delete_paymenttype: "حذف أنواع الدفع",
  view_paymenttype: "عرض أنواع الدفع",

  // promo
  add_promo: "إضافة عرض ترويجي جديد",
  change_promo: "تعديل العرض الترويجي",
  delete_promo: "حذف العرض الترويجي",
  view_promo: "عرض العروض الترويجية",

  // purchase
  add_purchase: "إضافة عملية شراء جديدة",
  change_purchase: "تعديل عملية الشراء",
  delete_purchase: "حذف عملية الشراء",
  view_purchase: "عرض عملية الشراء",

  // purchase item
  add_purchaseitem: "إضافة عناصر شراء جديدة",
  change_purchaseitem: "تعديل عناصر الشراء",
  delete_purchaseitem: "حذف عناصر الشراء",
  view_purchaseitem: "عرض عناصر الشراء",

  // user work shifts
  add_userworkshift: "إضافة مواعيد عمل",
  change_userworkshift: "تعديل مواعيد العمل",
  delete_userworkshift: "حذف مواعيد العمل",
  view_userworkshift: "عرض مواعيد العمل",
};
