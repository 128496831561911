import routes from "./routes";

export default class SidebarConfig {
  constructor(vm) {
    this.vm = vm;
    this.collapsed = true;
    this.theme = "white-theme";
    /* whether only one child of an expanded parent item can
    be active at a time. When show_one_child is set to true,
    only one child item can be expanded and active at a time */
    this.show_one_child = true;
    // keeps all sub links opened
    this.show_childs = false;
    // true if get direction returns rtl
    this.rtl = vm.$store.getters["auth/get_page_direction"] === "rtl";

    // hiding the toggle icon template
    this.hide_toggle = true;
  }

  get_routes() {
    return routes(this.vm);
  }
  on_toggle_collapse = (collapsed) => {
    this.collapsed = collapsed;
  };
  // eslint-disable-next-line no-unused-vars
  on_item_click = (event, item, node) => {
    if (item.title === this.vm.$t("sidebar.logout")) {
      this.vm.$refs.logout_modal_comp.$refs.logout_modal_btn.click();
    }
    // hide sidebar on small screens if item of the menu selected
    if (this.vm.is_small_screen()) {
      if (item.child) {
        return;
      }
      this.vm.show_sidebar = false;
    }
  };
}
