import InventoryCount from "@/views/management/inventory/count/InventoryCount";
import NewInventoryCount from "@/views/management/inventory/count/NewInventoryCount";
import DisplayInventoryCount from "@/views/management/inventory/count/DisplayInventoryCount";

export default [
  {
    path: "inventory_count/",
    component: InventoryCount,
    name: "inventory_count",
    meta: {
      title: () => {
        return "OnePos | inventory count";
      },
    },
  },
  {
    path: "inventory_count/new",
    component: NewInventoryCount,
    name: "new_inventory_count",
    meta: {
      title: () => {
        return "OnePos | New inventory count";
      },
    },
  },
  {
    path: "inventory_count/count_details/:report_id",
    component: DisplayInventoryCount,
    name: "count_details",
    meta: {
      title: (route) => {
        return `OnePos | InventoryCount ${route.params.report_id} `;
      },
    },
  },
];
