import axios from "axios";
import { Invoice } from "@axenda/zatca";
import store from "@/store";

const state = {
  invoices: [],
  pagination: {
    next: false,
    previous: false,
    total_pages: 1,
  },
  page_num: 1,
  selected_invoice: {},
  qr_code: "",
  search_invoice_type: "all",
  order_by_paid: false,
  order_by_refund: false,
  order_by: "-id",
  search_val: "",
  returned_amount: 0,
  // optimized object to send proper refund request to the server
  s_refund: 0,
};

// todo: split filters, pagination, search to multiple state files
const actions = {
  set_invoices({ commit }, invoices) {
    commit("set_invoices", invoices);
  },
  set_pagination({ commit }, pagination) {
    commit("set_pagination", pagination);
  },
  set_page_num({ commit }, num) {
    commit("set_page_num", num);
  },
  set_selected_invoice({ commit }, invoice) {
    commit("reset_invoice");
    commit("set_selected_invoice", invoice);
    commit("generate_qrcode");
  },
  async invoice_search({ getters, commit }) {
    // to hide opened invoice details
    commit("reset_invoice");
    const response = await axios.get(getters.get_search_url);
    commit("set_invoices", response.data.results);
    commit("set_pagination", response.data.pagination);
    return response;
  },
  set_search_invoice_type({ commit }, invoice_type) {
    commit("set_search_invoice_type", invoice_type);
  },
  set_order_by_paid({ commit }, bool) {
    commit("set_order_by_paid", bool);
  },
  set_order_by_refund({ commit }, bool) {
    commit("set_order_by_refund", bool);
  },
  set_order_by({ commit }, val) {
    commit("set_order_by", val);
  },
  set_search_val({ commit }, val) {
    commit("set_search_val", val);
  },
  set_returned_amount({ commit }, val) {
    commit("set_returned_amount", val);
  },
  set_server_refund({ commit }, val) {
    commit("set_server_refund", val);
  },
  reset_invoice({ commit }) {
    commit("reset_invoice");
  },
};

const mutations = {
  set_invoices: (state, invoices) => {
    state.invoices = invoices;
  },
  set_pagination: (state, pagination) => {
    state.pagination = pagination;
  },
  set_page_num: (state, num) => {
    state.page_num = num;
  },
  set_selected_invoice: (state, invoice) => {
    state.selected_invoice = invoice;
  },
  async generate_qrcode() {
    if (!state.selected_invoice.tax_total) {
      state.qr_code = "";
      return;
    }
    // generate qrcode
    const invoice = new Invoice({
      sellerName: store.getters["auth/user_info"].company_name,
      vatRegistrationNumber: store.getters["auth/user_info"].company_vat_num, // Note must start and end with number 3 and be 15 num in total
      invoiceTimestamp: state.selected_invoice.timestamp,
      invoiceTotal:
        state.selected_invoice.price || state.selected_invoice.total,
      invoiceVatTotal: state.selected_invoice.tax_total,
    });
    state.qr_code = await invoice.render();
  },
  set_search_invoice_type: (state, invoice_type) => {
    state.search_invoice_type = invoice_type;
  },
  set_order_by_paid: (state, bool) => {
    state.order_by_paid = bool;
  },
  set_order_by_refund: (state, bool) => {
    state.order_by_refund = bool;
  },
  set_order_by: (state, val) => {
    state.order_by = val;
  },
  set_search_val: (state, val) => {
    state.search_val = val;
  },
  set_returned_amount: (state, val) => {
    state.returned_amount = val;
  },
  set_server_refund: (state, val) => {
    state.s_refund = val;
  },
  reset_invoice: (state) => {
    state.selected_invoice = {};
    state.returned_amount = 0;
    state.s_refund = {};
  },
};
const getters = {
  get_invoices: (state) => {
    return state.invoices;
  },
  get_qr_code: (state) => {
    return state.qr_code;
  },
  get_pagination: (state) => {
    return state.pagination;
  },
  get_page_num: (state) => {
    return state.page_num;
  },
  get_selected_invoice: (state) => {
    return state.selected_invoice;
  },
  get_search_url() {
    return `/sell_screen/get_invoices/?page=${state.page_num}&id=${state.search_val}&invoice_type=${state.search_invoice_type}&order_by=${state.order_by}`;
  },
  get_search_invoice_type: (state) => {
    return state.search_invoice_type;
  },
  get_order_by_paid: (state) => {
    return state.order_by_paid;
  },
  get_order_by_refund: (state) => {
    return state.order_by_refund;
  },
  get_order_by: (state) => {
    return state.order_by;
  },
  get_search_val: (state) => {
    return state.search_val;
  },
  get_returned_amount: (state) => {
    return state.returned_amount;
  },
  get_server_refund: (state) => {
    return state.s_refund;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
