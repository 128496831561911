import zatca_info from "./zatca_info/en";
import zatca_csid from "./zatca_csid/en";

export default {
  // zatca main page sidebar
  add_zatca_info: "Add Zatca Information",
  csid: "CSID",
  ...zatca_info,
  ...zatca_csid,
};
